import {FC, useState, useEffect, useCallback, useMemo} from 'react';

import {OptionFilterType, filterOptionType} from '../ShippingHeaderType';
import {useQueryModifier} from '../../../../../hooks/useQueryModifier';
import {Button, Selector} from '../../../../../Components';
import {useStateData} from '../../useStateData';
import {
  LanguageNameShipp,
  useLanguageName,
} from '../../../../../hooks/useLanguageName';

export const FilterTab: FC = () => {
  const {languages, shipping} = useStateData();
  const {query, addQueryParams, queryObject} = useQueryModifier();

  const initialFilterOption = useMemo(() => {
    return {
      is_active: {id: '', name: ''},
      currency_id: {id: '', name: ''},
    };
  }, []);

  const [filterOption, setFilterOption] =
    useState<filterOptionType>(initialFilterOption);
  const activeOption = [
    {
      id: '',
      name: 'All',
    },
    {
      id: 1,
      name: 'Active',
    },
    {
      id: 0,
      name: 'Inactive',
    },
  ];
  const currencyTypeOption = [
    {
      id: '',
      name: 'All',
    },
  ];
  // filter shipp and active changes
  const handleChange = useCallback((name: string, option: OptionFilterType) => {
    setFilterOption((prev) => ({...prev, [name]: option}));
  }, []);

  // rest of filter options
  const resetFilter = useCallback(() => {
    setFilterOption(initialFilterOption);
    addQueryParams({is_active: '', currency_id: ''});
  }, [addQueryParams, initialFilterOption]);

  const handleApply = useCallback(() => {
    const customFilterOption = {
      is_active: filterOption?.is_active?.id.toString() || '',
      currency_id: filterOption?.currency_id?.id.toString() || '',
      page: 1,
    };
    addQueryParams(customFilterOption);
  }, [
    addQueryParams,
    filterOption?.is_active?.id,
    filterOption?.currency_id?.id,
  ]);

  useEffect(() => {
    setFilterOption({
      is_active: activeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.is_active
      )[0],
      currency_id: currencyTypeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.currency_id
      )[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, shipping?.data]);

  return (
    <>
      <Selector
        label={LanguageNameShipp('data.currency_id')}
        name="currency_id"
        defaultOptions={filterOption?.currency_id}
        options={currencyTypeOption}
        placeholder={LanguageNameShipp('data.currency_id_description')}
        searchPlaceholder="Search availability"
        onChange={handleChange}
      />
      <Selector
        label={LanguageNameShipp('data.is_active')}
        name="is_active"
        defaultOptions={filterOption?.is_active}
        options={activeOption}
        placeholder={LanguageNameShipp('data.is_active_placeholder')}
        searchPlaceholder="Search availability"
        onChange={handleChange}
      />
      <div className="filter-drop-footer">
        <Button
          label={useLanguageName('corporate.crud.reset')}
          icon={null}
          styles="text-third-color !w-20 h-7"
          labelStyles="text-xs font-semibold "
          onClick={resetFilter}
        />
        <Button
          label={useLanguageName('corporate.crud.apply')}
          icon={null}
          styles="text-white bg-third-color !w-20 !h-7"
          labelStyles="text-xs font-semibold"
          isLoading={shipping?.isLoading}
          onClick={handleApply}
        />
      </div>
    </>
  );
};
