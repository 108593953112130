import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createRoleAction,
  deleteRoleAction,
  fetchAllRolesAction,
  fetchPermissionsAction,
  getRoleAction,
  updateRoleAction,
} from "./roleAction";

interface InitialStateType {
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Array<string>;
}

interface GeneralState {
  role: InitialStateType;
  roles: InitialStateType;
  permissions: InitialStateType;
}

const initialStateStructure = {
  data: [],
  isLoading: true,
  success: false,
  message: [],
};

const initialState: GeneralState = {
  role: {
    ...initialStateStructure,
    isLoading: false,
  },
  roles: initialStateStructure,
  permissions: initialStateStructure,
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    resetRole: (state) => {
      state.role.data = [];
      state.role.isLoading = false;
      state.role.message = [];
      state.role.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // FETCH ROLES
      .addCase(fetchAllRolesAction.pending, (state) => {
        state.roles.isLoading = true;
      })
      .addCase(
        fetchAllRolesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.roles = action.payload;
          state.roles.isLoading = false;
        }
      )
      .addCase(
        fetchAllRolesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.roles.isLoading = false;
          state.roles.success = action.payload?.success;
          state.roles.message = action.payload?.message;
        }
      )
      // Create role
      .addCase(createRoleAction.pending, (state) => {
        state.role.isLoading = true;
      })
      .addCase(
        createRoleAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.role = action.payload;
          state.role.isLoading = false;
        }
      )
      .addCase(
        createRoleAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.role.isLoading = false;
          state.role.success = action.payload?.success;
          state.role.message = action.payload?.message;
        }
      )
      // Fetch all permissions
      .addCase(fetchPermissionsAction.pending, (state) => {
        state.permissions.isLoading = true;
      })
      .addCase(
        fetchPermissionsAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.permissions = action.payload;
          state.permissions.isLoading = false;
        }
      )
      .addCase(
        fetchPermissionsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.permissions.isLoading = false;
          state.permissions.success = action.payload?.success;
          state.permissions.message = action.payload?.message;
        }
      )
      // Fetch Role
      .addCase(getRoleAction.pending, (state) => {
        state.roles.isLoading = true;
      })
      .addCase(getRoleAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.roles.isLoading = false;
        state.roles = action.payload;
      })
      .addCase(getRoleAction.rejected, (state, action: PayloadAction<any>) => {
        state.roles.isLoading = false;
        state.roles = action.payload;
      })

      // Update role
      .addCase(updateRoleAction.pending, (state) => {
        state.role.isLoading = true;
      })
      .addCase(
        updateRoleAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.role = action.payload;
          state.role.isLoading = false;
        }
      )
      .addCase(
        updateRoleAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.role.isLoading = false;
          state.role.success = action.payload?.success;
          state.role.message = action.payload?.message;
        }
      )

      // Delete role
      .addCase(deleteRoleAction.pending, (state) => {
        state.role.isLoading = true;
      })
      .addCase(
        deleteRoleAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.role = action.payload;
          state.role.isLoading = false;
        }
      )
      .addCase(
        deleteRoleAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.role.isLoading = false;
          state.role.success = action.payload?.success;
          state.role.message = action.payload?.message;
        }
      );
  },
});

export const { resetRole } = rolesSlice.actions;

export default rolesSlice.reducer;
