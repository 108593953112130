import {
  faFileCsv,
  faFilePdf,
  faPenToSquare,
  faPrint,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  CellAction,
  CellParagraph,
  CellPrice,
  Normal,
} from "../../../Components";
import { usePermission } from "../../../hooks/usePermission";
import { useLanguageName } from "../../../hooks/useLanguageName";

export const CellComponentItemsSetting = [
  {
    Component: Normal,
    compProps: {
      firstValue: "name",
      secondValue: "description",
    },
  },
  {
    Component: CellParagraph,
    compProps: {
      parKey: "Units",
    },
  },
  {
    Component: CellAction,
    compProps: {
      parKey: "name",
      customStyle: "text-primary-color",
    },
  },
  {
    Component: CellPrice,
    compProps: {
      parKey: "price",
      parKeyTwo: "Currency",
      customStyle: "w-24",
      customWidth: "w-[1%]",
    },
  },
];

export const ItemSettingTableActionBtnData = () => {
  const exportLanguages = useLanguageName("corporate.crud.export");
  const exportCsvLanguages = useLanguageName("corporate.crud.export_csv");
  const exportPdfLanguages = useLanguageName("corporate.crud.export_pdf");
  return [
    {
      id: 0,
      name: exportLanguages,
      icon: faPrint,
      permission: true,
    },
    {
      id: 1,
      name: exportCsvLanguages,
      icon: faFileCsv,
      permission: true,
    },
    {
      id: 2,
      name: exportPdfLanguages,
      icon: faFilePdf,
      permission: true,
    },
  ];
};

export const itemNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.items",
    path: "/settings/items",
  },
];

export const addItemNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.items",
    path: "/settings/items",
  },
  {
    name: "corporate.settings.items.create.title",
    path: "/settings/add_employee",
  },
];

export const showItemNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.items",
    path: "/settings/items",
  },
  {
    name: "corporate.settings.items.show.title",
    path: "/settings/show_item",
  },
];

export const updateItemNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.items",
    path: "/settings/items",
  },
  {
    name: "corporate.settings.items.update.title",
    path: "/settings/add_items",
  },
];

export const ActionItemBtnRows = ({ paths }: any) => {
  const { edit, handleModalClose } = paths;
  const editItemLanguages = useLanguageName(
    "corporate.settings.items.update.title"
  );
  const deleteItemLanguages = useLanguageName(
    "corporate.settings.items.show.delete_item"
  );
  return [
    {
      id: 0,
      name: editItemLanguages,
      path: edit,
      icon: faPenToSquare,
      permission: usePermission("corporate.settings.items.update"),
    },
    {
      id: 2,
      name: deleteItemLanguages,
      icon: faX,
      permission: usePermission("corporate.settings.items.destroy"),
      optionClick: () => handleModalClose(),
    },
  ];
};
