import { useEffect, createRef, ReactNode, useCallback } from "react";
import "./style.css";

type TooltipProps = {
  children: ReactNode;
  position?: string;
  message: string;
};
const Tooltip = ({
  children,
  position = "bottom",
  message = "label",
}: TooltipProps) => {
  const buttonRef = createRef<HTMLDivElement>();
  const tooltipRef = createRef<HTMLDivElement>();

  const showTooltip = useCallback(() => {
    if (tooltipRef && tooltipRef.current) {
      tooltipRef?.current?.classList?.remove("opacity-0");
      tooltipRef?.current?.classList?.add("opacity-100");
    }
  }, [tooltipRef]);

  const hideTooltip = useCallback(() => {
    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.classList.add("opacity-0");
      tooltipRef.current.classList.remove("opacity-100");
    }
  }, [tooltipRef]);

  useEffect(() => {
    if (buttonRef && buttonRef.current) {
      buttonRef?.current?.addEventListener("mouseenter", showTooltip);
      buttonRef?.current?.addEventListener("mouseleave", hideTooltip);
      return () => {
        if (buttonRef && buttonRef.current) {
          buttonRef?.current?.removeEventListener("mouseenter", showTooltip);
          buttonRef?.current?.removeEventListener("mouseleave", hideTooltip);
        }
      };
    }
  }, [buttonRef, hideTooltip, showTooltip]);

  return (
    <div ref={buttonRef}>
      {children}
      <div className="relative inline-block flex justify-center">
        {
          {
            bottom: (
              <div
                ref={tooltipRef}
                id="tooltip-bottom"
                role="tooltip"
                className="opacity-0 bottom-tooltip tooltip"
              >
                <div className="bottom-tooltip-arrow tooltip-arrow" />
                {message}
              </div>
            ),
            left: <></>,
            top: <></>,
            right: <></>,
          }[position]
        }
      </div>
    </div>
  );
};

export default Tooltip;
