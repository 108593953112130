import { useState } from "react";
import { AddInvoiceItems } from "./AddInvoiceItems";
import { AddressForm } from "./AddressForm";
import { InvoiceSummary } from "./InvoiceSummary";
import {
  defaultPageData,
  defaultItem,
  invoiceAddedItemsTypes,
} from "./addRecurringInvoiceData";
import { UUID } from "../../../utils/UUID";
import {
  Button,
  Navlink,
  Selector,
  TextArea,
  TextInput,
  UploadImage,
} from "../../../Components";
import "./style.css";

export const AddRecurringInvoices = () => {
  const [invoiceAddedItems, setInvoiceAddedItems] = useState<
    invoiceAddedItemsTypes[]
  >([
    {
      key: UUID(),
      ...defaultItem,
    },
  ]);

  const handleChange = () => {};
  return (
    <div>
      <Navlink
        path="Add Recurring"
        routes={[
          {
            name: "dashboard",
            path: "/dashboard",
          },
          {
            name: "recurring invoices",
            path: "/recurring-invoices",
          },
          {
            name: "ri-1012",
            path: "/settings/ri-1012",
          },
        ]}
      >
        <Button label="Save draft" icon={null} styles="addRecurringDraftBtn" />
        <Button
          label="Save"
          icon={null}
          styles="addRecurringBtn"
          onClick={() =>
            console.log({ ...defaultPageData, items: invoiceAddedItems })
          }
        />
      </Navlink>
      <div className="parentContainer">
        <div className="topRow">
          <div className="textInputsContainer">
            <div className="w-full">
              <TextInput
                name="draft"
                label="Draft"
                placeholder="Enter invoice title"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <TextArea
                name="description"
                label="Description"
                placeholder="Add short description.... "
                rows={6}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="imageInputContainer">
            <UploadImage image="" isAvatar={false} onChange={handleChange} />
          </div>
        </div>
        <div className="selectionsRow">
          <div className="w-full">
            <TextInput
              name="invoice_number"
              label="Recurring Invoice No."
              placeholder="Enter invoice number"
              tag="Ri"
              onChange={handleChange}
            />
          </div>
          <div className="relative w-full">
            <Selector
              label="Language"
              // options={["en", "ar"]}
              placeholder="Choose language"
              searchPlaceholder="Search language"
            />
          </div>
          <div className="relative w-full">
            <Selector
              label="Currency"
              // options={["dollar", "euro", "dollar", "euro", "dollar", "euro"]}
              placeholder="Choose currency"
              searchPlaceholder="Search currency"
            />
          </div>
        </div>
        <hr className="miniDivider" />
        <div className="selectionsRow">
          <div className="w-full">
            <TextInput
              name="duration"
              label="Duration"
              placeholder="Enter duration value"
              dropdown
              dropdownLabel="Type"
              // dropdownOptions={["day", "month", "year"]}
              onChange={handleChange}
            />
          </div>
          <div className="relative w-full">
            <Selector
              label="Language"
              // options={["en", "ar"]}
              placeholder="Choose language"
              searchPlaceholder="Search language"
            />
          </div>
          <div className="relative w-full">
            <Selector
              label="Currency"
              // options={["dollar", "euro", "cent", "gold", "iron"]}
              placeholder="Choose currency"
              searchPlaceholder="Search currency"
            />
          </div>
        </div>
        <hr className="divider" />
        {/* -------- */}
        <AddressForm />
        <hr className="divider" />
        {/* -------- */}
        <AddInvoiceItems
          invoiceAddedItems={invoiceAddedItems}
          setInvoiceAddedItems={setInvoiceAddedItems}
        />
        <hr className="divider lg:!mt-7 !mt-0" />
        {/* -------- */}
        <InvoiceSummary />
      </div>
    </div>
  );
};
