import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const convertQueryStringToObject = (query: string) => {
  const queryObject = {} as Record<string, string>;
  // query = query.replace("?", "");
  const queryArray = query?.split("&");
  queryArray.forEach((item) => {
    const [key, value] = item?.split("=");
    if (typeof queryObject[key] === "undefined") {
      queryObject[key] = "";
    }

    if (value !== "undefined") {
      queryObject[key] = value;
    }
  });
  return queryObject;
};

export const useQueryModifier = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  let query = queryParams.toString();
  const queryObject = useMemo(() => convertQueryStringToObject(query), [query]);

  const addQueryParams = (newParams: any) => {
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === "") {
        queryParams.delete(key);
      } else {
        queryParams.set(key, newParams[key]);
      }
    });

    query = queryParams.toString();

    navigate({ search: query });
  };

  const resetQueryParams = () => {
    navigate({ search: "" });
  };

  return {
    addQueryParams,
    query: query ? "?" + query : query,
    queryObject,
    resetQueryParams,
  };
};

// const { addQueryParams, query, queryObject } = useQueryModifier();
// addQueryParams({ [name]: value });
