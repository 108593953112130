import { MouseEvent, MouseEventHandler, ReactNode } from "react";
import { useEscapeKeyPressed } from "../../../hooks/useEscapeKeyPressed";
import { Button } from "../Button";
import "./style.css";

// *** Example to use
// const [showModal, setShowModal] = useState(false);
// const handleModalClose = () => {
//     setShowModal(false);
//   };
// const handleSaveModal = () => {};
// <Button label="open regular modal" onClick={() => setShowModal(true)} />
// <Modal
//   title="Modal Title"
//   open={showModal}
//   footer
//   handleCloseModal={handleModalClose}
//   handleSaveModal={handleSaveModal}
// >
//     // body data
// </Modal>

interface ModalTypes {
  children: ReactNode;
  title?: string | null;
  footer?: boolean;
  open?: boolean;
  handleSaveModal?: () => void;
  handleCloseModal: MouseEventHandler<HTMLDivElement>;
}

export const Modal = ({
  children,
  title = null,
  footer = true,
  open = true,
  handleSaveModal = () => {},
  handleCloseModal = () => {},
}: ModalTypes) => {
  const handleStopPropagationClose = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  useEscapeKeyPressed(handleCloseModal, open);

  return (
    <>
      {open && (
        <>
          <div className="flex-center modal-screen" onClick={handleCloseModal}>
            <div
              className="modal-box-layout"
              onClick={handleStopPropagationClose}
            >
              <div className="modal-box">
                {/*header*/}
                {title && (
                  <div className="flex-center p-5">
                    <h3 className="modal-title">{title}</h3>
                  </div>
                )}
                {/*body*/}
                <div className="relative p-6 flex-auto">{children}</div>
                {/*footer*/}
                {footer && (
                  <div className="flex-center p-6 ">
                    <Button label="save" onClick={handleSaveModal} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="modal-transparent-background"
            onClick={handleCloseModal}
          />
        </>
      )}
    </>
  );
};

