import { FC } from "react";
import { Navlink } from "../../Components";

export const Dashboard: FC = () => {
  return (
    <div>
      <Navlink
        path={"dashboard"}
        header="Take overview about your data and statistics"
      />
    </div>
  );
};
