import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
import { useNotify } from "../../Components";

const api = new APIClient();

interface RoleArguments {
  [key: string]: any;
}

// GET/ALL Roles ACTION
export const fetchAllRolesAction = createAsyncThunk(
  'roles/getAllRoles',
  async (query: String, {rejectWithValue}) => {
    try {
      const {data} = await api.get(`${url.GIT_ROLES}${query}`);
      const newData = {
        ...data,
        data: data?.data?.RoleCollection,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// GET/ALL PERMISSIONS ACTION
export const fetchPermissionsAction = createAsyncThunk(
  'roles/fetchPermissionsAction',
  async (_, {rejectWithValue, getState}) => {
    try {
      const {data} = await api.get(`${url.GIT_PERMISSIONS}`);
      const newData = {
        ...data,
        data: data?.data?.Permissions,
      };
      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// POST/CREATE ROLE ACTION
export const createRoleAction = createAsyncThunk(
  "roles/create",
  async (role: RoleArguments, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.create(url.GIT_ROLES, role.role);
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// GET/ ROLE ACTION
export const getRoleAction = createAsyncThunk(
  'roles/getRoleAction',
  async (query: string | undefined, {rejectWithValue}) => {
    try {
      const {data} = await api.get(`${url.GIT_ROLES}/${query}`);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// PUT/UPDATE ROLE ACTION
export const updateRoleAction = createAsyncThunk(
  "roles/update",
  async (role: RoleArguments, { rejectWithValue }) => {
    const Notify = useNotify();

    try {
      const {data} = await api.create(`${url.GIT_ROLES}/${role?.role?.id}`, {
        ...role?.role,
        _method: 'PUT',
      });
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// DELETE/REMOVE ROLE ACTION
export const deleteRoleAction = createAsyncThunk(
  'roles/delete',
  async (query: string | undefined, {rejectWithValue}) => {
    const Notify = useNotify();
    try {
      const {data} = await api.delete(`${url.GIT_ROLES}/${query}`);
      if (data?.success) {
        Notify.Success({message: data?.message?.success});
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({message: errorData?.message?.error});
        return rejectWithValue(errorData);
      }
    }
  }
);
