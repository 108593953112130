import { ChangeEvent, FC, useCallback, FormEvent } from "react";
import { AuthLayout } from "../AuthLayout";
import Image from "./../../../assets/illustration.svg";
import { useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useNavigate } from "react-router";
import { restPasswordUser } from "../../../redux/auth/authAction";
import { Link, useSearchParams } from "react-router-dom";
import { object } from "../../../utils/ValidateErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button, TextInput } from "../../../Components";
export interface ResetData {
  password: string;
  password_confirmation: string;
}
type Response = {
  isLoading: boolean;
  success: boolean;
  message: { [k: string]: any };
  data: { [k: string]: any };
};
export const ResetPassword: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const response: Response = useAppSelector((state) => state?.auth);
  const {
    languages: { data },
  } = useAppSelector((state) => state.general);
  useEffect(() => {
    // set api response errors
    if (!response.success) {
      setErrors(response.message);
    }
    // Set ticket error
  }, [response]);

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const code = searchParams.get("code") || "";
  const [resetData, setResetData] = useState<ResetData>({
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState<{ [k: string]: string }>({
    password: "",
    password_confirmation: "",
  });

  const [valid, setValid] = useState(false);

  const flag = useRef(false);

  const validate = useCallback(() => {
    const userSchema = object({
      password: ["string", "required", "password"],
      password_confirmation: ["string", "required", "confirm_password"],
    });
    const result = userSchema.validate(resetData);
    setErrors(result.errors);
    setValid(result.valid);
  }, [resetData]);

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  const ResetAction = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
        validate();
      }
      if (valid) {
        dispatch(
          restPasswordUser({
            restData: {
              email,
              code,
              password: resetData.password,
              password_confirmation: resetData.password_confirmation,
            },
            cb: () => {
              navigate("/verify-code?email=" + email);
            },
          })
        );
      }
    },
    [
      code,
      dispatch,
      email,
      navigate,
      resetData.password,
      resetData.password_confirmation,
      valid,
      validate,
    ]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setResetData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <AuthLayout>
      <div className="auth-head-container">
        <div className="auth-head-container-forgetpassword-span ">
          <Link to="/forget-password">
            <div className="container-span">
              <FontAwesomeIcon icon={faArrowLeft} />
              <span className="span-text ">Back</span>
            </div>
          </Link>
        </div>
        <img className="w-28 h-32" src={Image} alt="forget password icon" />

        <h1 className="auth-card-title">Reset Password</h1>
        <p className="auth-card-description">
          {data?.ConstantsList?.["corporate.auth.reset_password.description"]}
        </p>
      </div>
      <form onSubmit={ResetAction} noValidate className="form-login">
        <div>
          <TextInput
            label={
              data?.ConstantsList?.["corporate.auth.reset_password.password"]
            }
            name="password"
            type="password"
            placeholder={
              data?.ConstantsList?.["corporate.auth.reset_password.password"]
            }
            value={resetData?.password}
            onChange={handleChange}
            error={errors?.password}
            errorMsg={errors?.password}
          />
        </div>
        <div>
          <TextInput
            label={
              data?.ConstantsList?.[
                "corporate.auth.reset_password.confirm_password"
              ]
            }
            name="password_confirmation"
            type="password"
            placeholder="Enter confirmation password"
            value={resetData?.password_confirmation}
            onChange={handleChange}
            error={errors?.password_confirmation}
            errorMsg={errors?.password_confirmation}
          />
        </div>
        <div className="auth-card-footer-container ">
          <div className="auth-card-footer">
            <Button
              type="submit"
              label={
                data?.ConstantsList?.[
                  "corporate.auth.reset_password.reset_password"
                ]
              }
              icon={null}
            />
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};
