import { FC, Key } from "react";
import "./style.css";

interface DataTypes {
  titles: Array<string>;
}

export const TableTitlesHeader: FC<DataTypes> = ({ titles }) => {
  return (
    <tr className=" whitespace-nowrap pt-4 w-full ">
      {titles.map((item: string, index: Key) => (
        <th
          key={item}
          className={`header-cell ltr:pr-5 rtl:pl-5 
          ${index === 0 ? "!pl-0" : ""}
          ${
            index === titles.length - 1 ? "!w-fit !pr-0" : ""
          }`}
        >
          {item}
        </th>
      ))}
    </tr>
  );
};
