import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NotifyMessageControl, MsgBlockStyle } from "./NotifyTypes";

export const NotifyMessage = ({
  id,
  message,
  options,
  onRemove,
}: NotifyMessageControl) => {
  const { type, lifetime, header, icon, boxStyle, iconStyle } = options;

  const MsgStyle = type
    ? MsgBlockStyle[type]
    : {
        boxStyle: boxStyle || "bg-white border-gray-600 ",
        iconStyle: iconStyle || "",
        icon: icon,
        header: header,
      };

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, lifetime);
    }
  }, [id, lifetime, onRemove]);

  return (
    <div className={` notify-msg-container ${MsgStyle.boxStyle} `}>
      {/* <div className="flex flex-row flex-no-wrap w-full gap-[22px]"> */}
      {MsgStyle.icon && (
        <div className={`notify-msg-container-icon ${MsgStyle.iconStyle}`}>
          <FontAwesomeIcon
            className={`h-[50px] w-[50px]`}
            icon={MsgStyle.icon}
          />
        </div>
      )}

      <div className="notify-msg-content">
        <div className="notify-msg-head">{MsgStyle?.header}</div>
        <p className="notify-msg-description">{message}</p>
      </div>
      {/* <div
          onClick={() => onRemove && onRemove(id)}
          className="notify-msg-close-container"
        >
          <FontAwesomeIcon className="notify-msg-close-icon" icon={faTimes} />
        </div> */}
      {/* </div> */}
    </div>
  );
};
