import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Items } from "./data";
import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./style.css";
import { useLanguageName } from "../../../hooks/useLanguageName";
import { Navlink } from "../../../Components";

interface GeneralDataType {
  id: number;
  title: string;
  desc: string;
  path: string;
  icon: IconProp;
  visible: boolean;
}

export const GeneralSetting = () => {
  const {
    permission: { data },
    languages,
  } = useAppSelector((state) => state.general);
  const [listData, setListData] = useState<Array<GeneralDataType>>([]);

  useEffect(() => {
    setListData(Items(data, languages));
  }, [data, languages]);

  return (
    <div>
      <Navlink
        path="corporate.settings.title"
        header={useLanguageName("corporate.settings.description")}
        isBackNavigate={false}
      />
      <div className="setting-container ">
        {listData.map((item) => {
          return (
            item?.visible && (
              <Link key={item?.id} to={item?.path} className="items-container">
                <div className="items">
                  <div className="setting-flex-center ">
                    <FontAwesomeIcon
                      className="items-icon w-[22px] h-[22px] "
                      icon={item?.icon}
                    />
                  </div>
                  <div className="items-col flex flex-col">
                    <span>{item?.title}</span>
                    <p className="setting-description">{item?.desc}</p>
                  </div>
                </div>
              </Link>
            )
          );
        })}
      </div>
    </div>
  );
};
