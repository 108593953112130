import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useState, useEffect } from "react";
import "./style.css";

type SelectButtonProps = {
  searchPlaceholder?: string;
  inputValue: string;
  setDisabled: (value: boolean) => void;
  onChange?: (
    something:
      | ChangeEvent<HTMLInputElement>
      | {
          itemName: string;
          itemPrice: string;
        }
  ) => void;
  options: {
    itemName: string;
    itemPrice: string;
  }[];
};

export const SelectButton = ({
  searchPlaceholder = "search placeholder",
  onChange = () => {},
  options,
  inputValue,
  setDisabled,
}: SelectButtonProps) => {
  const [search, setSearch] = useState<string>("");
  const [selected, setSelected] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelected("");
  }, [inputValue]);

  return (
    <div className="relative select-none">
      <div
        className={`selectBtn
        ${open ? "selectBtnOpened" : "selectBtnClosed"}
        `}
        onClick={() => setOpen(!open)}
      >
        <FontAwesomeIcon icon={faBoxOpen} className="selectBtnIcon" />
        <div className="sm:block hidden">Chose Item</div>
      </div>
      <ul
        className={`selectBtnUl invisible-scroll ${
          open ? "ulOpened " : "ulClosed"
        } `}
      >
        <li className="selectSearchContainer">
          <input
            type="text"
            placeholder={searchPlaceholder}
            className="selectsearch placeholder-black/3"
            value={search}
            onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
          />
        </li>
        <li
          className={`emptyItemsMessage
        ${
          options.some((option) =>
            option.itemName.toLocaleLowerCase().startsWith(search)
          )
            ? "hidden"
            : "block"
        }
        `}
        >
          There is no items ...
        </li>
        {options.map((option, index) => (
          <div key={index}>
            <li
              className={`selectListItems  ${
                option.itemName.toLocaleLowerCase().startsWith(search)
                  ? "block"
                  : "hidden"
              }
            ${
              ((selected.toLocaleLowerCase() ===
                option.itemName.toLocaleLowerCase() &&
                option.itemName.toLocaleLowerCase() ===
                  inputValue.toLocaleLowerCase()) ||
                option.itemName.toLocaleLowerCase() ===
                  inputValue.toLocaleLowerCase()) &&
              "selectListItemsSelected"
            }
           
            `}
              onClick={() => {
                if (
                  option.itemName.toLocaleLowerCase() !==
                  selected.toLocaleLowerCase()
                ) {
                  setSelected(option.itemName);
                  onChange(option);
                  setOpen(false);
                  setDisabled(true);
                }
              }}
            >
              <div className="flex justify-between space-x-2">
                <div>{option.itemName}</div>
                <div
                  className={`font-bold opacity-90  ${
                    (selected.toLocaleLowerCase() ===
                      option.itemName.toLocaleLowerCase() &&
                      option.itemName.toLocaleLowerCase() ===
                        inputValue.toLocaleLowerCase()) ||
                    option.itemName.toLocaleLowerCase() ===
                      inputValue.toLocaleLowerCase()
                      ? "!text-primary-color"
                      : "text-third-color"
                    // : "text-third-color dark:text-[#E5E5E5]"
                  }`}
                >
                  {option.itemPrice + " USD"}
                </div>
              </div>
            </li>
            <li
              className={`listItemDivider ${
                index === options.length - 1 ? "hidden" : "block"
              }`}
            ></li>
          </div>
        ))}
      </ul>
    </div>
  );
};
