import {
  faBell,
  faCircleDollarToSlot,
  faRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { logoutUser } from "../../redux/auth/authAction";
interface AvatarDropdownTypes {
  open: boolean | null;
  dropdown: boolean;
}
export const AvatarDropdown = ({ open, dropdown }: AvatarDropdownTypes) => {
  const { pathname, search } = useLocation();

  const dispatch = useAppDispatch();
  const menu = [
    {
      id: 0,
      label: "Profile",
      icon: faUser,
      path: "/profile",
      handleAction: () => {},
    },
    {
      id: 1,
      label: "Membership",
      icon: faCircleDollarToSlot,
      path: "/membership",
      handleAction: () => {},
    },
    {
      id: 2,
      label: "Notifications",
      icon: faBell,
      component: (
        <p
          className="group-hover:bg-white group-hover:text-primary-color text-center 
        w-6	h-6 p-1 rounded-full text-white bg-primary-color text-[10px] font-bold"
        >
          10
        </p>
      ),
      path: "/notifications",
      handleAction: () => {},
    },
    {
      id: 3,
      label: "Logout",
      icon: faRightFromBracket,
      path: `${pathname}${search}`,
      handleAction: () => {
        dispatch(logoutUser());
      },
    },
  ];

  return (
    <div
      className={`avatar-dropdown-container h-full ${
        dropdown ? "opacity-100 block" : "opacity-0 hidden"
      } duration-700`}
    >
      {menu?.map(({ id, label, icon, path, component, handleAction }) => (
        <Link
          key={id}
          to={path}
          className={`group flex items-center py-3  text-gray-800 hover:bg-main-color hover:text-white text-opacity-[70%]
          ${open ? "px-6" : "pl-12"}
          `}
          onClick={handleAction}
        >
          <FontAwesomeIcon className="rtl:ml-3 rtl-mr-0 mr-3" icon={icon} />
          <span
            className={`group-hover/item:text-main-color text-[16px] leading-[24px] font-[300] flex-1 ${
              !open && "hidden"
            } duration-300`}
          >
            {label}
          </span>
          {component}
        </Link>
      ))}
    </div>
  );
};
