import { FC } from "react";
import { CellPriceTypes } from "../TableTypes";
import "./style.css";

export const CellPrice: FC<CellPriceTypes> = ({
  data,
  customStyle,
  parKey = "",
  parKeyTwo = "",
}) => {
  return (
    <p className={`cell-paragraph ${customStyle}`}>
      {data?.[parKey]} {data?.[parKeyTwo]}
    </p>
  );
};
