import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
import { useNotify } from "../../Components";

interface EmployeeArguments {
  [key: string]: any;
}
const api = new APIClient();

// GET/ALL EMPLOYEE ACTION
export const fetchAllEmployeesAction = createAsyncThunk(
  "employees/getAllEmployees",
  async (query: String, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_ALL_EMPLOYEE}${query}`);
      const newData = {
        ...data,
        data: data?.data?.EmployeeCollection,
      };

      return newData;

      // return employee;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        return rejectWithValue(errorData);
      }
    }
  }
);

// HELPER FUNCTION
const filterEmployeeDataValues = (data: any) => {
  let customData = { ...data };
  delete customData?.data?.Employee?.is_banned;
  delete customData?.data?.Employee?.is_suspended;

  const filteredObj: any = {};

  for (const key in customData?.data?.Employee) {
    if (customData?.data?.Employee[key] !== null) {
      filteredObj[key] = customData?.data?.Employee[key];
    }
  }

  customData = {
    ...customData,
    data: {
      ...customData?.data,
      Employee: {
        ...filteredObj,
        is_active: data?.data?.Employee?.is_active ? 1 : 0,
      },
    },
  };

  return customData;
};

// GET/A EMPLOYEE ACTION
export const fetchEmployeeAction = createAsyncThunk(
  "employees/getEmployee",
  async (query: string | undefined, { rejectWithValue }) => {
    const Notify = useNotify();

    try {
      const { data } = await api.get(`${url.GIT_A_EMPLOYEE}/${query}`);
      return filterEmployeeDataValues(data);
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// POST/CREATE EMPLOYEE ACTION
export const createEmployeeAction = createAsyncThunk(
  "employees/createEmployee",
  async (employee: EmployeeArguments, { rejectWithValue }) => {
    const Notify = useNotify();

    try {
      const form = new FormData();
      for (let key in employee) {
        form.append(key, employee[key]);
      }
      const { data } = await api.create(url.POST_EMPLOYEE, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Notify.Success({ message: data?.message?.success });

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// PUT/UPDATE EMPLOYEE ACTION
export const updateEmployeeAction = createAsyncThunk(
  "employees/updateEmployee",
  async (employeeData: EmployeeArguments, { rejectWithValue }) => {
    const Notify = useNotify();

    const { employee, id } = employeeData;
    try {
      const form = new FormData();
      for (let key in employee) {
        if (key !== "avatar" || typeof employee[key] !== "string") {
          form.append(key, employee[key]);
        }
      }
      form.append("_method", "PUT");

      const { data } = await api.create(`${url.PUT_A_EMPLOYEE}/${id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Notify.Success({ message: data?.message?.success });

      return filterEmployeeDataValues(data);
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// DELETE EMPLOYEE ACTION
export const deleteEmployeesAction = createAsyncThunk(
  "employees/deleteEmployee",
  async ({ id, cb }: any, { rejectWithValue }) => {
    const Notify = useNotify();

    try {
      const { data } = await api.delete(`${url.DELETE_A_EMPLOYEE}/${id}`);
      cb();
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// export const fetchEmployeesAction = createAsyncThunk(
//   "employees/create",
//   async (
//     query: { limit: number; page: number },
//     { rejectWithValue, getState }
//   ) => {
//     try {
//       const {
//         employees: { employee },
//       } = getState() as any;

//       if (query?.limit != employee?.data?.pagination?.per_page) {
//         const { data } = await api.get(
//           `${url.GIT_EMPLOYEE}?page=${query.page}&per_page=${query.limit}`
//         );
//         const newData = {
//           ...data,
//           data: {
//             pagination: data?.data?.EmployeeCollection?.pagination,
//             Employees: {
//               1: data?.data?.EmployeeCollection?.Employees,
//             },
//           },
//         };
//         return newData;
//       } else if (!employee?.data?.Employees?.hasOwnProperty(query?.page)) {
//         const { data } = await api.get(
//           `${url.GIT_EMPLOYEE}?page=${query.page}&per_page=${query.limit}`
//         );
//         const newData = {
//           ...data,
//           data: {
//             pagination: data?.data?.EmployeeCollection?.pagination,
//             Employees: {
//               ...employee?.data?.Employees,
//               [query?.page]: data?.data?.EmployeeCollection?.Employees,
//             },
//           },
//         };
//         return newData;
//       }
//       // return employee;
//     } catch (error) {
//       if (isAxiosError(error)) {
//         return rejectWithValue(error?.response?.data);
//       }
//     }
//   }
// );
