import { FC, Fragment, useEffect, useState } from "react";
import { SelectPaginationType } from "../TableTypes";
import { useAppSelector } from "../../../redux/store";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const SelectPagination: FC<SelectPaginationType> = ({
  id,
  options = [8, 10, 20, 30, 50, 100],
  OptionSelected = 8,
  totalRows = 100,
  totalVisible = 8,
  handleChange,
}) => {
  const {
    languages: {
      data: { ConstantsList },
    },
  } = useAppSelector((state) => state.general);
  const [selected, setSelected] = useState<number | string>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelected(OptionSelected);
  }, [OptionSelected]);

  return (
    <div className="select-pagination-container ">
      <label htmlFor={id} className="select-pagination-label">
        {ConstantsList?.["corporate.crud.pagination_showing"] +
          " " +
          totalVisible +
          " " +
          ConstantsList?.["corporate.crud.pagination_of"] +
          " " +
          totalRows +
          " " +
          ConstantsList?.["corporate.crud.pagination_rows"]}
      </label>
      <div className="relative">
        <div
          onClick={() => setOpen(!open)}
          className={`select-pagination-input-container`}
        >
          <div
            className="grid grid-cols-2 gap-2"
            style={{ gridTemplateColumns: "auto auto" }}
          >
            <div className="">
              <span
                className={`${
                  !selected && "opacity-30"
                }   whitespace-nowrap w-full text-xs font-semibold `}
              >
                {selected}
              </span>
            </div>
          </div>
          <FontAwesomeIcon
            className={`h-2.5 ${open && "rotate-180"} duration-300
          ${!selected && "opacity-30"}`}
            icon={faChevronDown}
          />
        </div>
        <ul
          className={`select-pagination-box-container invisible-scroll ${
            open ? "max-h-72" : "max-h-0 opacity-0"
          } duration-300`}
        >
          {options?.map((option, index) => (
            <Fragment key={option}>
              <li
                className={`select-pagination-list-input
            ${
              selected === option &&
              "bg-main-color text-white hover:!text-white"
            }
            `}
                onClick={() => {
                  if (option !== selected) {
                    setSelected(option);
                    setOpen(false);
                    handleChange(Number(option));
                  }
                }}
              >
                {option}
              </li>
              {options?.length - 1 !== index && (
                <div className="px-2">
                  <hr className="divider" />
                </div>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};
