import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faCircleCheck,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ReactNode } from "react";

export type NotifyProviderProps = {
  children: React.ReactNode;
};

export type NotifyMessageType = "Info" | "Success" | "Warning" | "Error";

export type NotifyMsgPositionType =
  | "top_left"
  | "top_right"
  | "bottom_right"
  | "bottom_left"
  | "top_middle"
  | "bottom_middle";

export type NotifyMsgOptionType = {
  message: string | ReactNode;
  type?: NotifyMessageType;
  position?: NotifyMsgPositionType;
  lifetime?: number;
  icon?: IconProp;
  header?: string;
  boxStyle?: string;
  iconStyle?: string;
};

export type NotifyMessageBlock = {
  id: string;
  message: string | React.ReactNode;
  options: NotifyMsgOptionType;
};

export type NotifyDataType = {
  top_left: NotifyMessageBlock[];
  top_right: NotifyMessageBlock[];
  bottom_right: NotifyMessageBlock[];
  bottom_left: NotifyMessageBlock[];
  top_middle: NotifyMessageBlock[];
  bottom_middle: NotifyMessageBlock[];
};

export type NotifyControlDataType = {
  data: NotifyDataType;
  Error(options: NotifyMsgOptionType): void;
  Warning(options: NotifyMsgOptionType): void;
  Success(options: NotifyMsgOptionType): void;
  Info(options: NotifyMsgOptionType): void;
  Notify(options: NotifyMsgOptionType): void;
  remove(id: string, container: string): void;
};

export type NotifyMessageControl = {
  id: string;
  onRemove?: (id: string) => void;
} & NotifyMessageBlock;

export type NotifyContainerProps = {
  values?: NotifyControlDataType;
};

export const NotifyControlInitialData = {
  data: {
    top_left: [],
    top_right: [],
    bottom_right: [],
    bottom_left: [],
    top_middle: [],
    bottom_middle: [],
  },
  Error: () => {},
  Warning: () => {},
  Success: () => {},
  Info: () => {},
  Notify: () => {},
  remove: () => {},
};

export const MsgBlockStyle = {
  Info: {
    boxStyle: "bg-white border-blue-500 ",
    iconStyle: "text-blue-500 ",
    icon: faInfoCircle,
    header: "Info",
  },

  Error: {
    boxStyle: "text-white bg-error-msg-color",
    iconStyle: "opacity-20 text-white ",
    icon: faExclamationCircle,
    header: "Error",
  },

  Warning: {
    boxStyle: "text-white bg-warning-color",
    iconStyle: "opacity-20 text-white ",
    icon: faExclamationCircle,
    header: "Warning",
  },

  Success: {
    boxStyle: "text-white bg-success-msg-color",
    iconStyle: "opacity-20 text-white",
    icon: faCircleCheck,
    header: "Success",
  },
};
