import { Checkbox } from "../../../../../Components";

type PermissionProps = {
  children: any;
  title: string;
  id: string;
  onChange: any;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
};

export const Permission = ({
  children,
  title,
  id,
  onChange,
  defaultChecked,
  checked,
  disabled,
}: PermissionProps) => {
  return (
    <div className="add-role-permissions-container">
      <div>
        <div className="pb-4 px-2">
          <Checkbox
            id={id.toString()}
            afterLabel={title}
            onChange={onChange}
            styles="add-role-check-box"
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
          />
        </div>
        <div className="add-role-permission-child-container">{children}</div>
      </div>
    </div>
  );
};
