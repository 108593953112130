import { useState, ChangeEvent, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCircleXmark,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Loading } from "../Loading";
import "./style.css";
import { SelectImage, SelectorTypes } from "../SelectImage";

type Option = {
  id: number | string;
  name: string;
};

type imgDropdownType = {
  isShow: boolean;
} & SelectorTypes;

interface TextInputTypes {
  id?: string;
  name?: string;
  type?: string;
  label?: string;
  labelStyle?: string;
  inputBoxStyle?: string;
  tag?: string;
  tagStyle?: string;
  placeholder?: string;
  value?: string | number | readonly string[];
  dropdownName?: string; // dropdown name
  dropdown?: boolean;
  defaultOptions?: Option;
  defaultValue?: string;
  dropdownLabel?: string;
  dropdownOptions?: Array<Option>;
  imgDropdown?: imgDropdownType;
  error?: boolean | string;
  errorMsg?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSelect?: Function;
  autoFocus?: boolean;
  disabled?: boolean;
  maxLength?: number;
  tagBackgroundColor?: string;
}

export const TextInput = ({
  id,
  name = "textInput",
  type = "text",
  label = "Text label",
  labelStyle = "",
  inputBoxStyle = "",
  value = "",
  placeholder = "Enter value",
  tag,
  tagStyle,
  dropdownName,
  dropdown = false,
  dropdownLabel = "",
  dropdownOptions,
  defaultOptions,
  imgDropdown = {
    isShow: false,
    id: "",
    name: "",
    label: "",
    options: [],
    defaultOptions: { name: "", id: "", img: "" },
    placeholder: "",
    searchPlaceholder: "",
    onChange: () => {},
  },
  error,
  errorMsg,
  onChange = () => {},
  handleSelect = () => {},
  autoFocus,
  disabled = false,
  defaultValue,
  maxLength,
  tagBackgroundColor,
}: TextInputTypes) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selected, setSelected] = useState<Option>();
  const [hide, setHide] = useState(false);
  useEffect(() => {
    if (!selected && defaultOptions) {
      setSelected(defaultOptions);
    }
  }, [defaultOptions]);
  return (
    <div className="flex flex-col gap-2">
      <label htmlFor={id} className={`input-label ${labelStyle}`}>
        {label}
      </label>
      <div
        className={`input-text-container input-tag-container ${
          error && "border-b-[3px] border-failure-color"
        }${tag ? "flex" : ""} ${inputBoxStyle}`}
      >
        {tag && (
          <label
            htmlFor={id}
            className={`tag ${tagStyle}`}
            style={{ background: `#${tagBackgroundColor}` }}
          >
            {tag}
          </label>
        )}

        {type === "password" && (
          <>
            <FontAwesomeIcon
              onClick={() => setHide(!hide)}
              className={`cursor-pointer absolute rtl:left-5 rtl:right-auto right-5 top-3 ${
                error ? "text-failure-color opacity-100" : ""
              }${!hide ? "opacity-30" : ""}`}
              icon={hide ? faEye : faEyeSlash}
            />
          </>
        )}

        {dropdown && (
          <div className="relative flex items-center">
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="input-dropdown-button"
            >
              <div>
                <span
                  className={`${
                    !selected && "opacity-90"
                  } whitespace-nowrap w-full `}
                >
                  {selected?.name ? selected.name : dropdownLabel}
                </span>
              </div>
              <FontAwesomeIcon
                className={`${showDropdown && "rotate-180"} duration-500`}
                icon={faCaretDown}
              />
            </button>
            {showDropdown && (
              <div
                className={`input-dropdown-list ${
                  dropdown ? "max-h-60" : "max-h-0 opacity-0"
                }`}
              >
                {!dropdownOptions ? (
                  <Loading />
                ) : (
                  dropdownOptions.map((option, idx) => (
                    <div key={option.id}>
                      <button
                        key={option.id}
                        className="text-start p-2 capitalize w-full"
                        onClick={() => {
                          if (
                            option.name.toLocaleLowerCase() !==
                            selected?.name.toLocaleLowerCase()
                          ) {
                            handleSelect(dropdownName, option);
                            setSelected(option);
                            setShowDropdown(false);
                          }
                        }}
                      >
                        {option.name}
                      </button>
                      {idx !== dropdownOptions.length - 1 && <hr />}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        )}

        {imgDropdown?.isShow && (
          <SelectImage
            id={imgDropdown?.id}
            name={imgDropdown?.name}
            label={imgDropdown?.label}
            options={imgDropdown?.options}
            defaultOptions={imgDropdown?.defaultOptions}
            placeholder={imgDropdown?.placeholder}
            searchPlaceholder={imgDropdown?.searchPlaceholder}
            onChange={imgDropdown?.onChange}
          />
        )}

        <input
          autoFocus={autoFocus}
          autoComplete="off"
          id={id}
          name={name}
          type={!hide ? type : "text"}
          value={value}
          className="input-tag"
          // className="input-tag dark:text-white"
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          maxLength={maxLength}
        />
        {error && (
          <div className="input-error-msg">
            <FontAwesomeIcon icon={faCircleXmark} />
            <span className="text-[0.8rem] font-normal">{errorMsg}</span>
          </div>
        )}
      </div>
    </div>
  );
};
