import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Navlink,
  Selector,
  TextInput,
} from "../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { addDiscount } from "../DiscountsTableContent";
import { useNavigate } from "react-router-dom";
import { DiscountDataType, DiscountOptionType } from "../DiscountTypes";
import { object } from "../../../../utils/ValidateErrors";
import {
  LanguageNameDiscount,
  useLanguageName,
} from "../../../../hooks/useLanguageName";
import { createDiscountAction } from "../../../../redux/discount/discountAction";
import { fetchCurrencyListAction } from "../../../../redux/list/listAction";
import { useDiscountStateData } from "../useDiscountStateData";

export const AddDiscounts: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const flag = useRef(false);

  const initialData = useMemo(() => {
    return {
      name: "",
      currency_id: "",
      price_type: "",
      price: "",
      price_type_name: "",
      is_active: 0,
    };
  }, []);

  const initialDataErrors = useMemo(() => {
    return {
      ...initialData,
      is_active: "",
    };
  }, [initialData]);

  const [discountData, setDiscountData] =
    useState<DiscountDataType>(initialData);

  const [errors, setErrors] = useState(initialDataErrors);
  const { currencies } = useAppSelector((state) => state.list);
  const { customEnum } = useDiscountStateData();

  useEffect(() => {
    dispatch(fetchCurrencyListAction());
  }, [dispatch]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setDiscountData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setDiscountData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleChangeCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setDiscountData((prev) => ({
      ...prev,
      [name]: checked ? 1 : 0,
    }));
  }, []);

  const handleCurrencyChange = useCallback(
    (name: string, value: DiscountOptionType) => {
      setDiscountData((prev) => ({
        ...prev,
        [name]: value.id,
      }));
    },
    []
  );

  const handlePriceTypeChange = useCallback(
    (name: string, value: DiscountOptionType) => {
      setDiscountData((prev) => ({
        ...prev,
        [name]: value.id,
      }));
    },
    []
  );

  const validate = useCallback(() => {
    const discountSchema = object({
      name: ["required"],
      currency_id: ["required"],
      price_type: ["required"],
      price: ["required"],
    });
    const result = discountSchema.validate(discountData);
    setErrors(result.errors);
    return result.valid;
  }, [discountData]);

  const saveDiscount = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(createDiscountAction(discountData)).then(({ payload }) => {
          payload?.success
            ? navigate("/settings/discounts")
            : setErrors(payload?.message);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, discountData, validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  const btnLabel = useLanguageName("corporate.settings.discounts.create.save");

  return (
    <div>
      <Navlink
        path="corporate.settings.discounts.create.title"
        routes={addDiscount}
      >
        <Button
          form="add-discount-form"
          type="submit"
          styles="bg-main-color text-white h-10 w-full p-0 md:w-[151px] mt-5 md:mt-0 "
          labelStyles="text-sm"
          label={btnLabel}
          icon={null}
        />
      </Navlink>
      <div className="discount-parent-container">
        <form
          id="add-discount-form"
          onSubmit={saveDiscount}
          className="discount-form"
          noValidate
        >
          <div className="discount-first-row-grid">
            <TextInput
              id="discount_name"
              name="name"
              label={LanguageNameDiscount("name")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              placeholder={LanguageNameDiscount("name_placeholder")}
              value={discountData?.name}
              onChange={handleNameChange}
              error={errors?.name}
              errorMsg={errors?.name}
            />
            <div className="relative">
              <Selector
                id="discount_currency"
                name="currency_id"
                label={LanguageNameDiscount("currency_id")}
                placeholder={LanguageNameDiscount("currency_placeholder")}
                labelStyle="!text-base"
                searchPlaceholder="Search currency"
                onChange={handleCurrencyChange}
                options={currencies?.data}
              />
            </div>
          </div>
          <div className="discount-second-row-grid">
            <div className="relative md:col-span-1 col-span-2">
              <Selector
                id="discount_price_type"
                name="price_type"
                label={LanguageNameDiscount("price_type")}
                placeholder={LanguageNameDiscount("price_type_placeholder")}
                labelStyle="!text-base"
                searchPlaceholder="Search type"
                onChange={handlePriceTypeChange}
                options={customEnum}
              />
            </div>
            <div className="md:col-span-1 col-span-2 w-full">
              <TextInput
                id="discount_value"
                name="price"
                label={LanguageNameDiscount("price")}
                placeholder={LanguageNameDiscount("price_placeholder")}
                labelStyle="!text-base"
                inputBoxStyle="h-11"
                value={discountData?.price}
                onChange={handleValueChange}
                error={errors?.price}
                errorMsg={errors?.price}
              />
            </div>
            <div className="discount-checkbox">
              <div className="discount-checkbox-flex">
                <Checkbox
                  id="discount_is_active"
                  name="is_active"
                  afterLabel={LanguageNameDiscount("is_active")}
                  styles="checked:bg-main-color"
                  onChange={handleChangeCheck}
                  error={errors?.is_active}
                  errorMsg={errors?.is_active}
                />
                <p className="is_action-description">
                  {LanguageNameDiscount("is_active_description")}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
