import { Button, Selector } from "../../../../Components";
import "./style.css";

export const AddressForm = () => {
  return (
    <div className="grider ">
      <div className="w-full">
        <div className="titleText">From</div>
        <div className="fromContainer">
          <div className="col">
            <div className="boldText">Ulink Sp. z o.o. Ul.Szlak</div>
            <div className="normalText">77/222 31-153 Krakow Poland</div>
            <div className="normalText">
              <span className="boldText ">Tax ID: </span>
              676 263 68 05
            </div>
          </div>
          <Button
            styles="AddressFormButton"
            icon={null}
            label="Edit Business Profile"
          />
        </div>
      </div>

      <div className="w-full">
        <div className="titleText">To</div>
        <div className="toContainer">
          <div className="w-full lg:flex-row flex-col gap-y-6 flex gap-x-5">
            <div className="relative w-full">
              <Selector
                label=""
                // options={["Client 1", "Client 2", "Client 3"]}
                placeholder="Choose Client"
                searchPlaceholder="Search currency"
              />
            </div>
            <Button
              styles="AddressFormButton"
              icon={null}
              label="Add New Client"
            />
          </div>
          <div className="col">
            <div className="boldText">Alfredo Botosh</div>
            <div className="normalText">77/222 31-153 Krakow Poland</div>
            <div className="normalText">
              <span className="boldText ">Tax ID: </span>
              676 263 68 05
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
