import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  ActionButton,
  Checkbox,
  LoadingLayout,
  Navlink,
  TextInput,
} from "../../../../Components";
import { ActionItemBtnRows, showItemNavLinkList } from "../ItemsTableContent";
import { LanguageNameItemData } from "../../../../hooks/useLanguageName";
import { fetchItemsAction } from "../../../../redux/items/itemsAction";
import { useAddItemStateData } from "../useAddItemStateData";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../style.css";
import { ItemDeleteModal } from "../Components/ItemDeleteModal";

export const ShowItem: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    data: { Item },
    isLoading,
  } = useAppSelector((state) => state?.items?.item);
  const { customEnum } = useAddItemStateData();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(fetchItemsAction(id));
  }, [dispatch, id]);

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };

  return (
    <div>
      <Navlink
        path="corporate.settings.items.show.title"
        routes={showItemNavLinkList}
      >
        <ActionButton
          data={ActionItemBtnRows({
            paths: {
              edit: `/settings/update-item/${id}`,
              handleModalClose: () => setShowDeleteModal(true),
            },
          })}
          icon={faChevronDown}
          styles="employee-action-show-btn"
        />
      </Navlink>
      {
        <div className="table-container">
          <div id="employee-form" className="form-box-container">
            <LoadingLayout isLoading={isLoading}>
              <div className="form-box">
                <TextInput
                  id="item_name"
                  name="name"
                  label={LanguageNameItemData("name")}
                  labelStyle="!text-base"
                  inputBoxStyle="h-11"
                  value={Item?.name || " "}
                  disabled
                />
                <TextInput
                  id="item_description"
                  name="description"
                  label={LanguageNameItemData("description")}
                  labelStyle="!text-base"
                  inputBoxStyle="h-11"
                  value={Item?.description || " "}
                  disabled
                />
                <TextInput
                  id="item_unit_id"
                  name="unit_id"
                  label={LanguageNameItemData("unit_id")}
                  labelStyle="!text-base"
                  inputBoxStyle="h-11"
                  value={Item?.Units?.name || " "}
                  disabled
                />
                <TextInput
                  id="item_type"
                  name="type"
                  label={LanguageNameItemData("type")}
                  labelStyle="!text-base"
                  inputBoxStyle="h-11"
                  value={
                    customEnum?.filter((en: any) => en?.id === Item?.type)[0]
                      ?.name || ""
                  }
                  disabled
                />
                <TextInput
                  id="item_currency_id"
                  name="currency_id"
                  label={LanguageNameItemData("currency_id")}
                  labelStyle="!text-base"
                  inputBoxStyle="h-11"
                  value={Item?.Currency?.name || " "}
                  disabled
                />
                <TextInput
                  id="item_price"
                  name="price"
                  label={LanguageNameItemData("price")}
                  labelStyle="!text-base"
                  inputBoxStyle="h-11"
                  value={Item?.price || " "}
                  disabled
                />
                <div className="flex gap-1">
                  <Checkbox
                    id="item_is_active"
                    name="is_active"
                    afterLabel={LanguageNameItemData("is_active")}
                    styles="checked:bg-main-color"
                    checked={Item?.is_active || 0}
                  />
                  <p className="is_active_description ">
                    ({LanguageNameItemData("is_active_description")})
                  </p>
                </div>
              </div>
            </LoadingLayout>
          </div>
        </div>
      }
      <ItemDeleteModal
        handleModalClose={handleModalClose}
        showModal={showDeleteModal}
      />
    </div>
  );
};
