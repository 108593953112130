import { useState, useEffect, Fragment, useCallback } from "react";
import {
  IconDefinition,
  faChevronDown,
  faCircleXmark,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEscapeKeyPressed } from "../../../hooks/useEscapeKeyPressed";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import "./style.css";

type Option = {
  id: number | string;
  name: string;
};

interface SelectorTypes {
  id?: string;
  name?: string;
  label: string;
  labelStyle?: string;
  options?: Array<Option>;
  defaultOptions?: Option;
  placeholder: string;
  searchPlaceholder?: string;
  icon?: IconDefinition;
  error?: boolean | string;
  value?: string;
  errorMsg?: string;
  disabled?: boolean;
  onChange?: (name: string, value: Option) => void;
}

export const Selector = ({
  id,
  name = "selector_input",
  label = "Selector label",
  labelStyle = "",
  options = [
    { id: 0, name: "Option 1" },
    { id: 1, name: "Option 2" },
    { id: 2, name: "Option 3" },
  ],
  defaultOptions,
  placeholder = "Selector placeholder",
  searchPlaceholder = "search placeholder",
  icon,
  // value,
  error,
  errorMsg = "",
  onChange = () => {},
  disabled = false,
}: SelectorTypes) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<Option>();
  const [open, setOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);

  const handleCloseDrop = () => {
    setOpen(false);
  };
  useEscapeKeyPressed(handleCloseDrop, open);
  useOnClickOutside(
    targetRef,
    useCallback(() => {
      setOpen(false);
    }, [])
  );

  useEffect(() => {
    setSelected(defaultOptions);
  }, [defaultOptions]);

  const handleOpen = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };
  return (
    <div className="gap-2" id={id} ref={setTargetRef}>
      {label && (
        <label className={`input-label block mb-2 ${labelStyle}`}>
          {label}
        </label>
      )}
      <div
        onClick={handleOpen}
        className={`selector-input-container h-11 ${
          error && "border-b-[3px] border-failure-color"
        }`}
      >
        <div
          className="grid grid-cols-2 gap-2"
          style={{ gridTemplateColumns: "auto auto" }}
        >
          {icon && (
            <FontAwesomeIcon
              className={`h-4 w-4 mt-1 ${!selected && "opacity-30"}`}
              // className={`dark:text-white h-4 w-4 mt-1 ${!selected && "opacity-30"}`}
              icon={icon}
            />
          )}
          <div className="truncate">
            <span
              className={`${
                !selected && "opacity-30"
              }   whitespace-nowrap w-full `}
            >
              {selected?.name ? selected.name : placeholder}
            </span>
          </div>
        </div>
        <FontAwesomeIcon
          className={`h-3 ${open && "rotate-180"} duration-300
          ${!selected && "opacity-30"}`}
          icon={faChevronDown}
        />
      </div>
      {error && (
        <div className="input-error-msg top-[68px]">
          <FontAwesomeIcon icon={faCircleXmark} />
          <span className="text-[0.8rem] font-normal">{errorMsg}</span>
        </div>
      )}
      <ul
        className={`selector-box-container invisible-scroll ${
          open ? "max-h-72" : "max-h-0 opacity-0"
        } duration-300`}
      >
        <div className="selector-search-container">
          <FontAwesomeIcon className="icon-color" icon={faSearch} />
          <input
            type="text"
            placeholder={searchPlaceholder}
            className="selector-search-input"
            value={search}
            onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
          />
        </div>
        {options.map((option, index) => (
          <Fragment key={option.id}>
            <li
              className={`selector-list-input ${
                option.name.toLocaleLowerCase().startsWith(search)
                  ? "block"
                  : "hidden"
              }
            ${
              selected?.name?.toLocaleLowerCase() ===
                option?.name?.toLocaleLowerCase() &&
              "bg-main-color text-white hover:text-white"
            }
            `}
              onClick={() => {
                if (
                  option?.name?.toLocaleLowerCase() !==
                  selected?.name?.toLocaleLowerCase()
                ) {
                  setSelected(option);
                  setOpen(false);
                  onChange(name, option);
                }
              }}
            >
              {option.name}
            </li>
            {options?.length - 1 !== index && (
              <div className="px-5">
                <hr className="divider" />
              </div>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};