import { ChangeEvent } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

interface SearchType {
  id?: string;
  name: string;
  type?: string;
  label: string;
  placeholder: string;
  value?: string | number | readonly string[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Search = ({
  id = "search",
  name = "Search",
  type = "text",
  label = "Search",
  placeholder = "Search",
  value,
  onChange = () => {},
}: SearchType) => {
  return (
    <>
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <div className="relative">
        <div className="input-search-icon">
          <FontAwesomeIcon className="icon-color" icon={faMagnifyingGlass} />
        </div>
        <input
          id={id}
          name={name}
          type={type}
          className="input-search"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

