import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Selector, TextArea, TextInput } from "../../Components";

export const Expenses = () => {
  return (
    <div className="grid grid-cols p-8 w-full gap-8">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col justify-start gap-1">
          <div className="flex flex-row justify-start items-center gap-4">
            <FontAwesomeIcon icon={faChevronLeft} />
            <h1 className="text-xl font-medium text-liet-black-color opacity-80">
              Add Expense
            </h1>
          </div>
          <div className="flex flex-row gap-4 ">
            <div className="flex flex-row justify-start items-center gap-4">
              <span className="text-base font-light text-liet-black-color opacity-70">
                dashboard
              </span>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="flex flex-row justify-start items-center gap-4">
              <span className="text-base font-light text-liet-black-color opacity-70">
                Expenses
              </span>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>

            <div className="flex flex-row justify-start items-center gap-4">
              <span className="text-base font-light text-liet-black-color opacity-70">
                Add Expense
              </span>
            </div>
          </div>
        </div>
        <Button
          label="Save"
          // bgColor="bg-primary-background-color"
          // padding="py-2.5 px-12 "
          // textColor="text-white"
        />
      </div>
      <div className="grid grid-cols p-8 bg-white rounded-second-radius w-full drop-shadow-md">
        <div className="grid grid-rows-6 gap-8 ">
          <div className="grid grid-cols-2 gap-8">
            <TextInput
              label="Name"
              placeholder="Enter expense name"
              onChange={() => {}}
            />
            <TextInput
              label="Expense Number"
              placeholder="Enter expense number"
              onChange={() => {}}
            />
          </div>
          <div className="grid grid-cols-2 gap-8">
            <TextInput
              label="Purchase Order Number"
              placeholder="Enter purchase order number"
              onChange={() => {}}
            />
            <Selector
              label="Vendor"
              // options={[""]}
              placeholder="Choose vendor"
            />
          </div>
          <div className="grid grid-cols-2 gap-8">
            <Selector
              label="Employee"
              // options={[""]}
              placeholder="Choose employee"
            />
            <Selector
              label="Employee"
              // options={[""]}
              placeholder="Choose employee"
            />
          </div>
          <div className="grid grid-cols-2 gap-8">
            <div className="col-span-1 flex w-full gap-8">
              <div className="w-3/5">
                <Selector
                  label="Currency"
                  // options={[""]}
                  placeholder="Choose currency"
                />
              </div>
              <div className="w-2/5">
                <TextInput
                  label="Amount"
                  placeholder="Enter amount"
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="col-span-1">
              <Selector
                label="Expense Type"
                // options={[""]}
                placeholder="Choose type"
              />
            </div>
          </div>
          <div className="row-span-2 grid grid-cols-2 gap-8">
            <TextArea
              label="Note"
              placeholder="Write some notes for this expense"
              rows={3}
              name={""}
              cols={0}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
