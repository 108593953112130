import { FC, useCallback } from "react";
import { DeleteModal, useNotify } from "../../../../../Components";

import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { itemModalType } from "../itemHeaderType";
import { deleteItemAction } from "../../../../../redux/items/itemsAction";

export const ItemDeleteModal: FC<itemModalType> = ({
  showModal,
  handleModalClose = () => {},
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Notify = useNotify();

  const { message }: any = useAppSelector((state) => state.items?.itemDeleted);

  const handleDelete = useCallback(() => {
    dispatch(
      deleteItemAction({
        id: `${id}`,
        cb: () => {
          Notify.Success({ message: message?.success });
          handleModalClose();
          navigate("/settings/items");
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, message?.success]);

  return (
    <DeleteModal
      showModal={showModal}
      handleModalClose={handleModalClose}
      handleDelete={handleDelete}
      keys={{ description: "corporate.settings.items.show.delete.description" }}
    />
  );
};
