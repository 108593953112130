import {
  faChartPie,
  faChartSimple,
  faDolly,
  faFileInvoice,
  faGear,
  faMoneyBillWave,
  faReceipt,
  faRepeat,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export const list = (permission, languages) => {
  return [
    {
      id: 0,
      path: "/dashboard",
      label: languages?.data?.ConstantsList?.["corporate.dashboard"],
      icon: faChartPie,
      visible: permission?.includes("corporate.dashboard") || false,
      spacing: false,
    },
    {
      id: 1,
      path: "/reports",
      label: languages?.data?.ConstantsList?.["corporate.reports"],
      icon: faChartSimple,
      visible: permission?.includes("corporate.reports") || false,
      spacing: false,
    },
    {
      id: 2,
      path: "/invoices",
      label: languages?.data?.ConstantsList?.["corporate.invoices"],
      icon: faFileInvoice,
      visible: permission?.includes("corporate.invoices") || false,
      spacing: false,
    },
    {
      id: 3,
      path: "/recurring-invoices",
      label:  languages?.data?.ConstantsList?.["corporate.recurring_invoices"],
      icon: faRepeat,
      visible: permission?.includes("corporate.recurring_invoices") || false,
      spacing: false,
    },
    {
      id: 4,
      path: "/expenses",
      label: languages?.data?.ConstantsList?.["corporate.expenses"],
      icon: faReceipt,
      visible: permission?.includes("corporate.expenses") || false,
      spacing: false,
    },
    {
      id: 5,
      path: "/clients",
      label: languages?.data?.ConstantsList?.["corporate.clients"],
      icon: faUser,
      visible: permission?.includes("corporate.clients") || false,
      spacing: false,
    },
    {
      id: 6,
      path: "/vendors",
      label: languages?.data?.ConstantsList?.["corporate.vendors"],
      icon: faDolly,
      visible: permission?.includes("corporate.vendors") || false,
      spacing: false,
    },
    {
      id: 7,
      path: "/payments",
      label: languages?.data?.ConstantsList?.["corporate.payments"],
      icon: faMoneyBillWave,
      visible: permission?.includes("corporate.payments") || false,
      spacing: false,
    },
    {
      id: 8,
      label: "General Settings",
      path: "/settings",
      icon: faGear,
      visible: permission?.includes("corporate.settings") || false,
      spacing: false,
      // submenu: true,
      // children: [
      //   { path: "/settings/corporate", label: "Corporate Settings" },
      //   { path: "/settings/discounts", label: "Discounts" },
      //   { path: "/settings/add-discounts", label: "Add Discounts" },
      //   { path: "/settings/employees", label: "Employees" },
      //   { path: "/settings/add-employee", label: "Add Employee" },
      //   { path: "/settings/expenses-types", label: "Expenses Types" },
      //   { path: "/settings/add-expenses-type", label: "AddExpenses Type" },
      //   { path: "/settings/items-setting", label: "Items Setting" },
      //   { path: "/settings/add-item-setting", label: "AddItem Setting" },
      //   { path: "/settings/roles", label: "Roles" },
      //   { path: "/settings/add-role", label: "Add Role" },
      //   { path: "/settings/shipping", label: "Shipping" },
      //   { path: "/settings/add-shipping", label: "Add Shipping" },
      //   { path: "/settings/taxes", label: "Taxes" },
      //   { path: "/settings/add-taxes", label: "Add Taxes" },
      // ],
    },
  ];
};
