import { FC, useState } from "react";
import {Navlink} from "../../Components";
import { Items } from "./data";
import './style.css';
import {Link} from "react-router-dom";


export const Notifications: FC = () => {
  return (
       <div className="notification-container flex flex-col gap-8">
        <div className="flex flex-row justify-center ">
             <Navlink
        path="Notifications"
        header="Show your recent notifications on your system"
      
      />  
        </div>
      
        <div className="notification-head-container grid grid-rows ">
            {Items.map((item:any,index )=> {
                return (
                  
                  <Link  key={item.id} className={`notification-box hover:shadow-md  ${index === Items.length - 1 ? "border-none" : 'border-b'}`} to={""} >
                <img className="notification-img " src={item.img} alt="#" />
                <div className="notification-text-container ">
                    <div className="notification-text ">
                      <div className="notification-text-span ">
                      <span className="notification-name">{item.name}</span><p className="notification-desc">{item.desc}</p>
                        </div>
                       
                        
                        {item.new && <div className="notification-new bg-second-color rounded">New</div>}
                       
                    </div>
                    
                    <span className="notification-active">{item.active}</span>
                </div>
            </Link>   
                )
            })}
           




        </div>

       </div>
    );
};