import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {object} from '../../utils/ValidateErrors';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {fetchAvailableLanguagesAction} from '../../redux/general/generalAction';
import {
  fetchCitiesAction,
  fetchCountriesAction,
} from '../../redux/list/listAction';
import {
  Button,
  Navlink,
  Selector,
  TextInput,
  UploadImage,
  // useNotify,
} from '../../Components';
import './style.css';
import {updateEmployeeAction} from '../../redux/employee/employeeAction';
import {
  fetchUserInfoAction,
  updateUserInfoAction,
} from '../../redux/auth/authAction';

type ProfileData = {
  name: string;
  email: string;
  mobile: string;
  avatar: string;
  country_id?: string;
  city_id?: string;
  locale_id?: string;
};
type Option = {
  id: number | string;
  name: string;
};

export const Profile: FC = () => {
  const [profileData, setProfileData] = useState<ProfileData>({
    name: '',
    email: '',
    avatar: '',
    mobile: '',
    country_id: '',
    city_id: '',
    locale_id: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    avatar: '',
    mobile: '',
    country_id: '',
    city_id: '',
    locale_id: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUploadImageChange = (name: string, value: File | undefined) => {
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (name: string, value: Option) => {
    setProfileData((prev) => ({
      ...prev,
      [name]: value.id,
    }));
  };
  const validate = useCallback(() => {
    const profileSchema = object({
      name: ['required'],
      email: ['required', 'email'],
      avatar: ['image'],
      mobile: ['required'],
    });
    const result = profileSchema.validate(profileData);
    setErrors(result.errors);
    return result.valid;
  }, [profileData]);

  const flag = useRef(false);

  const saveProfile = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(updateUserInfoAction(profileData));
      }
    },
    [validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  const {
    availableLanguages: {
      data: {Locales},
    },
  } = useAppSelector((state) => state?.general);

  const {countries, cities} = useAppSelector((state) => state?.list);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserInfoAction());
    dispatch(fetchAvailableLanguagesAction());
    dispatch(fetchCountriesAction());
  }, [dispatch]);

  useEffect(() => {
    if (profileData.country_id) {
      dispatch(fetchCitiesAction(`?country_id=${profileData?.country_id}`));
    }
  }, [dispatch, profileData.country_id]);

  const {
    userInfo: {data},
    isLoading,
  } = useAppSelector((state) => state?.auth);
  const res = useAppSelector((state) => state?.auth);
 
  useEffect(() => {
    if (data.CorporateEmployee) {
      setProfileData(data.CorporateEmployee);
    }
  }, [data.CorporateEmployee]);

  return (
    <div className="profile-container flex flex-col gap-8">
      <Navlink
        path="Profile"
        header="Show your information and main profile data"
        children={
          <Button
            form="profile-form"
            type="submit"
            styles="bg-main-color text-white w-full hover:opacity-70"
            label="Save Changes"
            icon={null}
            isLoading={isLoading}
          />
        }
      />
      <form id="profile-form" onSubmit={saveProfile} noValidate>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-7">
          <div className="col-span-2 space-y-7 order-2 lg:order-1">
            <TextInput
              id="profile_name"
              name="name"
              label="Name"
              placeholder=""
              onChange={handleChange}
              error={errors?.name}
              errorMsg={errors?.name}
              value={profileData?.name}
            />

            <TextInput
              id="profile_email"
              name="email"
              label="Email"
              placeholder=""
              onChange={handleChange}
              error={errors?.email}
              errorMsg={errors?.email}
              value={profileData?.email}
            />
          </div>
          <div className="col-span-1 order-1 lg:order-2 self-center">
            <UploadImage
              name="avatar"
              image={profileData?.avatar}
              isAvatar
              onChange={handleUploadImageChange}
              styles="h-44 w-44"
              error={errors?.avatar}
              errorMsg={errors?.avatar}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
          <div>
            <TextInput
              id="profile_mobile"
              name="mobile"
              label="Mobile Number"
              placeholder="Enter mobile number"
              onChange={handleChange}
              handleSelect={handleSelectChange}
              error={errors?.mobile}
              errorMsg={errors?.mobile}
              dropdown
              dropdownName="country_id"
              dropdownLabel="Country"
              dropdownOptions={countries.data}
              value={profileData?.mobile}
              defaultOptions={
                countries.data?.filter(
                  (item: any) => item.id === profileData.country_id
                )[0]
              }
            />
          </div>

          <div className="relative">
            <Selector
              id="profile-language"
              name="locale_id"
              label="Language"
              options={Locales}
              placeholder="Choose language"
              searchPlaceholder="Search language"
              onChange={handleSelectChange}
              error={errors?.locale_id}
              errorMsg={errors?.locale_id}
              defaultOptions={
                Locales?.filter(
                  (item: any) => item.id === profileData.locale_id
                )[0]
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="relative">
            <Selector
              id="profile-city"
              name="city_id"
              label="City"
              options={cities?.data}
              placeholder="Choose city"
              searchPlaceholder="Search city"
              onChange={handleSelectChange}
              error={errors?.city_id}
              errorMsg={errors?.city_id}
              defaultOptions={
                cities.data?.filter(
                  (item: any) => item.id === profileData.city_id
                )[0]
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};
