import {
  faFileCsv,
  faFilePdf,
  faPenToSquare,
  faPrint,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { CellName, CellStatus } from "../../../Components";
import { usePermission } from "../../../hooks/usePermission";
import { useLanguageName } from "../../../hooks/useLanguageName";
import { useNavigate } from "react-router-dom";

export const CellComponentRoles = [
  {
    Component: CellName,
    compProps: {
      parKey: "name",
    },
  },
  {
    Component: CellStatus,
    compProps: {
      customWidth: "p-3 w-[1%]",
    },
  },
];

export const RoleTableActionBtnData = () => {
  const exportLanguages = useLanguageName("corporate.crud.export");
  const exportCsvLanguages = useLanguageName("corporate.crud.export_csv");
  const exportPdfLanguages = useLanguageName("corporate.crud.export_pdf");
  return [
    {
      id: 0,
      name: exportLanguages,
      icon: faPrint,
      permission: true,
    },
    {
      id: 1,
      name: exportCsvLanguages,
      icon: faFileCsv,
      permission: true,
    },
    {
      id: 2,
      name: exportPdfLanguages,
      icon: faFilePdf,
      permission: true,
    },
  ];
};

export const roleNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.roles",
    path: "/settings/roles",
  },
];

export const showRoleNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.roles",
    path: "/settings/roles",
  },
  {
    name: "corporate.settings.roles.show",
    path: `/settings/roles/:id/update`,
  },
];

export const addRoleNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.roles",
    path: "/settings/roles",
  },
  {
    name: "corporate.settings.roles.create",
    path: "/settings/roles/create",
  },
];

export const updateRoleNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.roles",
    path: "/settings/roles",
  },
  {
    name: "corporate.settings.roles.update",
    path: `/settings/roles/:id/update`,
  },
];

export const ActionBtnRows = ({ paths }: any) => {
  const { edit, setShowDeleteModal } = paths;
  const navigate = useNavigate();
  const updateRoleLanguages = useLanguageName(
    "corporate.settings.roles.update"
  );
  const deleteRoleLanguages = useLanguageName(
    "corporate.settings.roles.delete"
  );
  return [
    {
      id: 0,
      name: updateRoleLanguages,
      icon: faPenToSquare,
      path: edit,
      permission: usePermission("corporate.settings.roles.update"),
      optionClick: () => navigate(edit),
    },
    {
      id: 2,
      name: deleteRoleLanguages,
      icon: faX,
      permission: usePermission("corporate.settings.roles.destroy"),
      optionClick: () => setShowDeleteModal(true),
    },
  ];
};
