import { useState, FC, useCallback } from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEscapeKeyPressed } from "../../../hooks/useEscapeKeyPressed";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import "./style.css";

interface SelectorTypes {
  list?: any;
  icon?: IconDefinition;
  label?: string;
  listCustomCss?: string
}

export const CustomSelector: FC<SelectorTypes> = ({
  list,
  icon,
  label = "Selector label",
  listCustomCss,
}) => {
  const [open, setOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const handleCloseDrop = () => {
    setOpen(false);
  };
  useEscapeKeyPressed(handleCloseDrop, open);

  useOnClickOutside(
    targetRef,
    useCallback(() => {
      setOpen(false);
    }, [])
  );

  return (
    <div className="relative" ref={setTargetRef}>
      <div
        onClick={() => setOpen(!open)}
        className={`custom-selector-btn
        ${open && "custom-selector-btn-opened"}`}
      >
        {icon && (
          <FontAwesomeIcon className={`custom-selector-btn-icon`} icon={icon} />
        )}
        {label}
      </div>
      {open && (
        <div className={`custom-selector-container-list duration-300 ${listCustomCss}`}>
          {list}
        </div>
      )}
    </div>
  );
};
