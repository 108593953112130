import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SettingsTypes, optionTypes } from "./CorporateSettingsType";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchCorporateSettingAction } from "../../../redux/corporate_settings/corporateSettingsAction";
import {
  fetchCitiesAction,
  fetchCountriesAction,
  fetchCurrencyListAction,
  fetchUnitsListAction,
} from "../../../redux/list/listAction";
import { fetchAvailableLanguagesAction } from "../../../redux/general/generalAction";

export const useCorporateSettingData = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(
    (state) => state.corporateSettings?.corporateSettings
  );
  const { units, currencies, countries, cities } = useAppSelector(
    (state) => state.list
  );
  const Locales = useAppSelector((state) => state.general?.availableLanguages);

  useEffect(() => {
    dispatch(fetchCorporateSettingAction());
  }, [dispatch]);

  useEffect(() => {
    units?.data?.length === 0 && dispatch(fetchUnitsListAction());
    currencies?.data?.length === 0 && dispatch(fetchCurrencyListAction());
    Locales?.data?.length === 0 && dispatch(fetchAvailableLanguagesAction());
    countries?.data?.length === 0 && dispatch(fetchCountriesAction());
  }, []);

  const [settingsData, setSettingsData] = useState<SettingsTypes>({
    name: "",
    email: "",
    url: "",
    register_id: "",
    tax_id: "",
    phone_number: "",
    mobile_number: "",
    contact_name: "",
    contact_email: "",
    contact_mobile_number: "",
    address: "",
    postal_code: "",
  });

  useEffect(() => {
    setSettingsData(data);
  }, [data]);

  useEffect(() => {
    if (settingsData?.country_id) {
      dispatch(fetchCitiesAction(`?country_id=${settingsData?.country_id}`));
    }
  }, [dispatch, settingsData?.country_id]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSettingsData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = useCallback((name: string, value: optionTypes) => {
    setSettingsData((prev) => ({
      ...prev,
      [name]: value.id,
    }));
  }, []);

  return {
    settingsData,
    setSettingsData,
    handleChange,
    handleSelectChange,
    units: units || [],
    currencies: currencies || [],
    Locales: Locales?.data?.Locales || [],
    countries: countries || [],
    cities: cities || [],
    isLoading,
  };
};
