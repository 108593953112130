import { useState } from "react";
import "./style.css";

// props example
// tabsData={[
//   {
//     label: "label 1",
//     content:
//       "Ut irure"
//   }
// ]}

type Tab = {
  id: string | number;
  label: string;
  content: any;
};

interface TabsTypes {
  tabsData: Array<Tab>;
  showActiveTabIndex?: boolean;
}

export function Tabs({ tabsData, showActiveTabIndex = false }: TabsTypes) {
  const [activeTabIndex, setActiveTabIndex] = useState(1);

  return (
    <div>
      <div className="tabs-container">
        {/* Loop through tab data and render button for each. */}
        {tabsData?.map((tab, idx) => {
          return (
            <button
              key={idx}
              className={`tabs-btn ${
                idx === activeTabIndex
                  ? "tabs-btn-active"
                  : "tabs-btn-nonactive"
              }`}
              type="button"
              // Change the active tab on click.
              onClick={() => setActiveTabIndex(idx)}
            >
              {tab.label}
            </button>
          );
        })}
      </div>
      {/* Show active tab content. */}
      {tabsData[activeTabIndex].id === activeTabIndex && (
        <div className="py-4">
          {/* <p>{tabsData[activeTabIndex].content}</p> */}
          {tabsData[activeTabIndex].content}
        </div>
      )}
    </div>
  );
}
