import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionButton,
  Button,
  Navlink,
  Table,
  useNotify,
} from "../../../Components";
import {
  CellComponentExpensesTypes,
  ExpensesTypesTableActionBtnData,
  expensesTypesNavLinkList,
} from "./ExpensesTypesTableContent";
import { FilterTab } from "./Components/FilterTab";
import { SortTab } from "./Components/SortTab";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useQueryModifier } from "../../../hooks/useQueryModifier";
import { usePermission } from "../../../hooks/usePermission";
import { fetchAllExpenseTypesAction } from "../../../redux/expense_types/expenseTypesAction";
import { useLanguageName } from "../../../hooks/useLanguageName";
import "./style.css";

export const ExpenseTypes: FC = () => {
  const navigate = useNavigate();
  const { message, isLoading, data } = useAppSelector(
    (state) => state?.expenses_types?.expenses_types
  );
  const Notify = useNotify();

  const { query, resetQueryParams } = useQueryModifier();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllExpenseTypesAction(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    Object.keys(message)?.map((el: any) =>
      Notify?.Error({ message: message?.[el] })
    );
    Object.keys(message)?.length && resetQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const createExpensesBtn = useLanguageName(
    "corporate.settings.expenses_types.create"
  );

  return (
    <div>
      <Navlink
        path={"corporate.settings.expenses_types"}
        routes={expensesTypesNavLinkList}
      >
        {usePermission("corporate.settings.expenses_types.create") && (
          <Button
            type="button"
            styles="expenses-type-create-btn"
            labelStyles="text-sm"
            label={createExpensesBtn}
            icon={null}
            onClick={() => navigate("/settings/add-expense-type")}
          />
        )}
      </Navlink>
      <div className="table-container pt-0 w-full">
        <Table
          cellComponent={CellComponentExpensesTypes}
          filterHeader={{
            filter: <FilterTab />,
            sort: <SortTab />,
            control: (
              <ActionButton
                label={useLanguageName("corporate.crud.export")}
                styles="expenses-type-export-btn"
                data={ExpensesTypesTableActionBtnData()}
              />
            ),
          }}
          data={data?.ExpenseTypes}
          rowLink="/settings/show-expense-type/"
          isLoading={isLoading}
          is_pagination={true}
          pagination={{
            paginateOptions: {
              currentPage: data?.pagination?.current_page,
              totalCount: data?.pagination?.total,
              pageSize: data?.pagination?.per_page,
            },
          }}
        />
      </div>
    </div>
  );
};
