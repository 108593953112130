import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Selector,
  Tabs,
  TextInput,
  UploadImage,
} from "../../../Components";
import "./style.css";

export const AddClient = () => {
  const [data, setData] = useState({});

  const handleChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const handleImageChange = (value: any) => {
    setData({ ...data, image: value });
  };

  console.log(setData);
  return (
    <div className="addclient-container">
      <div className="container">
        <div className="head-col-start ">
          <div className="head-row">
            <FontAwesomeIcon icon={faChevronLeft} />
            <p className="text-head-title ">Add Clients</p>
          </div>
          <div className="head-row  ">
            <div className="head-row">
              <span className="text-title">dashboard</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="head-row  ">
              <span className="text-title">Clients</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="head-row">
              <span className="text-title">Add Clients</span>
            </div>
          </div>
        </div>
        <Button
          label="Save"
          styles="bg-primary-color py-2.5 px-12 text-white"
          disabled
        />
      </div>
      <div className="client-container ">
        <div className="client-container-border border-input-color ">
          <div>
            <span className="text">Basic Information</span>
          </div>
          <div className="client-tab-container">
            <div className="client-tab ">
              <Tabs
                tabsData={[
                  {
                    id: 0,
                    label: "label 1",
                    content: "Ut irure",
                  },
                  {
                    id: 1,
                    label: "label 1",
                    content: "Ut irure",
                  },
                ]}
              />
              <TextInput
                name="name"
                label="Name"
                placeholder="Enter invoice title"
                onChange={handleChange}
              />
              <TextInput
                name="email address"
                label="Email Address"
                type="Email"
                placeholder="Enter invoice title"
                onChange={handleChange}
              />
            </div>
            <div className="col-span-1">
              <UploadImage
                isAvatar={true}
                image=""
                onChange={handleImageChange}
              />
            </div>
          </div>
          <div className="client-input ">
            <TextInput
              name="business name"
              label="Business Name"
              placeholder="Enter invoice title"
              onChange={handleChange}
            />
            <TextInput
              name="website url"
              label="Website URL"
              placeholder="Enter URL"
              tag="https://"
              onChange={handleChange}
            />
          </div>
          <div className="client-input">
            <Selector
              label="Language"
              placeholder="Choose language"
              options={[]}
            />
            <Selector
              label="Currency"
              placeholder="Choose currency"
              options={[]}
            />
          </div>
        </div>
        <div className="client-container-border border-input-color">
          <div className="">
            <span className="text">Address</span>
          </div>
          <div className="client-tow-col">
            <Selector
              label="Country"
              placeholder="Choose Country"
              options={[]}
            />
            <Selector label="City" placeholder="Choose City" options={[]} />
          </div>
          <div className="client-tow-col">
            <TextInput
              name="postal code"
              label="Postal Code"
              placeholder="Enter postal code"
              onChange={handleChange}
            />
            <TextInput
              name="address"
              label="Address"
              placeholder="Enter address"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="client-container-col">
          <div>
            <span className="text">Additional Information</span>
          </div>
          <div className="client-tab-container">
            <TextInput
              name="telephone number"
              type="number"
              label="Telephone Number"
              placeholder="Enter your phone number"
              onChange={handleChange}
            />
            <TextInput
              name="mobile number"
              type="number"
              label="Mobile Number"
              placeholder="Enter your mobile number"
              onChange={handleChange}
            />
            <TextInput
              name="tax id"
              type="number"
              label="Tax Id"
              placeholder="Enter your Tax Id"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
