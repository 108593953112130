import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
// import { result } from "./domeData";

const api = new APIClient();

// FETCH ENUMS LIST ACTION
export const fetchEnumsAction = createAsyncThunk(
  "list/enums",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_LIST_ENUMS);
      const newData = {
        ...data,
        data: data?.data?.Enums,
      };
      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// FETCH COUNTRIES LIST ACTION
export const fetchCountriesAction = createAsyncThunk(
  "list/countries",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_LIST_COUNTRIES);
      const newData = {
        ...data,
        data: data?.data?.Countries,
      };
      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// example to use :: dispatch(fetchCitiesAction("?country_id=1"));
// FETCH CITIES LIST ACTION
export const fetchCitiesAction = createAsyncThunk(
  "list/cities",
  async (query: string, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_CITE_BY_Q}${query}`);
      const newData = {
        ...data,
        data: data?.data?.Cities,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// FETCH ROLE LIST ACTION
export const fetchRoleAction = createAsyncThunk(
  "list/role",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_LIST_ROLES);

      const newData = {
        ...data,
        data: data?.data?.Roles,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// FETCH ORDER LIST ACTION
export const fetchOrderListAction = createAsyncThunk(
  "list/order",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_LISTS_ORDERS);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);
// FETCH UNITS LIST ACTION
export const fetchUnitsListAction = createAsyncThunk(
  "list/units",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_LISTS_UNIT);
      const newData = {
        ...data,
        data: data?.data?.Units,
      };
      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// FETCH CURRENCIES LIST ACTION
export const fetchCurrencyListAction = createAsyncThunk(
  "list/currencies",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_LISTS_CURRENCIES}`);
      const newData = {
        ...data,
        data: data?.data?.Currencies,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);
