import { FC } from "react";
import { UpdateModal } from "./UpdateModal";
import { employeeModalType } from "../EmployeeHeaderType";
import { EmployeeDeleteModal } from "./EmployeeDeleteModal";

export const ModalsEmployees: FC<employeeModalType> = ({
  showModal,
  handleModalClose,
}) => {
  return (
    <>
      {showModal?.delete && (
        <EmployeeDeleteModal
          showModal={showModal}
          handleModalClose={handleModalClose}
        />
      )}
      {showModal?.is_suspended && (
        <UpdateModal
          dataControl={{
            name: "is_suspended",
            title: "corporate.settings.employees.show.suspend.confirm",
            subTitle: "corporate.settings.employees.show.suspend.description",
            btnTitle: "corporate.settings.employees.show.suspend.approved",
          }}
          showModal={showModal}
          handleModalClose={handleModalClose}
        />
      )}
      {showModal?.is_banned && (
        <UpdateModal
          dataControl={{
            name: "is_banned",
            title: "corporate.settings.employees.show.banned.confirm",
            subTitle: "corporate.settings.employees.show.banned.description",
            btnTitle: "corporate.settings.employees.show.banned.approved",
          }}
          showModal={showModal}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};
