import { useEffect, FC } from "react";
import { ActionButton, Navlink, Table, useNotify } from "../../../Components";
import {
  CellComponentItemsSetting,
  ItemSettingTableActionBtnData,
  itemNavLinkList,
} from "./ItemsTableContent";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useQueryModifier } from "../../../hooks/useQueryModifier";
import { FilterTab } from "./Components/FilterTab";
import { SortTab } from "./Components/SortTab";
import { NavLink } from "react-router-dom";
import { usePermission } from "../../../hooks/usePermission";
import { fetchAllItemsAction } from "../../../redux/items/itemsAction";
import { useLanguageName } from "../../../hooks/useLanguageName";

export const ItemsSetting: FC = () => {
  const dispatch = useAppDispatch();
  const Notify = useNotify();

  const { message, isLoading, data } = useAppSelector(
    (state) => state?.items?.items
  );
  const { query, resetQueryParams } = useQueryModifier();

  useEffect(() => {
    dispatch(fetchAllItemsAction(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    Object.keys(message)?.map((el: any) =>
      Notify?.Error({ message: message?.[el] })
    );
    Object.keys(message)?.length && resetQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const addItemBtn = useLanguageName("corporate.settings.items.index.add");

  return (
    <div>
      <Navlink path="corporate.settings.items" routes={itemNavLinkList}>
        {usePermission("corporate.settings.items.create") && (
          <NavLink
            to={"/settings/add-item"}
            className="header-item-btn-add-item"
          >
            {addItemBtn}
          </NavLink>
        )}
      </Navlink>
      <div className="table-container w-full">
        <Table
          cellComponent={CellComponentItemsSetting}
          filterHeader={{
            filter: <FilterTab />,
            sort: <SortTab />,
            control: (
              <ActionButton
                label={useLanguageName("corporate.crud.export")}
                styles="item-setting-export-btn"
                data={ItemSettingTableActionBtnData()}
              />
            ),
          }}
          data={data?.Items}
          rowLink="/settings/show-item/"
          isLoading={isLoading}
          is_pagination={true}
          pagination={{
            paginateOptions: {
              currentPage: data?.pagination?.current_page,
              totalCount: data?.pagination?.total,
              pageSize: data?.pagination?.per_page,
            },
          }}
        />
      </div>
    </div>
  );
};
