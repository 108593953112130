import { FC, useState, useEffect } from "react";
import {
  ActionButton,
  Checkbox,
  LoadingLayout,
  Navlink,
  TextInput,
} from "../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useParams } from "react-router-dom";
import { DeleteTypeModal } from "../Components/DeleteTypeModal";
import { LanguageNameExpensesTypes } from "../../../../hooks/useLanguageName";
import {
  ActionBtnRows,
  showExpensesTypesNavLinkList,
} from "../ExpensesTypesTableContent";
import { getExpenseTypeAction } from "../../../../redux/expense_types/expenseTypesAction";

export const ShowExpensesTypes: FC = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    expenses_types: {
      data: { ExpenseType },
      isLoading,
    },
  } = useAppSelector((state) => state?.expenses_types);

  const dispatch = useAppDispatch();

  const { id: typeId } = useParams();

  useEffect(() => {
    dispatch(getExpenseTypeAction(typeId));
  }, []);

  return (
    <div>
      <Navlink
        path="corporate.settings.expenses_types.show.title"
        routes={showExpensesTypesNavLinkList}
      >
        <ActionButton
          styles="expenses-type-show-action-btn"
          data={ActionBtnRows({
            paths: {
              edit: `/settings/expenses-types/${typeId}/update`,
              setShowDeleteModal,
            },
          })}
        />
      </Navlink>
      <form
        id="expenses_types-form"
        className="rounded-primary-radius p-7 space-y-7 bg-white"
        noValidate
      >
        <LoadingLayout isLoading={isLoading}>
          <div className="grid lg:grid-cols-2 gap-7">
            <TextInput
              label={LanguageNameExpensesTypes("data.name")}
              placeholder={LanguageNameExpensesTypes("data.name_placeholder")}
              onChange={() => {}}
              value={ExpenseType?.name}
              disabled
            />
            <TextInput
              label={LanguageNameExpensesTypes("data.count")}
              placeholder={LanguageNameExpensesTypes("data.count_placeholder")}
              onChange={() => {}}
              value={ExpenseType?.expenses_count}
              disabled
            />
            <div className="flex flex-col gap-1">
              <Checkbox
                id="type_is_active"
                afterLabel={LanguageNameExpensesTypes("data.is_active")}
                onChange={() => {}}
                checked={ExpenseType?.is_active}
                disabled
              />
              <p className="text-sm font-normal opacity-70">
                {LanguageNameExpensesTypes("data.is_active_description")}
              </p>
            </div>
          </div>
        </LoadingLayout>
      </form>
      <DeleteTypeModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
    </div>
  );
};
