import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
import { useNotify } from "../../Components";

const api = new APIClient();

interface TaxArguments {
  id?: string;
  name: string;
  currency_id: string;
  price_type: string;
  price: string;
  is_active?: string;
}

// GET/ALL Taxes ACTION
export const fetchAllTaxesAction = createAsyncThunk(
  "taxes/getAllTaxes",
  async (query: String, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_TAXES}${query}`);
      const newData = {
        ...data,
        data: data?.data?.TaxCollection,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// POST/CREATE TAX ACTION
export const createTaxAction = createAsyncThunk(
  "taxes/create",
  async (tax: TaxArguments, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.create(url.GIT_TAXES, tax);
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// GET/ TAX ACTION
export const getTaxAction = createAsyncThunk(
  "taxes/getTaxAction",
  async (query: string | undefined, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_TAXES}/${query}`);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// DELETE/REMOVE TAX ACTION
export const deleteTaxAction = createAsyncThunk(
  "taxes/delete",
  async (query: string | undefined, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.delete(`${url.GIT_TAXES}/${query}`);
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// PUT/UPDATE TAX ACTION
export const updateTaxAction = createAsyncThunk(
  "taxes/update",
  async (tax: TaxArguments, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.create(`${url.GIT_TAXES}/${tax?.id}`, {
        ...tax,
        _method: "PUT",
      });
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);
