import {useEffect, useState} from 'react';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './style.css';

interface CheckboxTypes {
  id: string | undefined;
  name?: string;
  beforeLabel?: string;
  afterLabel?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: any;
  styles?: string;
  error?: boolean | string;
  errorMsg?: string;
}
export const Checkbox = ({
  id,
  name,
  beforeLabel,
  afterLabel,
  disabled,
  defaultChecked,
  checked = false,
  onChange,
  styles,
  error,
  errorMsg,
  ...rest
}: CheckboxTypes) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  // useEffect(() => {
  //   setIsChecked(defaultChecked);
  // }, [defaultChecked]);

  return (
    <div className="flex items-center">
      <label className="cursor-pointer flex items-center" htmlFor={id}>
        {beforeLabel && (
          <span className="checkbox-label checkbox-label-before">
            {beforeLabel}
          </span>
        )}
        <div className="relative group">
          <input
            id={id}
            name={name}
            className={`checkbox rounded w-4 h-4 bg-checkbxo-bg-color ${styles}`}
            type="checkbox"
            checked={isChecked}
            onChange={(event) => {
              setIsChecked((prevIsChecked) => !prevIsChecked);
              onChange(event, isChecked);
            }}
            disabled={disabled}
            {...rest}
            value={!isChecked ? 1 : 0}
          />
          <FontAwesomeIcon
            icon={faCheck}
            className={`checkbox-icon ${
              isChecked ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
        <span />
        {afterLabel && (
          <span className="checkbox-label checkbox-label-after">
            {afterLabel}
          </span>
        )}
      </label>
    </div>
  );
};
