import { forwardRef, Ref } from "react";
import { Link } from "react-router-dom";
import logo from "./../../assets/logo.svg";
import full_logo from "./../../assets/full-logo.svg";

interface LogoTypes {
  open: boolean | null;
}

const Logo = forwardRef(
  ({ open }: LogoTypes, ref: Ref<HTMLAnchorElement> | undefined) => (
    <Link
      ref={ref}
      to={"/"}
      className={`logo-container pt-[40px] ${open ? "px-[53px]" : "px-[44px]"}`}
    >
      {open ? (
        <img
          className="full-logo-img logo-img"
          src={full_logo}
          alt="Full logo"
        />
      ) : (
        <img
          className="collapsed-logo-img logo-img"
          src={logo}
          alt="Collapsed logo"
        />
      )}
    </Link>
  )
);

export default Logo;
