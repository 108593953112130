import { useEffect } from "react";
import { useAppDispatch } from "../redux/store";
import {
  fetchLanguagesAction,
  // fetchPermissionAction,
} from "../redux/general/generalAction";
import { fetchEnumsAction } from "../redux/list/listAction";
import { setLanguage } from "../helpers/api_helper";

// type useInitialDataFetchProps = {
//   language: string;
// };

const direction = JSON.parse(
  localStorage.getItem("language-direction") ||
    `{"id":1,"name":"English","locale_key":"en","is_rtl":0}`
);

export const useInitialDataFetch = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLanguagesAction(direction?.locale_key));
    dispatch(fetchEnumsAction());
    setLanguage(direction?.locale_key);
    // dispatch(fetchPermissionAction());
  }, [dispatch]);
};
