import { FC, useState, useEffect, useCallback, useMemo } from "react";
import { OptionFilterType, filterOptionType } from "../itemHeaderType";
import { useAppSelector } from "../../../../../redux/store";
import { useQueryModifier } from "../../../../../hooks/useQueryModifier";
import { Button, Selector } from "../../../../../Components";
import { useAddItemStateData } from "../../useAddItemStateData";
import {
  LanguageNameItemData,
  useLanguageName,
} from "../../../../../hooks/useLanguageName";

export const FilterTab: FC = () => {
  const { units, customEnum, currencies } = useAddItemStateData();
  const { query, addQueryParams, queryObject } = useQueryModifier();
  const { items } = useAppSelector((state) => state.items);

  const initialFilterOption = useMemo(() => {
    return {
      is_active: { id: "", name: "" },
      unit_id: { id: "", name: "" },
      currency_id: { id: "", name: "" },
      type: { id: "", name: "" },
    };
  }, []);

  const [filterOption, setFilterOption] =
    useState<filterOptionType>(initialFilterOption);

  const activeOption = [
    {
      id: "",
      name: "All",
    },
    {
      id: 1,
      name: "Active",
    },
    {
      id: 0,
      name: "Inactive",
    },
  ];
  const unitOption = [
    {
      id: "",
      name: "All",
    },
    ...units?.data,
  ];
  const currencyOption = [
    {
      id: "",
      name: "All",
    },
    ...currencies?.data,
  ];
  const typeOption = [
    {
      id: "",
      name: "All",
    },
    ...customEnum,
  ];

  // filter changes
  const handleChange = useCallback((name: string, option: OptionFilterType) => {
    setFilterOption((prev) => ({ ...prev, [name]: option }));
  }, []);

  // rest of filter options
  const resetFilter = useCallback(() => {
    setFilterOption(initialFilterOption);
    addQueryParams({ is_active: "", unit_id: "", currency_id: "", type: "" });
  }, [addQueryParams, initialFilterOption]);

  const handleApply = useCallback(() => {
    const customFilterOption = {
      is_active: filterOption?.is_active?.id.toString() || "",
      unit_id: filterOption?.unit_id?.id.toString() || "",
      currency_id: filterOption?.currency_id?.id.toString() || "",
      type: filterOption?.type?.id.toString() || "",
      page: 1,
    };
    addQueryParams(customFilterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addQueryParams,
    filterOption?.is_active?.id,
    filterOption?.unit_id?.id,
    filterOption?.type,
  ]);

  useEffect(() => {
    setFilterOption({
      unit_id: unitOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.unit_id
      )[0],
      currency_id: currencyOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.currency_id
      )[0],
      is_active: activeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.is_active
      )[0],
      type: typeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.type
      )[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, units?.data, currencies?.data, customEnum]);
  return (
    <>
      <Selector
        name="is_active"
        label={useLanguageName("corporate.crud.available")}
        placeholder={useLanguageName("corporate.crud.available_placeholder")}
        searchPlaceholder="Search availability"
        options={activeOption}
        defaultOptions={filterOption?.is_active}
        onChange={handleChange}
      />
      <Selector
        name="unit_id"
        label={LanguageNameItemData("unit_id")}
        placeholder={LanguageNameItemData("unit_placeholder")}
        options={unitOption}
        defaultOptions={filterOption?.unit_id}
        searchPlaceholder="Search Unit"
        onChange={handleChange}
      />
      <Selector
        name="type"
        label={LanguageNameItemData("type")}
        placeholder={LanguageNameItemData("type_placeholder")}
        searchPlaceholder="Search Type"
        options={typeOption}
        defaultOptions={filterOption?.type}
        onChange={handleChange}
      />
      <Selector
        name="currency_id"
        label={LanguageNameItemData("currency_id")}
        placeholder={LanguageNameItemData("currency_placeholder")}
        searchPlaceholder="Search currency"
        options={currencyOption}
        defaultOptions={filterOption?.currency_id}
        onChange={handleChange}
      />
      <div className="filter-drop-footer">
        <Button
          label={useLanguageName("corporate.crud.reset")}
          icon={null}
          styles="text-third-color !w-20 h-7"
          labelStyles="text-xs font-semibold "
          onClick={resetFilter}
        />
        <Button
          label={useLanguageName("corporate.crud.apply")}
          icon={null}
          styles="text-white bg-third-color !w-20 !h-7"
          labelStyles="text-xs font-semibold"
          isLoading={items?.isLoading}
          onClick={handleApply}
        />
      </div>
    </>
  );
};
