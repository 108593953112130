export type invoiceAddedItemsTypes = {
  key: string;
  input: string;
  quantity: string;
  rate: string;
  amount: string;
  isDisabled: boolean;
};

export type RecurringForm = {
  rInvoiceTitle: string;
  rInvoiceDescription: string;
  rInvoiceImage: string;
  rInvoiceNumber: string;
  rInvoiceLanguage: string;
  rInvoiceCurrency: string;
  from: string;
  to: string;
  items: invoiceAddedItemsTypes | {};
  rNote: string;
  tax: { taxName: string; taxValue: string };
  discount: { discountName: string; discountValue: string };
  shipping: { shippingName: string; shippingValue: string };
  paidAmount: string;
};

export type AddInvoiceRowProps = {
  removeItem: (rowKey: string) => void;
  invoiceAddedItems: invoiceAddedItemsTypes[];
  setInvoiceAddedItems: React.Dispatch<
    React.SetStateAction<invoiceAddedItemsTypes[]>
  >;
  rowNum: number;
  data: {
    key: string;
    input: string;
    quantity: string;
    rate: string;
    amount: string;
    isDisabled: boolean;
  };
};

export const defaultPageData = {
  rInvoiceTitle: "",
  rInvoiceDescription: "",
  rInvoiceImage: "",
  rInvoiceNumber: "",
  rInvoiceLanguage: "",
  rInvoiceCurrency: "",
  from: "",
  to: "",
  items: {},
  rNote: "",
  tax: { taxName: "", taxValue: "" },
  discount: { discountName: "", discountValue: "" },
  shipping: { shippingName: "", shippingValue: "" },
  paidAmount: "",
};

export const defaultItem = {
  input: "",
  quantity: "",
  rate: "",
  amount: "",
  isDisabled: false,
};
