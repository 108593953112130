import { FC, useState, useEffect, useCallback, useMemo } from "react";

import { OptionFilterType, filterOptionType } from "../TaxHeaderType";
import { useQueryModifier } from "../../../../../hooks/useQueryModifier";
import { Button, Selector } from "../../../../../Components";
import { useStateData } from "../../useStateData";
import {
  LanguageNameTax,
  useLanguageName,
} from "../../../../../hooks/useLanguageName";

export const FilterTab: FC = () => {
  const { languages, taxes, enums } = useStateData();
  const { query, addQueryParams, queryObject } = useQueryModifier();

  const initialFilterOption = useMemo(() => {
    return {
      is_active: { id: "", name: "" },
      price_type: { id: "", name: "" },
    };
  }, []);

  const [filterOption, setFilterOption] =
    useState<filterOptionType>(initialFilterOption);
  const activeOption = [
    {
      id: "",
      name: "All",
    },
    {
      id: 1,
      name: "Active",
    },
    {
      id: 0,
      name: "Inactive",
    },
  ];

  const [priceOptions, setPriceOptions] = useState({
    data: [],
  });

  useEffect(() => {
    // set enums options
    if (enums?.data?.PriceType) {
      const types = enums?.data?.PriceType;
      setPriceOptions({
        data: Object.keys(types).reduce((acc: any, type) => {
          acc[types[type]] = type;
          return acc;
        }, {}),
      });
    }
  }, [enums?.data?.PriceType]);

  const priceTypeOption = [
    {
      id: "",
      name: "All",
    },
    ...Object.keys(enums?.data?.PriceType).map((key) => ({
      id: enums?.data?.PriceType[key],
      name: key,
    })),
    //  spread the price type enums
  ];

  // filter tax and active changes
  const handleChange = useCallback((name: string, option: OptionFilterType) => {
    setFilterOption((prev) => ({ ...prev, [name]: option }));
  }, []);

  // rest of filter options
  const resetFilter = useCallback(() => {
    setFilterOption(initialFilterOption);
    addQueryParams({ is_active: "", price_type: "" });
  }, [addQueryParams, initialFilterOption]);

  const handleApply = useCallback(() => {
    const customFilterOption = {
      is_active: filterOption?.is_active?.id.toString() || "",
      price_type: filterOption?.price_type?.id.toString() || "",
      page: 1,
    };
    addQueryParams(customFilterOption);
  }, [
    addQueryParams,
    filterOption?.is_active?.id,
    filterOption?.price_type?.id,
  ]);

  useEffect(() => {
    setFilterOption({
      is_active: activeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.is_active
      )[0],
      price_type: priceTypeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.price_type
      )[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, taxes?.data]);

  return (
    <>
      <Selector
        label={LanguageNameTax("data.price_type")}
        name="price_type"
        defaultOptions={filterOption?.price_type}
        options={priceTypeOption}
        placeholder={LanguageNameTax("data.price_type_description")}
        searchPlaceholder="Search availability"
        onChange={handleChange}
      />
      <Selector
        label={LanguageNameTax("data.is_active")}
        name="is_active"
        defaultOptions={filterOption?.is_active}
        options={activeOption}
        placeholder={LanguageNameTax("data.is_active_placeholder")}
        searchPlaceholder="Search availability"
        onChange={handleChange}
      />
      <div className="filter-drop-footer">
        <Button
          label={useLanguageName("corporate.crud.reset")}
          icon={null}
          styles="text-third-color !w-20 h-7"
          labelStyles="text-xs font-semibold "
          onClick={resetFilter}
        />
        <Button
          label={useLanguageName("corporate.crud.apply")}
          icon={null}
          styles="text-white bg-third-color !w-20 !h-7"
          labelStyles="text-xs font-semibold"
          isLoading={taxes?.isLoading}
          onClick={handleApply}
        />
      </div>
    </>
  );
};
