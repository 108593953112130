import { FC, ReactNode } from "react";
import Logo from "../../assets/Group.png";
import "./style.css";

interface AuthLayoutTypes {
  children: ReactNode;
}

export const AuthLayout: FC<AuthLayoutTypes> = ({ children }) => {
  return (
    <div className="auth-layout-container">
      <img className="auth-layout-img" src={Logo} alt="auth layout" />
      <div className="auth-layout-box-content">{children}</div>
    </div>
  );
};
