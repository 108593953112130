import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { AddInvoiceRowProps } from "../../addRecurringInvoiceData";
import { SelectInput, TextInput } from "../../../../../Components";
import "../style.css";

const options = [
  { itemName: "Samsung Mobile", itemPrice: "150" },
  { itemName: "Social Media Marketing", itemPrice: "80" },
  { itemName: "Computer Desk", itemPrice: "500" },
  { itemName: "SEO Services", itemPrice: "100" },
  { itemName: "Laptop", itemPrice: "350" },
  { itemName: "Network Device", itemPrice: "120" },
];

export const AddInvoiceRow = ({
  data,
  removeItem,
  setInvoiceAddedItems,
  rowNum,
  invoiceAddedItems,
}: AddInvoiceRowProps) => {
  const [addItem, setAddItem] = useState(data);

  const onChange = (event: { target: { name: any; value: any } }) => {
    if (event.target.name === "quantity") {
      setAddItem((prev) => ({
        ...prev,
        quantity: event.target.value,
      }));
    } else if (event.target.name === "rate") {
      setAddItem((prev) => ({
        ...prev,
        rate: event.target.value,
      }));
    } else if (event.target.name === "amount") {
      setAddItem((prev) => ({
        ...prev,
        amount: event.target.value,
      }));
    }
    const updatedItems = [...invoiceAddedItems];
    updatedItems[rowNum] = {
      ...updatedItems[rowNum],
      amount: event.target.value,
    };
    setInvoiceAddedItems(updatedItems);
  };

  const setDisabled = (value: boolean) => {
    setAddItem((prev) => ({
      ...prev,
      isDisabled: value,
    }));
  };

  const handleSelectInput = (something: any) => {
    if (something.target) {
      setAddItem((prev) => ({
        ...prev,
        input: something.target.value,
      }));
      const updatedItems = [...invoiceAddedItems];
      updatedItems[rowNum] = {
        ...updatedItems[rowNum],
        input: something.target.value,
      };
      setInvoiceAddedItems(updatedItems);
      for (const item of options) {
        if (
          item.itemName.toLowerCase() === something.target.value.toLowerCase()
        ) {
          setAddItem((prev) => ({
            ...prev,
            amount: item.itemPrice,
          }));
          setDisabled(true);
          break;
        } else {
          setDisabled(false);
        }
      }
    } else {
      setAddItem((prev) => ({
        ...prev,
        input: something.itemName,
        amount: something.itemPrice,
      }));
      const updatedItems = [...invoiceAddedItems];
      updatedItems[rowNum] = {
        ...updatedItems[rowNum],
        input: something.itemName,
        amount: something.itemPrice,
      };
      setInvoiceAddedItems(updatedItems);
    }
  };

  return (
    <div className="addItemTopContainer">
      <div className="inputsContainer">
        <div className="min-w-[200px] w-full">
          <SelectInput
            name="description"
            label="Description"
            placeholder="Item name and discription"
            onChange={handleSelectInput}
            setAddItem={setAddItem}
            value={addItem.input}
            setDisabled={setDisabled}
            options={options}
          />
        </div>
        <div className="flex lg:gap-x-4 gap-x-2 ">
          <div className="min-w-[65px] w-full">
            <TextInput
              name="quantity"
              label="Quantity"
              placeholder="Qty"
              onChange={onChange}
            />
          </div>

          <div className="min-w-[150px] w-full">
            <TextInput
              name="rate"
              label="Rate"
              placeholder="00.00"
              dropdown
              dropdownLabel="Unit"
              // dropdownOptions={["1", "2", "3"]}
              onChange={onChange}
            />
          </div>

          <div className="min-w-[60px] w-full">
            <TextInput
              name="amount"
              label="Amount"
              placeholder="00.00"
              onChange={addItem.isDisabled ? () => {} : onChange}
              value={addItem.amount}
            />
          </div>
        </div>
      </div>
      <div
        className="IconContainer mt-6"
        onClick={() => removeItem(addItem.key)}
      >
        <FontAwesomeIcon icon={faX} className="icon" />
      </div>
    </div>
  );
};
