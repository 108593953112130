import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createEmployeeAction,
  deleteEmployeesAction,
  fetchAllEmployeesAction,
  fetchEmployeeAction,
  updateEmployeeAction,
} from "./employeeAction"; // You should import the correct type for UserData

interface InitialStateType {
  // data: Record<string, Array<{ key: string; value: any }> | string |undefined>;
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Record<string, string>;
}

interface GeneralState {
  employees: InitialStateType;
  employee: InitialStateType;
  employeeCreated: InitialStateType;
  employeeDeleted: InitialStateType;
}

const initialStateStructure = {
  data: { Employee: [] },
  isLoading: true,
  success: true,
  message: {},
};

const initialState: GeneralState = {
  employees: initialStateStructure,
  employee: initialStateStructure,
  employeeCreated: initialStateStructure,
  employeeDeleted: { ...initialStateStructure, success: false },
};

const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET/ALL EMPLOYEE ACTION
      .addCase(fetchAllEmployeesAction.pending, (state) => {
        state.employees.isLoading = true;
      })
      .addCase(
        fetchAllEmployeesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.employees = action.payload;
          state.employees.isLoading = false;
        }
      )
      .addCase(
        fetchAllEmployeesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.employees.isLoading = false;
          state.employees.success = action.payload?.success;
          state.employees.message = action.payload?.message;
        }
      )
      // GET/A EMPLOYEE ACTION
      .addCase(fetchEmployeeAction.pending, (state) => {
        state.employee.isLoading = true;
      })
      .addCase(
        fetchEmployeeAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.employee.isLoading = false;
          state.employee = action.payload;
        }
      )
      .addCase(
        fetchEmployeeAction.rejected,
        (state, action: PayloadAction<any>) => {
          // state.employee.isLoading = false;
          state.employee.success = action.payload?.success;
          state.employee.message = action.payload?.message;
        }
      )
      // POST/CREATE EMPLOYEE ACTION
      .addCase(createEmployeeAction.pending, (state) => {
        state.employeeCreated.isLoading = true;
      })
      .addCase(
        createEmployeeAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.employeeCreated.isLoading = false;
          state.employeeCreated = action.payload;
        }
      )
      .addCase(
        createEmployeeAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.employeeCreated.isLoading = false;
          state.employeeCreated = action.payload;
          // state.employeeCreated.message = action.payload?.message;
        }
      )
      // PUT/UPDATE EMPLOYEE ACTION
      .addCase(updateEmployeeAction.pending, (state) => {
        state.employee.isLoading = true;
      })
      .addCase(
        updateEmployeeAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.employee.isLoading = false;
          state.employee = action.payload;
        }
      )
      .addCase(
        updateEmployeeAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.employee.isLoading = false;
          state.employee.success = action.payload?.success;
          state.employee.message = action.payload?.message;
        }
      )
      // DELETE EMPLOYEE ACTION
      .addCase(deleteEmployeesAction.pending, (state) => {
        state.employeeDeleted.success = false;
        state.employeeDeleted.isLoading = true;
      })
      .addCase(
        deleteEmployeesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.employeeDeleted.isLoading = false;
          state.employeeDeleted = action.payload;
        }
      )
      .addCase(
        deleteEmployeesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.employeeDeleted.isLoading = false;
          state.employeeDeleted.success = action.payload?.success;
          state.employeeDeleted.message = action.payload?.message;
        }
      );
  },
});

export default employeesSlice.reducer;
