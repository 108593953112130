import { ChangeEvent, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchPermissionsAction } from "../../../redux/role/roleAction";
import { resetRole } from "../../../redux/role/rolesSlice";
import { useNavigate } from "react-router-dom";

type Response = {
  isLoading: boolean;
  success: boolean;
  message: { [k: string]: any };
  data: { [k: string]: any };
};

type role = {
  name: string;
  is_active: number;
};

export function useStateData() {
  const { roles, orders } = useAppSelector((state) => state?.list);

  const { languages, permission } = useAppSelector((state) => state?.general);
  const dispatch = useAppDispatch();

  const apiRole: Response = useAppSelector((state) => state?.roles?.role);

  const [role, setRole] = useState<role>({
    name: "",
    is_active: 0,
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setRole((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    const { name } = event.target;
    setRole((prev) => ({
      ...prev,
      [name]: !isChecked ? 1 : 0,
    }));
  };

  const [errors, setErrors] = useState({
    name: "",
    permissions: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (apiRole?.success) {
      dispatch(resetRole());
      navigate("/settings/roles");
    }
  }, [apiRole?.success]);

  return {
    permission,
    apiRole,
    roles,
    languages,
    orders,
    role,
    setRole,
    handleInputChange,
    handleCheckboxChange,
    errors,
    setErrors,
  };
}
