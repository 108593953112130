import { FC, useCallback } from "react";
import { Row } from "./Row";
import { TableTitlesHeader } from "./TableTitlesHeader";
import { SelectPagination } from "./SelectPagination";
import { Pagination } from "./Pagination";
import { TableProps } from "./TableTypes";
import { useQueryModifier } from "../../hooks/useQueryModifier";
import { TableHeader } from "./TableHeader";
import { LoadingLayout } from "../Common";
import "./style.css";

export const Table: FC<TableProps> = ({
  data,
  rowLink,
  cellComponent,
  titles,
  filterHeader,
  tableCss,
  is_pagination = false,
  // filterComponent,
  pagination,
  isLoading = false,
}) => {
  const { addQueryParams, queryObject } = useQueryModifier();
  // const { select, paginateOptions } = pagination;
  const defaultOptions = pagination?.select?.options || [8, 10, 20, 30, 50, 100];

  const handleSelectedChange = useCallback(
    (data: number) => {
      addQueryParams({ page: 1, per_page: data });
    },
    [addQueryParams]
  );
  const handlePageChange = useCallback(
    (data: number) => {
      addQueryParams({ page: data });
    },
    [addQueryParams]
  );

  return (
    <>
      <div className="table-container-header ">
        {filterHeader && (
          <TableHeader
            filter={filterHeader?.filter}
            sort={filterHeader?.sort}
            control={filterHeader?.control}
            filterListCustomCss={filterHeader?.filterListCustomCss}
            sortListCustomCss={filterHeader?.sortListCustomCss}
          />
        )}

        <LoadingLayout
          isLoading={isLoading}
          boxStyle="w-full min-h-[40vh] flex justify-center py-8"
        >
          <div className={`table-container-table ${tableCss}`}>
            <table className="w-full">
              {titles && (
                <thead>
                  <TableTitlesHeader titles={titles} />
                </thead>
              )}
              <tbody className="w-full">
                {data?.map(
                  (row: Record<string, string | number | null>, i: number) => {
                    return (
                      <Row
                        id={row?.id}
                        rowLink={rowLink}
                        key={i}
                        lastRow={data?.length - 1 === i}
                      >
                        {cellComponent.map((item, i) => {
                          return (
                            item.Component && (
                              <td
                                key={i}
                                className={
                                  item?.compProps?.customWidth ||
                                  `table-cell-container`
                                }
                              >
                                <item.Component
                                  key={i}
                                  {...item?.compProps}
                                  data={row}
                                />
                              </td>
                            )
                          );
                        })}
                      </Row>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </LoadingLayout>
      </div>
      {!isLoading && is_pagination && (
        <div className="table-footer ">
          <div>
            <Pagination
              onPageChange={handlePageChange}
              totalCount={pagination?.paginateOptions?.totalCount || 1}
              currentPage={pagination?.paginateOptions?.currentPage || 1}
              pageSize={Number(queryObject?.per_page) || defaultOptions[0]}
            />
          </div>
          <SelectPagination
            // records={selectPagination.records}
            options={defaultOptions}
            OptionSelected={Number(queryObject?.per_page) || defaultOptions[0]}
            handleChange={handleSelectedChange}
            totalRows={pagination?.paginateOptions?.totalCount}
            totalVisible={data?.length > 0 && data.length}
          />
        </div>
      )}
    </>
  );
};
