import {
  FC,
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useCallback,
  FormEvent,
} from "react";
import {
  Button,
  Checkbox,
  Navlink,
  Selector,
  TextInput,
} from "../../../../Components";
import { object } from "../../../../utils/ValidateErrors";
import { createTaxAction } from "../../../../redux/tax/taxAction";
import { useAppDispatch } from "../../../../redux/store";
import { useStateData } from "../useStateData";
import {
  fetchCurrencyListAction,
  fetchEnumsAction,
} from "../../../../redux/list/listAction";
import { useNavigate } from "react-router-dom";
import { resetTax } from "../../../../redux/tax/taxesSlice";
import { addTaxNavLinkList } from "../TaxesTableContent";
import { LanguageNameTax } from "../../../../hooks/useLanguageName";

type TaxData = {
  name: string;
  currency_id: string;
  price_type: string;
  price: string;
  is_active?: string;
};

type Option = {
  id: number | string;
  name: string;
};
type PriceOption = {
  data: Array<Option>;
};
export const AddTax: FC = () => {
  const { languages, apiTax, currencies, enums } = useStateData();
  const [priceOptions, setPriceOptions] = useState<any>({
    data: [],
  });
  useEffect(() => {
    // set enums options
    if (enums?.data?.PriceType) {
      const types = enums?.data?.PriceType;
      setPriceOptions((prev: PriceOption) => {
        return {
          ...prev,
          data: [
            ...Object.keys(types).map((type) => ({
              id: types[type],
              name: type,
            })),
          ],
        };
      });
    }
  }, [enums?.data?.PriceType]);

  const [taxData, setTaxData] = useState<TaxData>({
    name: "",
    currency_id: "",
    price_type: "",
    price: "",
    is_active: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    currency_id: "",
    price_type: "",
    price: "",
    is_active: "",
  });

  const flag = useRef(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setTaxData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (name: string, value: Option) => {
    setTaxData((prev) => ({
      ...prev,
      [name]: value.id,
    }));
  };

  const validate = useCallback(() => {
    const taxSchema = object({
      name: ["required"],
      currency_id: ["required"],
      price_type: ["required"],
      price: ["required"],
    });
    const result = taxSchema.validate(taxData);
    setErrors(result.errors);
    return result.valid;
  }, [taxData]);

  const dispatch = useAppDispatch();
  const saveTax = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(createTaxAction(taxData));
      }
    },
    [validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  useEffect(() => {
    dispatch(fetchCurrencyListAction());
    dispatch(fetchEnumsAction());
  }, [dispatch]);

  const navigate = useNavigate();
  useEffect(() => {
    if (apiTax.success) {
      dispatch(resetTax());
      navigate("/settings/taxes");
    }
  }, [apiTax.success]);

  return (
    <div>
      <Navlink
        path="corporate.settings.taxes.create"
        routes={addTaxNavLinkList}
        children={
          <Button
            form="tax-form"
            type="submit"
            styles="text-white bg-main-color w-[117px] h-[41px]"
            label={LanguageNameTax("create.save")}
            icon={null}
            isLoading={apiTax?.isLoading}
            disabled={apiTax?.isLoading}
          />
        }
      />
      <form
        id="tax-form"
        onSubmit={saveTax}
        className="rounded-primary-radius p-7 space-y-7 bg-white"
        noValidate
      >
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            id="tax_name"
            name="name"
            label={LanguageNameTax("data.name")}
            placeholder={LanguageNameTax("data.name_placeholder")}
            onChange={handleChange}
            error={errors?.name}
            errorMsg={errors?.name}
            value={taxData?.name}
          />
          <div className="relative">
            <Selector
              id="tax_city"
              name="currency_id"
              label={LanguageNameTax("data.currency_id")}
              options={currencies?.data}
              placeholder={LanguageNameTax("data.currency_id_placeholder")}
              searchPlaceholder={LanguageNameTax(
                "data.currency_id_search_placeholder"
              )}
              onChange={handleSelectChange}
              error={errors?.currency_id}
              errorMsg={errors?.currency_id}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-4 gap-7">
          <div className="relative">
            <Selector
              id="tax_price_type"
              name="price_type"
              label={LanguageNameTax("data.type")}
              options={priceOptions?.data}
              placeholder={LanguageNameTax("type_placeholder")}
              searchPlaceholder={LanguageNameTax("type_search_placeholder")}
              onChange={handleSelectChange}
              error={errors?.price_type}
              errorMsg={errors?.price_type}
            />
          </div>
          <TextInput
            id="tax_value"
            name="price"
            label={LanguageNameTax("data.price")}
            placeholder={LanguageNameTax("price_placeholder")}
            onChange={handleChange}
            error={errors?.price}
            errorMsg={errors?.price}
            value={taxData?.price}
          />
          <div className="flex items-center gap-1 col-span-2">
            <Checkbox
              id="tax_is_active"
              name="is_active"
              afterLabel={LanguageNameTax("data.is_active")}
              styles="checked:bg-main-color"
              onChange={handleChange}
              error={errors?.is_active}
              errorMsg={errors?.is_active}
            />
            <p className="text-sm font-normal opacity-70">
              {LanguageNameTax("create.is_active_details")}
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};
