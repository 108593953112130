import { useEffect } from "react";

export const useEscapeKeyPressed = (callback: Function, active = true) => {
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Esc") {
        callback();
      }
    };

    if (active) {
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [callback, active]);
};
