import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  fetchCurrencyListAction,
  fetchUnitsListAction,
} from "../../../redux/list/listAction";
import { OptionFilterType } from "./Components/itemHeaderType";

export const useAddItemStateData = () => {
  const dispatch = useAppDispatch();
  const { units, enums, currencies } = useAppSelector((state) => state.list);
  const [customEnum, setCustomEnum] = useState<Array<OptionFilterType>>([]);

  useEffect(() => {
    units?.data?.length === 0 && dispatch(fetchUnitsListAction());
    currencies?.data?.length === 0 && dispatch(fetchCurrencyListAction());
  }, [dispatch, currencies?.data?.length, units?.data?.length]);

  useEffect(() => {
    if (enums?.data?.ItemType) {
      const types = enums?.data?.ItemType;
      setCustomEnum(() => {
        return Object.keys(types).map((type) => ({
          id: types[type],
          name: type,
        }));
      });
    }
  }, [enums]);

  return {
    units,
    enums,
    customEnum,
    currencies,
  };
};
