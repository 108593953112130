import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { Submenu } from "./submenu";
import { useState, useEffect } from "react";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
export type ItemDataType = {
  id: number;
  path: string;
  label: string;
  icon: {
    prefix: string;
    iconName: string;
  };
  visible: boolean;
  spacing: boolean;
  submenu?: boolean;
  children?: Array<MenuChild>;
};
type MenuChild = {
  path: string;
  label: string;
};
interface ItemTypes {
  data: ItemDataType;
  open: boolean | null;
  lineRef: any;
}
export const Item = ({ data, open, lineRef }: ItemTypes) => {
  const { pathname } = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const openSubmenu = (event: any, path: string) => {
    if (data.path === "/settings") {
      setSubMenuOpen(!subMenuOpen);
    }
  };

  useEffect(() => {
    if (data) {
      const listItems = document.querySelectorAll(".sidebar-item");

      const handleItemClick = (event: any) => {
        const line = lineRef.current;
        const listItem = event.target;

        if (line) {
          line.style.display = "block";
          const lineTop =
            listItem.offsetTop +
            listItem.offsetHeight / 2 -
            line.offsetHeight / 2;
          line.style.top = lineTop + "px";
        }
      };

      const matchingItem = [...listItems].find(
        (item) => item.getAttribute("data-path") === pathname
      ) as HTMLElement;

      if (matchingItem) {
        matchingItem.click();
      }

      return () => {
        listItems.forEach((item) => {
          item.removeEventListener("click", handleItemClick);

          // Remove the line when the component unmounts
          const line = item.querySelector(".line");
          if (line) {
            item.removeChild(line);
          }
        });
      };
    }
  }, [data, lineRef, pathname]);

  return (
    data.visible && (
      <Link
        className="group/item"
        to={data.path}
        onClick={(event) => openSubmenu(event, data.path)}
      >
        <li
          data-path={data.path}
          className={`sidebar-item ${
            data.spacing ? "mt-9" : open ? "mt-[47px]" : "mt-[49px]"
          } ${
            pathname === data.path &&
            "text-primary-color opacity-100 active"
          }
          `}
        >
          <FontAwesomeIcon
            className={`pointer-events-none	sidebar-item-icon
            ${pathname === data.path && "text-main-color"} ${
              subMenuOpen && "text-main-color"
            }`}
            icon={data.icon as IconDefinition}
          />
          <span
            className={`sidebar-item-label ${!open && "hidden"}
            ${subMenuOpen && "text-main-color"}
            `}
          >
            {data.label}
          </span>
          {data.submenu && open && (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`submenu-icon ${subMenuOpen && "rotate-180"} ${
                subMenuOpen && "text-main-color"
              }`}
            />
          )}
        </li>
        {data.submenu && subMenuOpen && open && (
          <Submenu children={data.children} />
        )}
        <div ref={lineRef} className="sidebar-line" />
      </Link>
    )
  );
};
