import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
import { useNotify } from "../../Components";

interface DiscountArguments {
  [key: string]: any;
}

const api = new APIClient();

// GET/ALL DISCOUNTS ACTION
export const fetchAllDiscountsAction = createAsyncThunk(
  "discounts/getAllDiscounts",
  async (query: String, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_ALL_DISCOUNT}${query}`);
      const newData = {
        ...data,
        data: data?.data?.DiscountCollection,
      };

      return newData;

      // return discounts;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        return rejectWithValue(errorData);
      }
    }
  }
);

// HELPER FUNCTION
const filterDiscountDataValues = (data: any) => {
  let customData = { ...data };
  delete customData?.data?.Discount?.is_banned;
  delete customData?.data?.Discount?.is_suspended;

  const filteredObj: any = {};

  for (const key in customData?.data?.Discount) {
    if (customData?.data?.Discount[key] !== null) {
      filteredObj[key] = customData?.data?.Discount[key];
    }
  }

  customData = {
    ...customData,
    data: {
      ...customData?.data,
      Discount: {
        ...filteredObj,
        is_active: data?.data?.Discount?.is_active ? 1 : 0,
      },
    },
  };
  return customData;
};

// GET/A DISCOUNT ACTION
export const fetchDiscountAction = createAsyncThunk(
  "discounts/getDiscount",
  async (query: string | undefined, { rejectWithValue }) => {
    const Notify = useNotify();

    try {
      const { data } = await api.get(`${url.GET_A_DISCOUNT}/${query}`);
      return filterDiscountDataValues(data);
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// POST/CREATE DISCOUNT ACTION
export const createDiscountAction = createAsyncThunk(
  "discounts/createDiscount",
  async (discount: DiscountArguments, { rejectWithValue }) => {
    const Notify = useNotify();

    try {
      const form = new FormData();
      for (let key in discount) {
        form.append(key, discount[key]);
      }
      const { data } = await api.create(url.POST_DISCOUNT, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Notify.Success({ message: data?.message?.success });

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// PUT/UPDATE DISCOUNT ACTION
export const updateDiscountAction = createAsyncThunk(
  "discounts/updateDiscount",
  async (discountData: DiscountArguments, { rejectWithValue }) => {
    const Notify = useNotify();

    const { discount, id } = discountData;
    try {
      const form = new FormData();
      for (let key in discount) {
        form.append(key, discount[key]);
      }
      form.append("_method", "PUT");

      const { data } = await api.create(`${url.PUT_A_DISCOUNT}/${id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Notify.Success({ message: data?.message?.success });
      return filterDiscountDataValues(data);
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// DELETE DISCOUNT ACTION
export const deleteDiscountAction = createAsyncThunk(
  "item/deleteItem",
  async ({ id, cb }: Record<string, any>, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.delete(`${url.DELETE_A_DISCOUNT}/${id}`);
      cb();
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);
