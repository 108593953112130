import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createExpenseTypeAction,
  deleteExpenseTypeAction,
  fetchAllExpenseTypesAction,
  getExpenseTypeAction,
  updateExpenseTypeAction,
} from "./expenseTypesAction";

interface InitialStateType {
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Array<string>;
}

interface GeneralState {
  expense_type: InitialStateType;
  expenses_types: InitialStateType;
}

const initialStateStructure = {
  data: [],
  isLoading: true,
  success: false,
  message: [],
};

const initialState: GeneralState = {
  expense_type: {
    ...initialStateStructure,
    isLoading: false,
  },
  expenses_types: {
    ...initialStateStructure,
    isLoading: false,
  },
};

const rolesSlice = createSlice({
  name: "expenses_types",
  initialState,
  reducers: {
    resetType: (state) => {
      state.expense_type.data = [];
      state.expense_type.isLoading = false;
      state.expense_type.message = [];
      state.expense_type.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // FETCH ALL EXPENSE TYPES
      .addCase(fetchAllExpenseTypesAction.pending, (state) => {
        state.expenses_types.isLoading = true;
      })
      .addCase(
        fetchAllExpenseTypesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.expenses_types = action.payload;
          state.expenses_types.isLoading = false;
        }
      )
      .addCase(
        fetchAllExpenseTypesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.expenses_types.isLoading = false;
          state.expenses_types.success = action.payload?.success;
          state.expenses_types.message = action.payload?.message;
        }
      )

      // Create Expense Type
      .addCase(createExpenseTypeAction.pending, (state) => {
        state.expense_type.isLoading = true;
      })
      .addCase(
        createExpenseTypeAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.expense_type = action.payload;
          state.expense_type.isLoading = false;
        }
      )
      .addCase(
        createExpenseTypeAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.expense_type.isLoading = false;
          state.expense_type.success = action.payload?.success;
          state.expense_type.message = action.payload?.message;
        }
      )

      // Fetch Expense Type
      .addCase(getExpenseTypeAction.pending, (state) => {
        state.expenses_types.isLoading = true;
      })
      .addCase(
        getExpenseTypeAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.expenses_types = action.payload;
          state.expenses_types.isLoading = false;
        }
      )
      .addCase(
        getExpenseTypeAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.expenses_types.isLoading = false;
          state.expenses_types.success = action.payload?.success;
          state.expenses_types.message = action.payload?.message;
        }
      )

      // Update Expense Type
      .addCase(updateExpenseTypeAction.pending, (state) => {
        state.expense_type.isLoading = true;
      })
      .addCase(
        updateExpenseTypeAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.expense_type = action.payload;
          state.expense_type.isLoading = false;
        }
      )
      .addCase(
        updateExpenseTypeAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.expense_type.isLoading = false;
          state.expense_type.success = action.payload?.success;
          state.expense_type.message = action.payload?.message;
        }
      )

      // Delete Type
      .addCase(deleteExpenseTypeAction.pending, (state) => {
        state.expense_type.isLoading = true;
      })
      .addCase(
        deleteExpenseTypeAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.expense_type = action.payload;
          state.expense_type.isLoading = false;
        }
      )
      .addCase(
        deleteExpenseTypeAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.expense_type.isLoading = false;
          state.expense_type.success = action.payload?.success;
          state.expense_type.message = action.payload?.message;
        }
      );
  },
});

export const { resetType } = rolesSlice.actions;

export default rolesSlice.reducer;
