import { useState, useEffect } from "react";
import { useAppSelector } from "../../../redux/store";
import { DiscountOptionType } from "./DiscountTypes";

export const useDiscountStateData = () => {
  const { enums } = useAppSelector((state) => state.list);
  const [customEnum, setCustomEnum] = useState<Array<DiscountOptionType>>([]);

  useEffect(() => {
    if (enums?.data?.ItemType) {
      const types = enums?.data?.PriceType;
      setCustomEnum(() => {
        return Object.keys(types).map((type) => ({
          id: types[type],
          name: type,
        }));
      });
    }
  }, [enums]);

  return {
    enums,
    customEnum,
  };
};
