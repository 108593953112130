import {
  FC,
  useState,
  ChangeEvent,
  useCallback,
  MouseEventHandler,
  Fragment,
} from "react";
import { Button, TextInput } from "../../../../../Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../../../../Components/Common/Tooltip";
import { useLanguageName } from "../../../../../hooks/useLanguageName";
import { useAppDispatch } from "../../../../../redux/store";
import { updateCorporateSettingAction } from "../../../../../redux/corporate_settings/corporateSettingsAction";

type Row = {
  id: string;
  name?: string;
  default?: any;
  current?: any;
};
type ColorData = {
  [key: string]: string;
};
type ColorColumn = {
  label?: string;
  visible?: boolean;
};
export const ColorsTab: FC<any> = ({
  isEdit,
  settingsData,
  setSettingsData,
  isLoading,
}) => {
  const columns: ColorColumn[] = [
    {
      label: useLanguageName(
        "corporate.settings.corporates_settings.color_name"
      ),
      visible: true,
    },
    {
      label: useLanguageName(
        "corporate.settings.corporates_settings.default_color"
      ),
      visible: true,
    },
    {
      label: useLanguageName(
        "corporate.settings.corporates_settings.current_color"
      ),
      visible: true,
    },
    {
      label: useLanguageName(
        "corporate.settings.corporates_settings.new_color"
      ),
      visible: !!isEdit,
    },
  ];
  const rows: Row[] = [
    {
      id: "primary_color",
      name: useLanguageName(
        "corporate.settings.corporates_settings.primary_color"
      ),
      default: settingsData?.default_primary_color,
      current: settingsData?.primary_color,
    },
    {
      id: "secondary_color",
      name: useLanguageName(
        "corporate.settings.corporates_settings.secondary_color"
      ),
      default: settingsData?.default_secondary_color,
      current: settingsData?.secondary_color,
    },
    {
      id: "primary_button_color",
      name: useLanguageName(
        "corporate.settings.corporates_settings.primary_button_color"
      ),
      default: settingsData?.default_primary_button_color,
      current: settingsData?.primary_button_color,
    },
    {
      id: "secondary_button_color",
      name: useLanguageName(
        "corporate.settings.corporates_settings.secondary_button_color"
      ),
      default: settingsData?.default_secondary_button_color,
      current: settingsData?.secondary_button_color,
    },
  ];
  const [data, setData] = useState<ColorData>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const dispatch = useAppDispatch();
  const submitColor: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      const { id } = event.currentTarget;
      if (data?.[id]) {
        dispatch(updateCorporateSettingAction({ [id]: `#${data?.[id]}` }));
      }
    },
    [data, dispatch]
  );

  const resetEntry: MouseEventHandler<SVGSVGElement> = useCallback((event) => {
    event.preventDefault();
    const { id } = event.currentTarget;
    setData((prev) => ({
      ...prev,
      [id]: "",
    }));
  }, []);

  return (
    <>
      <div className="relative overflow-x-auto overflow-y-hidden">
        <table className="w-full text-left">
          <thead>
            <tr>
              {columns?.map(
                (col) =>
                  col.visible && (
                    <th
                      key={col?.label}
                      scope="col"
                      className="text-base font-bold opacity-70 px-6 py-3"
                    >
                      {col.label}
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, idx) => {
              return (
                <Fragment key={row.name}>
                  <tr className="bg-gray-50 rounded-modal-radius bg-light-gray-3 border-none">
                    <th
                      scope="row"
                      className="text-sm font-bold opacity-80 px-6 py-4 whitespace-nowrap rounded-tl-modal-radius rounded-bl-modal-radius"
                    >
                      <p className="">{row?.name}</p>
                    </th>
                    <td className="px-6 py-4">
                      <div className="flex items-center gap-2.5">
                        <div
                          id="badge"
                          className="w-[15px] h-[15px] rounded-[3px]"
                          style={{
                            background: row?.default,
                          }}
                        />
                        <span
                          className="text-sm font-bold"
                          style={{
                            color: row?.default,
                          }}
                        >
                          {row?.default}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center gap-2.5">
                        <div
                          id="badge"
                          className="w-[15px] h-[15px] rounded-[3px]"
                          style={{
                            background: row?.current,
                          }}
                        />
                        <span
                          className="text-sm font-bold"
                          style={{
                            color: row?.current,
                          }}
                        >
                          {row?.current}
                        </span>
                      </div>
                    </td>

                    {isEdit && (
                      <td className="px-6 py-4">
                        <TextInput
                          name={row.id}
                          label=""
                          tag="#"
                          tagStyle="pt-[2px] pb-[4px] pl-[6px] pr-[10px]"
                          inputBoxStyle="border bg-white w-[190px] h-[35px]"
                          onChange={handleChange}
                          value={
                            data?.[row.id] !== undefined
                              ? data?.[row.id]
                              : "000000"
                          }
                          maxLength={6}
                          tagBackgroundColor={data?.[row.id]}
                        />
                      </td>
                    )}

                    {isEdit && (
                      <td className="px-6 py-4 rounded-tr-modal-radius rounded-br-modal-radius">
                        <div className="flex items-center gap-[20px]">
                          <Button
                            id={row.id}
                            type="button"
                            label="Apply"
                            icon={null}
                            styles="text-sm font-bold text-white bg-primary-color !w-[98px] !h-[32px]"
                            onClick={submitColor}
                            isLoading={!!data?.[row.id] && isLoading}
                          />
                          <Tooltip message="reset" position="bottom">
                            <FontAwesomeIcon
                              id={row.id}
                              className="cursor-pointer hover:scale-110 text-primary-color h-[15px]"
                              icon={faUndo}
                              onClick={resetEntry}
                            />
                          </Tooltip>
                        </div>
                      </td>
                    )}
                  </tr>
                  {idx !== rows.length - 1 && <br />}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
