import { useCallback, useState } from "react";
import { NotifyContainer } from "./NotifyContainer";
import {
  NotifyMessageBlock,
  NotifyMsgOptionType,
  NotifyDataType,
  NotifyControlDataType,
  NotifyProviderProps,
  NotifyControlInitialData,
} from "./NotifyTypes";
import { UUID } from "../../../utils/UUID";
import "./style.css";

// const Notify = useNotify();
// Notify?.Error("Oppps Error", {
//   type: "Error",
//   position: "top_middle",
//   lifetime: 5000,
// });

let NotifyControlData: NotifyControlDataType = NotifyControlInitialData;

export const useNotify = () => {
  return NotifyControlData;
};

const DEFAULT_INTERVAL = 2500;

export const NotifyProvider = ({ children }: NotifyProviderProps) => {
  const [data, setData] = useState<NotifyDataType>({
    top_left: [],
    top_right: [],
    bottom_right: [],
    bottom_left: [],
    top_middle: [],
    bottom_middle: [],
  });

  const Notify = useCallback(
    (options: NotifyMsgOptionType) => {
      let defaultPosition = options?.position || "top_right";
      if (options?.message) {
        const new_item: NotifyMessageBlock = {
          id: UUID(),
          message: options?.message,
          options: {
            lifetime: options?.lifetime ? options?.lifetime : DEFAULT_INTERVAL,
            ...options,
          },
        };

        setData((prevState) => ({
          ...prevState,
          [defaultPosition]: [
            ...prevState[defaultPosition as keyof NotifyDataType],
            new_item,
          ],
        }));
      }
    },
    [setData]
  );

  const Error = useCallback(
    (options?: NotifyMsgOptionType) =>
      Notify(
        (options = {
          ...options,
          message: options?.message || "Error",
          type: "Error",
        })
      ),
    [Notify]
  );

  const Warning = useCallback(
    (options: NotifyMsgOptionType) =>
      Notify(
        (options = {
          ...options,
          message: options?.message || "Warning",
          type: "Warning",
        })
      ),
    [Notify]
  );

  const Success = useCallback(
    (options: NotifyMsgOptionType) =>
      Notify(
        (options = {
          ...options,
          message: options?.message || "Success",
          type: "Success",
        })
      ),
    [Notify]
  );

  const Info = useCallback(
    (options: NotifyMsgOptionType) =>
      Notify(
        (options = {
          ...options,
          message: options?.message || "Info",
          type: "Info",
        })
      ),
    [Notify]
  );

  const NotifyData = useCallback(() => {
    return {
      data: data,
      Error: Error,
      Warning: Warning,
      Success: Success,
      Info: Info,
      Notify: Notify,

      async remove(id: string, container: string) {
        setData((prevState) => ({
          ...prevState,
          [container]: prevState[container as keyof NotifyDataType].filter(
            (e) => e.id !== id
          ),
        }));
      },
    };
  }, [data, setData, Error, Warning, Success, Info, Notify]);

  NotifyControlData = NotifyData();

  return (
    <>
      <NotifyContainer values={NotifyData()} />
      {children}
    </>
  );
};
