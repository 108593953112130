import { RootState, useAppSelector } from "../redux/store";

export const usePermission = (permissionName: string) => {
  const permission = useAppSelector(
    (state: RootState) => state?.general?.permission?.data
  );
  // console.log("🚀 ~~ permission:", permission)

  const hasPermission = permission?.includes(permissionName);

  return !!hasPermission; // Return a boolean
};
