import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
import { useNotify } from "../../Components";

const api = new APIClient();

// GET/CORPORATE SETTINGS ACTION
export const fetchCorporateSettingAction = createAsyncThunk(
  "corporateSettings/getCorporateSettings",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_CORPORATE_SETTINGS);

      const newData = {
        ...data,
        data: {
          ...data?.data?.Corporate,
          ...data?.data?.CorporateSetting,
        },
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        return rejectWithValue(errorData);
      }
    }
  }
);

// POT/CORPORATE SETTINGS ACTION
export const updateCorporateSettingAction = createAsyncThunk(
  "corporateSettings/updateCorporateSettings",
  async (corporateSettings: any, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const form = new FormData();
      for (let key in corporateSettings) {
        if (corporateSettings.hasOwnProperty(key)) {
          form.append(key, corporateSettings[key] as string);
        }
      }
      const { data } = await api.create(url.PUT_CORPORATE_SETTINGS, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const newData = {
        ...data,
        data: {
          ...data?.data?.Corporate,
          ...data?.data?.CorporateSetting,
        },
      };
      Notify.Success({ message: data?.message?.success });

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);
