import { useState, useEffect, Fragment, useCallback } from "react";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
import { useEscapeKeyPressed } from "../../../hooks/useEscapeKeyPressed";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import ErrorImg from "./../../../assets/404.svg";

type Option = {
  id: number | string;
  name: string;
  img?: any;
};

export interface SelectorTypes {
  id?: string;
  name?: string;
  label: string;
  options?: Array<Option>;
  defaultOptions?: Option;
  placeholder: string;
  searchPlaceholder?: string;
  value?: string;
  onChange?: (name: string, value: Option) => void;
}

export const SelectImage = ({
  id,
  name = "selector_input",
  label = "Selector label",
  options = [
    { id: 0, name: "Option 1", img: ErrorImg },
    { id: 1, name: "Option 2", img: ErrorImg },
    { id: 2, name: "Option 3", img: ErrorImg },
    { id: 3, name: "Option 4", img: ErrorImg },
  ],
  defaultOptions,
  placeholder = "Selector placeholder",
  searchPlaceholder = "search placeholder",
  onChange = () => {},
}: SelectorTypes) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<Option>();
  const [open, setOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);

  const handleCloseDrop = () => {
    setOpen(false);
  };
  useEscapeKeyPressed(handleCloseDrop, open);

  useOnClickOutside(
    targetRef,
    useCallback(() => {
      setOpen(false);
    }, [])
  );

  useEffect(() => {
    setSelected(defaultOptions);
  }, [defaultOptions]);

  return (
    <div id={id} ref={setTargetRef}>
      {label && <p className="input-label opacity-100 mb-1">{label}</p>}
      <div
        onClick={() => setOpen(!open)}
        className={`img-selector-input-container h-9`}
      >
        <div className="truncate">
          {selected?.name ? (
            <div className="img-selector-chosen-item">
              <div className="img-selector-chosen-item-img-container">
                <img
                  src={selected.img}
                  alt={`${selected.img}-x`}
                  className="img-selector-chosen-item-img"
                />
              </div>
              <div>{selected.name}</div>
            </div>
          ) : (
            <div className="img-selector-chosen-item-text">{placeholder}</div>
          )}
        </div>

        <FontAwesomeIcon
          className={`h-3 ${open && "rotate-180"} duration-300
          ${!selected && "opacity-30"}`}
          icon={faChevronDown}
        />
      </div>
      <ul
        className={`img-selector-box-container invisible-scroll ${
          open ? "max-h-72" : "max-h-0 opacity-0"
        } duration-300`}
      >
        <div className="img-selector-search-container">
          <div className="img-selector-search-sub-container">
            <FontAwesomeIcon className="icon-color" icon={faSearch} />
            <input
              type="text"
              placeholder={searchPlaceholder}
              className="img-selector-search-input"
              value={search}
              onChange={({ target: { value } }) =>
                setSearch(value.toLowerCase())
              }
            />
          </div>
        </div>
        {options.map((option, index) => (
          <Fragment key={option.id}>
            <li
              className={`img-selector-list-input ${
                option.name.toLocaleLowerCase().startsWith(search)
                  ? "flex"
                  : "hidden"
              }
           
            `}
              onClick={() => {
                if (
                  option?.name?.toLocaleLowerCase() !==
                  selected?.name?.toLocaleLowerCase()
                ) {
                  setSelected(option);
                  setOpen(false);
                  onChange(name, option);
                }
              }}
            >
              <div
                className={`flex items-center p-1 w-full relative  ${
                  selected?.name?.toLocaleLowerCase() ===
                    option?.name?.toLocaleLowerCase() &&
                  "text-main-color !font-semibold"
                }`}
              >
                <div
                  className={` ${
                    selected?.name?.toLocaleLowerCase() ===
                      option?.name?.toLocaleLowerCase() &&
                    "img-selector-selected-list-bg"
                  }`}
                ></div>
                <div className="img-selector-chosen-item-img-container !w-10 mr-2">
                  <img
                    src={option.img}
                    alt={`${option.img}-x`}
                    className="img-selector-chosen-item-img"
                  />
                </div>
                <div>{option.name}</div>
              </div>
            </li>
            {options?.length - 1 !== index && (
              <div className="px-5">
                <hr className="divider" />
              </div>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};
