export const InvoicesData = [
  {
    name: "RI-1201",
    to: "Software company",
    status: "Active",
    color: "#4BB543",
    duration: "6 Months",
    cycles: 2,
    price: "500",
    issueDate: "15 September 2023",
    remainingBalance: "250",
    nextInvoiceDate: "25 February 2023",
  },{
    name: "RI-1201",
    to: "Software company",
    status: "Canceled",
    color: "#E35256",
    duration: "6 Months",
    cycles: 2,
    price: "500",
    issueDate: "test",
    remainingBalance: "250",
    nextInvoiceDate: "25 February 2023",
  },
  {
    name: "RI-1201",
    to: "Software company",
    status: "Finished",
    color: "#adadad",
    duration: "6 Months",
    cycles: 2,
    price: "500",
    issueDate: "15 September 2023",
    remainingBalance: "0",
    nextInvoiceDate: "25 February 2023",
  },
];
