import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchCorporateSettingAction,
  updateCorporateSettingAction,
} from "./corporateSettingsAction";
// You should import the correct type for UserData

interface InitialStateType {
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Record<string, string>;
}

interface GeneralState {
  corporateSettings: InitialStateType;
}

const initialStateStructure = {
  data: {},
  isLoading: true,
  success: true,
  message: {},
};

const initialState: GeneralState = {
  corporateSettings: initialStateStructure,
};

const corporateSettings = createSlice({
  name: "corporateSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET/CORPORATE SETTINGS ACTION
      .addCase(fetchCorporateSettingAction.pending, (state) => {
        state.corporateSettings.isLoading = true;
      })
      .addCase(
        fetchCorporateSettingAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.corporateSettings = action.payload;
          state.corporateSettings.isLoading = false;
        }
      )
      .addCase(
        fetchCorporateSettingAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.corporateSettings.success = action.payload?.success;
          state.corporateSettings.message = action.payload?.message;
        }
      );
    builder
      // GET/CORPORATE SETTINGS ACTION
      .addCase(updateCorporateSettingAction.pending, (state) => {
        state.corporateSettings.isLoading = true;
      })
      .addCase(
        updateCorporateSettingAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.corporateSettings = action.payload;
          state.corporateSettings.isLoading = false;
        }
      )
      .addCase(
        updateCorporateSettingAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.corporateSettings.success = action.payload?.success;
          state.corporateSettings.message = action.payload?.message;
        }
      );
  },
});

export default corporateSettings.reducer;
