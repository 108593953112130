import { useEffect, useState, useRef, Ref } from "react";

import Avatar from "./avatar";
import { Item, ItemDataType } from "./item";
import Logo from "./logo";
import { list } from "./list";
import { Navigator } from "./navigator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { MobileNavbar } from "./mobile-nav";
import { useAppSelector } from "../../redux/store";
import "./style.css";

export const Sidebar = () => {
  const {
    permission: { data },
    languages,
  } = useAppSelector((state) => state.general);

  const [listData, setListData] = useState<Array<ItemDataType>>([]);

  useEffect(() => {
    setListData(list(data, languages));
  }, [data, languages]);

  const [open, setOpen] = useState<boolean | null>(
    JSON.parse(localStorage.getItem("sidebar") ?? "null")
  );
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const lineRef = useRef<HTMLDivElement>(null);
  const forwardedLogoRef: Ref<HTMLAnchorElement> =
    useRef<HTMLAnchorElement>(null);

  const forwardAvatarRef: Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const listItemsRef: Ref<HTMLUListElement> = useRef<HTMLUListElement>(null);

  const sidebarRef: Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const handleResizeListItems = () => {
    if (
      sidebarRef.current &&
      listItemsRef.current &&
      forwardedLogoRef.current &&
      forwardAvatarRef.current
    ) {
      const newHeight =
        forwardedLogoRef.current.offsetHeight +
        forwardAvatarRef.current.offsetHeight;

      listItemsRef.current.style.height = `${
        sidebarRef.current.offsetHeight - newHeight
      }px`;
    }
  };

  useEffect(() => {
    handleResizeListItems();
  }, [dropdown]);

  return (
    <div>
      <MobileNavbar setShowSidebar={setShowSidebar} />
      <div
        ref={sidebarRef}
        className={`group/sidebar sidebar-container ${
          showSidebar ? "translate-x-0" : "-translate-x-full md:translate-x-0"
        }`}
      >
        <div
          className={`mobilenavbar-open ${
            open ? "w-[245px]" : "w-[116px]"
          } duration-300`}
        >
          <Navigator open={open} setOpen={setOpen} />
          <div>
            <Logo ref={forwardedLogoRef} open={open} />
            <button
              className="close-sidebar-icon"
              onClick={() => setShowSidebar(false)}
            >
              <span className="sr-only">Close sidebar</span>
              <FontAwesomeIcon className="w-6 h-6" icon={faXmark} />
            </button>
            <ul
              id="list-container"
              ref={listItemsRef}
              className="sidebar-list-container invisible-scroll"
            >
              {listData?.map((item) => (
                <Item key={item.id} open={open} data={item} lineRef={lineRef} />
              ))}
            </ul>
          </div>
          <Avatar
            ref={forwardAvatarRef}
            open={open}
            dropdown={dropdown}
            setDropdown={setDropdown}
          />
        </div>
      </div>
    </div>
  );
};
