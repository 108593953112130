import {FC, useState, useEffect} from 'react';
import {
  ActionButton,
  Checkbox,
  Navlink,
  TextInput,
} from '../../../../Components';
import {getTaxAction} from '../../../../redux/tax/taxAction';
import {useAppDispatch, useAppSelector} from '../../../../redux/store';
import {useStateData} from '../useStateData';
import {useParams} from 'react-router-dom';
import {DeleteTaxModal} from '../Components/DeleteTaxModal';
import {ActionBtnRows, showTaxNavLinkList} from '../TaxesTableContent';
import {LanguageNameTax} from '../../../../hooks/useLanguageName';

export const ShowTax: FC = () => {
  const {enums} = useStateData();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [priceOptions, setPriceOptions] = useState<any>({
    data: {},
  });

  const {
    taxes: {
      data: {Tax},
    },
  } = useAppSelector((state) => state?.taxes);

  useEffect(() => {
    // set enums options
    if (enums?.data?.PriceType) {
      const types = enums?.data?.PriceType;
      setPriceOptions({
        data: Object.keys(types).reduce((acc: any, type) => {
          acc[types[type]] = type;
          return acc;
        }, {}),
      });
    }
  }, [enums?.data?.PriceType]);

  const dispatch = useAppDispatch();

  const {id: taxId} = useParams();

  useEffect(() => {
    dispatch(getTaxAction(taxId));
  }, []);

  return (
    <div>
      <Navlink
        path="corporate.settings.taxes.show.title"
        routes={showTaxNavLinkList}
        children={
          <ActionButton
            styles="bg-third-color text-white px-[20px] py-[10px]"
            data={ActionBtnRows({
              paths: {
                edit: `/settings/update-tax/${taxId}`,
                setShowDeleteModal,
              },
            })}
          />
        }
      />
      <form
        id="tax-form"
        className="rounded-primary-radius p-7 space-y-7 bg-white"
        noValidate
      >
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            placeholder={LanguageNameTax("data.name")}
            value={Tax?.name}
            disabled
          />
          <TextInput
            placeholder={LanguageNameTax("data.currency_id")}
            value={Tax?.Currency?.name}
            disabled
          />
        </div>
        <div className="grid lg:grid-cols-4 gap-7">
          <TextInput
            placeholder={LanguageNameTax("data.type")}
            value={priceOptions?.data?.[Tax?.price_type]}
            disabled
          />
          <TextInput
            placeholder={LanguageNameTax("data.price")}
            value={Tax?.price}
            disabled
          />
          <div className="flex items-center gap-1 col-span-2">
            <Checkbox
              id="tax_is_active"
              afterLabel={LanguageNameTax("data.is_active")}
              checked={Tax?.is_active}
              disabled
            />
            <p className="text-sm font-normal opacity-70">
              {LanguageNameTax('data.is_active_details')}
            </p>
          </div>
        </div>
      </form>
      <DeleteTaxModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
    </div>
  );
};
