import { FC, useEffect } from "react";
import {
  ActionButton,
  Button,
  Navlink,
  Table,
  useNotify,
} from "../../../Components";
import { EmployeeTableActionBtnData } from "../Employees/EmployeeTableContent";
import { DiscountFilterTab } from "./Components/DiscountFilterTab";
import { DiscountSortTab } from "./Components/DiscountSortTab";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useLanguageName } from "../../../hooks/useLanguageName";
import { useNavigate } from "react-router-dom";
import { DiscountTableCells, discountsRoot } from "./DiscountsTableContent";
import { useQueryModifier } from "../../../hooks/useQueryModifier";
import { fetchAllDiscountsAction } from "../../../redux/discount/discountAction";
import "./style.css";

export const Discounts: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Notify = useNotify();
  const { message, isLoading, data } = useAppSelector(
    (state) => state?.discounts?.discounts
  );

  const permission = useAppSelector(
    (state) => state?.general?.permission?.data
  );

  const { query, resetQueryParams } = useQueryModifier();
  useEffect(() => {
    dispatch(fetchAllDiscountsAction(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    Object.keys(message)?.map((el: any) =>
      Notify?.Error({ message: message?.[el] })
    );
    Object.keys(message)?.length && resetQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const btnLabel = useLanguageName("corporate.settings.discounts.create.title");
  const exportLabel = useLanguageName("corporate.crud.export");

  return (
    <div>
      <Navlink path="corporate.settings.discounts" routes={discountsRoot}>
        {permission?.includes("corporate.settings.discounts.create") && (
          <Button
            styles="add-discount-btn"
            labelStyles="text-sm"
            label={btnLabel}
            icon={null}
            onClick={() => navigate("/settings/add-discount")}
          />
        )}
      </Navlink>
      <div className="w-full">
        <Table
          cellComponent={DiscountTableCells}
          data={data?.Discounts}
          rowLink="/settings/show-discount/"
          is_pagination={true}
          pagination={{
            paginateOptions: {
              currentPage: data?.pagination?.current_page,
              totalCount: data?.pagination?.total,
              pageSize: data?.pagination?.per_page,
            },
          }}
          isLoading={isLoading}
          filterHeader={{
            filter: <DiscountFilterTab />,
            sort: <DiscountSortTab />,
            control: (
              <ActionButton
                label={exportLabel}
                styles="export-btn"
                data={EmployeeTableActionBtnData()}
              />
            ),
          }}
          tableCss="!py-0"
        />
      </div>
    </div>
  );
};
