import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faPercentage,
  faTag,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import { TextArea, TextInput } from "../../../../Components";
import "./style.css";

export const InvoiceSummary = () => {
  const handleChange = () => {};

  return (
    <div className="topContainer">
      <TextArea
        name="recurringNote"
        label="Recurring Note"
        placeholder="Write some notes for this invoice"
        onChange={handleChange}
        rows={7}
      />
      <div className="space-y-6">
        <div className="subTotal">
          <div>Sub Total</div>
          <div>00.00</div>
        </div>
        <hr className="hr" /> {/* -------- */}
        <div className="centerBetween">
          <div className="addExtra">Add Extra</div>
          <div className="flex gap-x-5">
            <div className="PrimaryIconContainer">
              <FontAwesomeIcon icon={faTag} className="primaryIcon " />
              <div className="primaryBg" />
            </div>
            <div className="PrimaryIconContainer">
              <FontAwesomeIcon icon={faPercentage} className="primaryIcon" />
              <div className="primaryBg" />
            </div>
            <div className="PrimaryIconContainer">
              <FontAwesomeIcon icon={faTruckFast} className="primaryIcon" />
              <div className="primaryBg" />
            </div>
          </div>
        </div>
        <div className="rowGridContainer">
          <div className="firstRow">
            <div className="">
              <TextInput
                name="tax-name"
                placeholder="Tax Name"
                label=""
                onChange={handleChange}
              />
            </div>
            <div className="min-w-[75px] w-[75px]">
              <TextInput
                name="price"
                placeholder="00.00"
                label=""
                onChange={handleChange}
              />
            </div>
            <div className="ThirdIconContainer ">
              <FontAwesomeIcon icon={faBars} className="barsIcon" />
            </div>
          </div>
          <div className="totalContainerText">00.00</div>
        </div>
        <hr className="hr" /> {/* -------- */}
        <div className="rowGridContainer">
          <div className="firstRow">
            <div className="">
              <TextInput
                name="discount-name"
                placeholder="Discount Name"
                label=""
                onChange={handleChange}
              />
            </div>
            <div className="min-w-[75px] w-[75px]">
              <TextInput
                name="price"
                placeholder="00.00"
                label=""
                onChange={handleChange}
              />
            </div>
            <div className="ThirdIconContainer ">
              <FontAwesomeIcon icon={faBars} className="barsIcon " />
            </div>
          </div>
          <div className="totalContainerText">-00.00</div>
        </div>
        <hr className="hr" /> {/* -------- */}
        <div className="rowGridContainer">
          <div className="firstRow">
            <div className="">
              <TextInput
                name="shipping-name"
                placeholder="Shipping Name"
                label=""
                onChange={handleChange}
              />
            </div>
            <div className="min-w-[75px] w-[75px]">
              <TextInput
                name="price"
                placeholder="00.00"
                label=""
                onChange={handleChange}
              />
            </div>
            <div className="ThirdIconContainer ">
              <FontAwesomeIcon icon={faBars} className="barsIcon" />
            </div>
          </div>
          <div className="totalContainerText ">00.00</div>
        </div>
        <div className="totalContainer">
          <div className="totalContainerLeft">
            <div className="totalContainerTotal">
              <div className="totalContainerText">Total</div>
              <div className="usd z-50 ">
                <div className="z-30"> USD</div>
                <div className="primaryBg -z-10"></div>
              </div>
            </div>
            <div className="totalContainerText">Paid Amount</div>
            <div className="totalContainerText">Balance</div>
          </div>
          <div className="totalContainerRight">
            <div className="totalContainerText">52.32</div>
            <div className="paidAmount">00.00</div>
            <div className="totalContainerText">256.50</div>
          </div>
        </div>
      </div>
    </div>
  );
};
