import { CellStatusCustom, Normal } from "../../../Components";
import { faPenToSquare, faX } from "@fortawesome/free-solid-svg-icons";
import { usePermission } from "../../../hooks/usePermission";
import { useLanguageName } from "../../../hooks/useLanguageName";

export const DiscountTableCells = [
  {
    Component: Normal,
    compProps: {
      secondValue: "name",
      positionCss: "pt-0 font-bold text-sm",
      customWidth: "ltr:pr-5 rtl:pl-5 whitespace-nowrap",
    },
  },
  {
    Component: Normal,
    compProps: {
      customWidth: "ltr:pr-5 rtl:pl-5 whitespace-nowrap",
      positionCss: "py-6",
      secondValue: "price_type_name",
    },
  },
  {
    Component: CellStatusCustom,
    compProps: {
      active: "Active",
      notActive: "Disabled",
      customWidth: "ltr:pr-5 rtl:pl-5",
    },
  },
  {
    Component: Normal,
    compProps: {
      customWidth: "w-[1%] ",
      positionCss: "pt-0",
      secondValue: "price",
    },
  },
];

export const discountsRoot = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.discounts",
    path: "/settings/discounts",
  },
];

export const addDiscount = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.discounts",
    path: "/settings/discounts",
  },
  {
    name: "corporate.settings.discounts.create.title",
    path: "/settings/add-discount",
  },
];

export const showDiscount = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.discounts",
    path: "/settings/discounts",
  },
  {
    name: "corporate.settings.discounts.show.title",
    path: "/settings/add-discount",
  },
];
export const editDiscount = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.discounts",
    path: "/settings/discounts",
  },
  {
    name: "corporate.settings.discounts.update",
    path: "/settings/edit-discount",
  },
];

export const DiscountActionBtnRows = ({ paths }: any) => {
  const { edit, handleModalClose } = paths;
  const editLabel = useLanguageName(
    "corporate.settings.discounts.show.edit_discount"
  );
  const deleteLabel = useLanguageName("corporate.crud.delete");
  return [
    {
      id: 0,
      name: editLabel,
      path: edit,
      icon: faPenToSquare,
      permission: usePermission("corporate.settings.discounts.update"),
    },
    {
      id: 1,
      name: deleteLabel,
      icon: faX,
      permission: usePermission("corporate.settings.discounts.destroy"),
      optionClick: () => handleModalClose("delete", true),
    },
  ];
};
