import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionButton,
  Button,
  Navlink,
  Table,
  useNotify,
} from "../../../Components";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useQueryModifier } from "../../../hooks/useQueryModifier";
import { usePermission } from "../../../hooks/usePermission";
import { useStateData } from "./useStateData";
import { fetchAllTaxesAction } from "../../../redux/tax/taxAction";
import {
  CellComponentTaxes,
  TaxTableActionBtnData,
  taxNavLinkList,
} from "./TaxesTableContent";
import { FilterTab } from "./Components/FilterTab";
import { SortTab } from "./Components/SortTab";
import "./style.css";

export const Taxes: FC = () => {
  const navigate = useNavigate();
  const { languages } = useStateData();
  const { message, isLoading, data } = useAppSelector(
    (state) => state?.taxes?.taxes
  );
  const Notify = useNotify();

  const { query, resetQueryParams } = useQueryModifier();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllTaxesAction(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    Object.keys(message)?.map((el: any) =>
      Notify?.Error({ message: message?.[el] })
    );
    Object.keys(message)?.length && resetQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <div>
      <Navlink path={"corporate.settings.taxes"} routes={taxNavLinkList}>
        {usePermission("corporate.settings.taxes.create") && (
          <Button
            type="button"
            styles="taxes-index-action-btn"
            labelStyles="text-sm"
            label={
              languages?.data?.ConstantsList?.[
                "corporate.settings.taxes.create.title"
              ]
            }
            icon={null}
            onClick={() => navigate("/settings/add-tax")}
          />
        )}
      </Navlink>
      <div className="table-container pt-0 w-full">
        <Table
          cellComponent={CellComponentTaxes}
          filterHeader={{
            filter: <FilterTab />,
            sort: <SortTab />,
            control: (
              <ActionButton
                label="Export"
                styles="taxes-export-btn"
                data={TaxTableActionBtnData()}
              />
            ),
          }}
          data={data?.Taxes}
          rowLink="/settings/show-tax/"
          isLoading={isLoading}
          is_pagination={true}
          pagination={{
            paginateOptions: {
              currentPage: data?.pagination?.current_page, // current page number
              totalCount: data?.pagination?.total, // total count of records
              pageSize: data?.pagination?.per_page, // total records per page
            },
            select: {
              options: [8, 10, 20, 30, 50, 100], // options for selecting
            },
            // paginationChanges: paginationChanges, // on pagination changes
          }}
        />
      </div>
    </div>
  );
};
