import { createAsyncThunk } from "@reduxjs/toolkit";

export interface UserData {
  id: number;
  name: string;
  email: string;
  // ... other properties
}
// example
export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await fetch("https://jsonplaceholder.ir/users");
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
