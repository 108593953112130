import { FC } from "react";
import { Navlink } from "../../Components";
import { MembershipSummary } from "./components/MembershipSummary";
import { MembershipPayments } from "./components/MembershipPayments";
import "./style.css";
import { useAppSelector } from "../../redux/store";
import { useLanguageName } from "../../hooks/useLanguageName";

export const Membership: FC = () => {
  const { languages } = useAppSelector((state) => state.general);

  return (
    <div>
      <Navlink
        path="corporate.memberships.show.title"
        header={useLanguageName("corporate.memberships.show.description")}
        isBackNavigate={false}
      />
      <div className="membership-page-container">
        <MembershipSummary />
        <div className="overflow-x-auto w-full">
          <MembershipPayments />
        </div>
      </div>
    </div>
  );
};
