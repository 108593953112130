import { FC, useState } from "react";
import { corporateSettingsTypeNavLinkList } from "./CorporateSettingsTableContent";
import { UpdateGeneralTab } from "./TabsComponents/UpdateGeneralTab";
import { useCorporateSettingData } from "./useCorporateSettingData";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { updateCorporateSettingAction } from "../../../redux/corporate_settings/corporateSettingsAction";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useLanguageName } from "../../../hooks/useLanguageName";
import { ActionButton, Button, Navlink, Tabs } from "../../../Components";
import { LogoTab } from "./TabsComponents/LogoTab";
import { ColorsTab } from "./TabsComponents/ColorsTab";
import useInputValidation from "../../../hooks/useInputValidation";
import { ShowGeneralTab } from "./TabsComponents/ShowGeneralTab";

export const CorporateSettings: FC = () => {
  const { languages } = useAppSelector((state) => state.general);

  const {
    settingsData,
    setSettingsData,
    handleChange,
    handleSelectChange,
    currencies,
    Locales,
    countries,
    cities,
    isLoading,
  } = useCorporateSettingData();
  const dispatch = useAppDispatch();

  const [isEdit, setIsEdit] = useState(false);

  const { errors, action: settingsAction } = useInputValidation(settingsData, {
    name: ["required"],
    email: ["required", "email"],
    url: ["required", "url"],
    register_id: ["required"],
    tax_id: ["required"],
    phone_number: ["required"],
    mobile_number: ["required"],
    contact_name: ["required"],
    contact_email: ["required", "email"],
    contact_mobile_number: ["required"],
    address: ["required"],
    postal_code: ["required"],
  });

  const removeKeys = [
    "icon",
    "logo",
    "statement_logo",
    "primary_color",
    "secondary_color",
    "primary_button_color",
    "secondary_button_color",
    "default_primary_color",
    "default_secondary_color",
    "default_primary_button_color",
    "default_secondary_button_color",
  ];

  const handleUpdate = () => {
    if (isEdit) {
      settingsAction(settingsData, () => {
        const newData: any = { ...settingsData };
        removeKeys.forEach((key) => {
          delete newData?.[key];
        });
        dispatch(updateCorporateSettingAction(newData));
        setIsEdit(false);
      });
    } else {
      setIsEdit(true);
    }
  };

  const tabsData = [
    {
      id: 0,
      label: useLanguageName("corporate.settings.corporates_settings.general"),
      content: isEdit ? (
        <UpdateGeneralTab
          isEdit={!isEdit}
          settingsData={settingsData}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          currencies={currencies}
          Locales={Locales}
          countries={countries}
          cities={cities}
          errors={errors}
        />
      ) : (
        <ShowGeneralTab
          isEdit={!isEdit}
          settingsData={settingsData}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          currencies={currencies}
          Locales={Locales}
          countries={countries}
          cities={cities}
          errors={errors}
        />
      ),
    },
    {
      id: 1,
      label: useLanguageName("corporate.settings.corporates_settings.logo"),
      content: (
        <LogoTab
          isEdit={isEdit}
          settingsData={settingsData}
          setSettingsData={setSettingsData}
          handleChange={handleChange}
          isLoading={isLoading}
        />
      ),
    },

    {
      id: 2,
      label: useLanguageName("corporate.settings.corporates_settings.color"),
      content: (
        <ColorsTab
          isEdit={isEdit}
          settingsData={settingsData}
          setSettingsData={setSettingsData}
          handleChange={handleChange}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <div>
      <Navlink
        path="corporate.settings.corporates_settings.title"
        routes={corporateSettingsTypeNavLinkList}
      >
        {isEdit ? (
          <Button
            form="corporate-setting-form"
            type="button"
            styles="bg-main-color text-white h-10 w-full p-0 md:w-[151px] mt-5 md:mt-0 "
            labelStyles="text-sm"
            label={
              languages.data?.ConstantsList?.[
                "corporate.settings.corporates_settings.apply"
              ]
            }
            icon={null}
            onClick={handleUpdate}
          />
        ) : (
          <ActionButton
            label="Actions"
            styles="bg-main-color text-white h-10 w-full p-0 md:w-[151px] mt-5 md:mt-0"
            data={[
              {
                id: 0,
                name: "Edit ",
                icon: faPenToSquare,
                permission: true,
                optionClick: () => handleUpdate(),
              },
            ]}
          />
        )}
      </Navlink>
      <form
        id="expenses_types_form"
        className="rounded-primary-radius p-7 space-y-7 bg-white"
        onSubmit={() => {}}
        noValidate
      >
        <Tabs tabsData={tabsData} />
      </form>
    </div>
  );
};
