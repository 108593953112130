import { FC } from "react";
import { Selector, TextInput } from "../../../../../Components";
import { LanguageNameCorporateSetting } from "../../../../../hooks/useLanguageName";

export const ShowGeneralTab: FC<any> = ({
  settingsData,
  handleSelectChange,
  handleChange,
  currencies,
  Locales,
  countries,
  cities,
}) => {
  return (
    <div>
      <h2 className="text-lg font-bold my-2.5">
        {LanguageNameCorporateSetting("info")}
      </h2>
      <p className="text-sm font-normal">
        {LanguageNameCorporateSetting("descriptions")}
      </p>
      <div className="space-y-7 mt-8">
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            id="name"
            name="name"
            label={LanguageNameCorporateSetting("name")}
            placeholder={LanguageNameCorporateSetting("name_placeholder")}
            onChange={handleChange}
            value={settingsData?.name}
            disabled
          />
          <TextInput
            id="email"
            name="email"
            label={LanguageNameCorporateSetting("email")}
            placeholder={LanguageNameCorporateSetting("email_placeholder")}
            onChange={handleChange}
            value={settingsData?.email}
            disabled
          />

          <TextInput
            id="url"
            name="url"
            label={LanguageNameCorporateSetting("website_url")}
            placeholder={LanguageNameCorporateSetting(
              "website_url_placeholder"
            )}
            onChange={handleChange}
            value={settingsData?.url}
            disabled
          />
          <TextInput
            id="register_id"
            name="register_id"
            label={LanguageNameCorporateSetting("registration_id")}
            placeholder={LanguageNameCorporateSetting(
              "registration_id_placeholder"
            )}
            onChange={handleChange}
            value={settingsData?.register_id}
            disabled
          />
          <TextInput
            id="tax_id"
            type="number"
            name="tax_id"
            label={LanguageNameCorporateSetting("tax_id")}
            placeholder={LanguageNameCorporateSetting("tax_id_placeholder")}
            onChange={handleChange}
            value={settingsData?.tax_id}
            disabled
          />

          <div className="relative">
            <Selector
              id="locale"
              name="locale_id"
              label={LanguageNameCorporateSetting("locale_id")}
              options={Locales}
              placeholder={LanguageNameCorporateSetting(
                "locale_id_placeholder"
              )}
              searchPlaceholder={""}
              defaultOptions={settingsData?.Locale}
              disabled
            />
          </div>
          <div className="relative">
            <Selector
              id="currency"
              name="currency_id"
              label={LanguageNameCorporateSetting("currency_id")}
              options={currencies?.data}
              placeholder={LanguageNameCorporateSetting(
                "currency_id_placeholder"
              )}
              searchPlaceholder={""}
              defaultOptions={settingsData?.Currency}
              onChange={handleSelectChange}
              disabled
            />
          </div>
        </div>
        <div className="w-full h-0.5 bg-gray-color opacity-30" />
      </div>
      <h2 className="text-lg font-bold my-2.5">
        {LanguageNameCorporateSetting("contact_information")}
      </h2>
      <p className="text-sm font-normal">
        {LanguageNameCorporateSetting("contact_description")}
      </p>
      <div className="space-y-7 mt-8">
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            id="phone_number"
            name="phone_number"
            label={LanguageNameCorporateSetting("phone")}
            placeholder={LanguageNameCorporateSetting("phone_placeholder")}
            onChange={handleChange}
            value={settingsData?.phone_number}
            disabled
          />
          <TextInput
            id="mobile_number"
            name="mobile_number"
            label={LanguageNameCorporateSetting("mobile")}
            placeholder={LanguageNameCorporateSetting("mobile_placeholder")}
            onChange={handleChange}
            value={settingsData?.mobile_number}
            disabled
          />

          <TextInput
            id="contact_name"
            name="contact_name"
            label={LanguageNameCorporateSetting("contact_name")}
            placeholder={LanguageNameCorporateSetting(
              "contact_name_placeholder"
            )}
            onChange={handleChange}
            value={settingsData?.contact_name}
            disabled
          />
          <TextInput
            id="contact_email"
            name="contact_email"
            label={LanguageNameCorporateSetting("contact_email")}
            placeholder={LanguageNameCorporateSetting(
              "contact_email_placeholder"
            )}
            onChange={handleChange}
            value={settingsData?.contact_email}
            disabled
          />

          <TextInput
            id="contact_mobile_number"
            name="contact_mobile_number"
            label={LanguageNameCorporateSetting("contact_mobile")}
            placeholder={LanguageNameCorporateSetting(
              "contact_mobile_placeholder"
            )}
            onChange={handleChange}
            value={settingsData?.contact_mobile_number}
            disabled
          />
        </div>
        <div className="w-full h-0.5 bg-gray-color opacity-30" />
      </div>
      <h2 className="text-lg font-bold my-2.5">
        {LanguageNameCorporateSetting("address_information")}
      </h2>
      <p className="text-sm font-normal">
        {LanguageNameCorporateSetting("address_description")}
      </p>
      <div className="space-y-7 mt-8">
        <div className="grid lg:grid-cols-2 gap-7">
          <div className="relative">
            <Selector
              id="country"
              name="country_id"
              label={LanguageNameCorporateSetting("country_id")}
              options={countries?.data}
              placeholder={LanguageNameCorporateSetting(
                "country_id_placeholder"
              )}
              searchPlaceholder={""}
              defaultOptions={settingsData?.Country}
              onChange={handleSelectChange}
              disabled
            />
          </div>
          <div className="relative">
            <Selector
              id="city"
              name="city_id"
              label={LanguageNameCorporateSetting("city_id")}
              options={cities?.data}
              placeholder={LanguageNameCorporateSetting("city_id_placeholder")}
              searchPlaceholder={""}
              defaultOptions={settingsData?.City}
              onChange={handleSelectChange}
              disabled
            />
          </div>

          <TextInput
            id="address"
            name="address"
            label={LanguageNameCorporateSetting("address")}
            placeholder={LanguageNameCorporateSetting("address_placeholder")}
            onChange={handleChange}
            value={settingsData?.address}
            disabled
          />
          <TextInput
            id="postal_code"
            name="postal_code"
            label={LanguageNameCorporateSetting("postal_code")}
            placeholder={LanguageNameCorporateSetting(
              "postal_code_placeholder"
            )}
            onChange={handleChange}
            value={settingsData?.postal_code}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
