import {
  ChangeEvent,
  FC,
  useState,
  useCallback,
  FormEvent,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { object } from "../../../../utils/ValidateErrors";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  Button,
  Checkbox,
  Navlink,
  Selector,
  TextInput,
} from "../../../../Components";
import { itemDataType, itemOptionType } from "../itemsTypes";
import {
  LanguageNameItemData,
  useLanguageName,
} from "../../../../hooks/useLanguageName";
import { addItemNavLinkList } from "../ItemsTableContent";
import { createItemsAction } from "../../../../redux/items/itemsAction";
import { useAddItemStateData } from "../useAddItemStateData";

export const AddItemSetting: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currencies, customEnum, units } = useAddItemStateData();
  const itemDataCreated: any = useAppSelector(
    (state) => state?.items?.itemCreated
  );

  const flag = useRef(false);

  const initialData = useMemo(() => {
    return {
      name: "",
      description: "",
      unit_id: "",
      type: "",
      currency_id: "",
      price: "",
      is_active: 0,
    };
  }, []);
  const initialDataErrors = useMemo(() => {
    return {
      ...initialData,
      is_active: "",
    };
  }, [initialData]);

  const [itemData, setItemData] = useState<itemDataType>(initialData);
  const [errors, setErrors] = useState(initialDataErrors);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setItemData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleChangeCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setItemData((prev) => ({
      ...prev,
      [name]: checked ? 1 : 0,
    }));
  }, []);

  const handleSelectChange = useCallback(
    (name: string, value: itemOptionType) => {
      setItemData((prev) => ({
        ...prev,
        [name]: value.id,
      }));
    },
    []
  );

  const validate = useCallback(() => {
    const itemSettingSchema = object({
      name: ["required"],
      description: ["required"],
      price: ["required"],
      unit_id: ["required"],
      type: ["required"],
      currency_id: ["required"],
    });
    const result = itemSettingSchema.validate(itemData);
    setErrors(result.errors);
    return result.valid;
  }, [itemData]);

  const saveItem = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(createItemsAction(itemData)).then(({ payload }) => {
          payload?.success
            ? navigate("/settings/items")
            : setErrors(payload?.message);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, itemData, validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  useEffect(() => {
    if (!itemDataCreated.success) {
      setErrors(itemDataCreated.message);
    }
  }, [itemDataCreated]);

  return (
    <div>
      <Navlink
        path="corporate.settings.items.create.title"
        routes={addItemNavLinkList}
      >
        <Button
          form="add-item-form"
          type="submit"
          styles="item-setting-save-add-item-btn"
          label={useLanguageName("corporate.settings.items.create.save")}
          icon={null}
        />
      </Navlink>
      <div className="table-container">
        <form
          id="add-item-form"
          onSubmit={saveItem}
          className="form-box-container"
          noValidate
        >
          <div className="employee-box-row">
            <TextInput
              id="item_name"
              name="name"
              label={LanguageNameItemData("name")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              placeholder={LanguageNameItemData("name_placeholder")}
              value={itemData?.name}
              onChange={handleChange}
              error={errors?.name}
              errorMsg={errors?.name}
            />
            <TextInput
              id="item_description"
              name="description"
              label={LanguageNameItemData("description")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              placeholder={LanguageNameItemData("description_placeholder")}
              value={itemData?.description}
              onChange={handleChange}
              error={errors?.description}
              errorMsg={errors?.description}
            />

            <div className="relative">
              <Selector
                id="item_unit_id"
                name="unit_id"
                label={LanguageNameItemData("unit_id")}
                labelStyle="!text-base"
                options={units?.data}
                placeholder={LanguageNameItemData("unit_placeholder")}
                searchPlaceholder="Search Item type"
                onChange={handleSelectChange}
                error={errors?.unit_id}
                errorMsg={errors?.unit_id}
              />
            </div>

            <div className="relative">
              <Selector
                id="item_type"
                name="type"
                label={LanguageNameItemData("type")}
                labelStyle="!text-base"
                options={customEnum}
                placeholder={LanguageNameItemData("type_placeholder")}
                searchPlaceholder="Search Item type"
                onChange={handleSelectChange}
                error={errors?.type}
                errorMsg={errors?.type}
              />
            </div>

            <div className="relative">
              <Selector
                id="item_currency_id"
                name="currency_id"
                label={LanguageNameItemData("currency_id")}
                labelStyle="!text-base"
                options={currencies?.data}
                placeholder={LanguageNameItemData("currency_placeholder")}
                searchPlaceholder="Search currency"
                onChange={handleSelectChange}
                error={errors?.currency_id}
                errorMsg={errors?.currency_id}
              />
            </div>

            <TextInput
              id="item_price"
              name="price"
              label={LanguageNameItemData("price")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              placeholder={LanguageNameItemData("price_placeholder")}
              value={itemData?.price}
              onChange={handleChange}
              type="number"
              error={errors?.price}
              errorMsg={errors?.price}
            />
            <div className="flex gap-1">
              <Checkbox
                id="item_is_active"
                name="is_active"
                afterLabel={LanguageNameItemData("is_active")}
                styles="checked:bg-main-color"
                onChange={handleChangeCheck}
                error={errors?.is_active}
                errorMsg={errors?.is_active}
              />
              <p className="is_active_description ">
                ({LanguageNameItemData("is_active_description")})
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
