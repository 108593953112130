import {
  faFileCsv,
  faFilePdf,
  faPenToSquare,
  faPrint,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { usePermission } from "../../../hooks/usePermission";
import { CellName, CellStatus } from "../../../Components";
import { CellAmount } from "../../../Components/Table/CellAmount";
import { useNavigate } from "react-router-dom";
import { useLanguageName } from "../../../hooks/useLanguageName";

export const addShippNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.shipping",
    path: "/settings/shipping",
  },
  {
    name: "corporate.settings.shipping.create.title",
    path: "/settings/shipping/create",
  },
];
export const CellComponentShipping = [
  {
    Component: CellName,
    compProps: {
      parKey: "name",
    },
  },
  {
    Component: CellStatus,
    compProps: {},
  },

  {
    Component: CellAmount,
    compProps: {
      parKey: "amount",
      customWidth: "p-3 w-[1%]",
    },
  },
];
export const showShippNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.shipping",
    path: "/settings/shipping",
  },
  {
    name: "corporate.settings.shipping.show.title",
    path: "/settings/shipping/create",
  },
];
export const updateShippNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.shipping",
    path: "/settings/shipping",
  },
  {
    name: "corporate.settings.shipping.update.title",
    path: "/settings/shipping/:id/update",
  },
];
export const shippNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.shipping",
    path: "/settings/shipping",
  },
];
export const ShippTableActionBtnData = [
  {
    id: 0,
    name: "Print Invoice",
    icon: faPrint,
    permission: true,
  },
  {
    id: 1,
    name: "Export CSV File",
    icon: faFileCsv,
    permission: true,
  },
  {
    id: 2,
    name: "Export PDF File",
    icon: faFilePdf,
    permission: true,
  },
];
export const ActionBtnRows = ({ paths }: any) => {
  const { edit, setShowDeleteModal } = paths;
  const navigate = useNavigate();
  const editLabel = useLanguageName(
    "corporate.settings.shipping.update"
  );
  const deleteLabel = useLanguageName("corporate.crud.delete");
  return [
    {
      id: 0,
      name: editLabel,
      icon: faPenToSquare,
      path: edit,
      permission: usePermission("corporate.settings.shipping.update"),
      optionClick: () => navigate(edit),
    },
    {
      id: 2,
      name: deleteLabel,
      icon: faX,
      permission: usePermission("corporate.settings.shipping.destroy"),
      optionClick: () => setShowDeleteModal(true),
    },
  ];
};
