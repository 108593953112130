import { AddInvoiceRow } from "./AddInvoiceRow";
import {
  defaultItem,
  invoiceAddedItemsTypes,
} from "../addRecurringInvoiceData";
import { UUID } from "../../../../utils/UUID";
import { Button } from "../../../../Components";
import "./style.css";

type AddInvoiceItemsProps = {
  invoiceAddedItems: invoiceAddedItemsTypes[];
  setInvoiceAddedItems: any;
};

export const AddInvoiceItems = ({
  invoiceAddedItems,
  setInvoiceAddedItems,
}: AddInvoiceItemsProps) => {
  const addItemHandler = () => {
    setInvoiceAddedItems((prev: any) => [
      {
        key: UUID(),
        ...defaultItem,
      },
      ...prev,
    ]);
  };
  const removeItemHandler = (rowKey: string) => {
    setInvoiceAddedItems((prev: any[]) =>
      prev.filter((item) => item.key !== rowKey)
    );
  };

  return (
    <div className="space-y-7">
      <div className="flex justify-between">
        <div>
          <div className="font-bold text-2xl titleText">Items</div>
          <div className="md:text-base text-sm titleText">
            Add and customize invoice items
          </div>
        </div>
        <Button
          label="Add Item"
          icon={null}
          styles="AddInvoiceRowButtons"
          onClick={addItemHandler}
        />
      </div>
      {invoiceAddedItems.map((item, index) => (
        <AddInvoiceRow
          key={item.key}
          rowNum={index}
          data={item}
          removeItem={removeItemHandler}
          setInvoiceAddedItems={setInvoiceAddedItems}
          invoiceAddedItems={invoiceAddedItems}
        />
      ))}
    </div>
  );
};
