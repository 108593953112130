import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
interface NavigatorTypes {
  open: boolean | null;
  setOpen: (state: boolean) => void;
}
export const Navigator = ({ open, setOpen }: NavigatorTypes) => {
  const saveSidebarState = () => {
    localStorage.setItem("sidebar", JSON.stringify(!open));
    setOpen(!open);
  };
  return (
    <div
      onClick={saveSidebarState}
      className="navigator-container group-hover/sidebar:-right-6"
    >
      <FontAwesomeIcon
        icon={open ? faChevronDown : faChevronUp}
        className="navigator-icon"
      />
    </div>
  );
};
