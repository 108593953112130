export const corporateSettingsTypeNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.corporates_settings.title",
    path: "/settings/expenses-types",
  },
];
