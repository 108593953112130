import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import full_logo from "./../../assets/full-logo.svg";
import './style.css';
interface MobileNavbarTypes {
  setShowSidebar: (state: boolean) => void;
}
export const MobileNavbar = ({ setShowSidebar }: MobileNavbarTypes) => {
  return (
    <nav className="navbar">
      <button
        className="open-sidebar-icon"
        onClick={() => setShowSidebar(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <FontAwesomeIcon className="w-6 h-6" icon={faBars} />
      </button>
      <Link to={"/"}>
        <img
          className="full-logo-img logo-img"
          src={full_logo}
          alt="Full logo"
        />
      </Link>
    </nav>
  );
};
