import {RootState, useAppSelector} from '../redux/store';

export const useLanguageName = (prefixName: string) => {
  const {ConstantsList} = useAppSelector(
    (state: RootState) => state.general?.languages?.data
  );
  return ConstantsList?.[`${prefixName}`] || "";
};

// EMPLOYEE
const LanguageNameEmployee = (prefixName: string) => {
  return useLanguageName(`corporate.settings.employees.data.${prefixName}`);
};
const LanguageNameShipp = (prefixName: string) => {
  return useLanguageName(`corporate.settings.shipping.data.${prefixName}`);
};
const LanguageNameCorporateSetting = (prefixName: string) => {
  return useLanguageName(
    `corporate.settings.corporates_settings.${prefixName}`
  );
};

// ITEM SETTINGS
const LanguageNameItemData = (prefixName: string) => {
  return useLanguageName(`corporate.settings.items.data.${prefixName}`);
};
// TAX
const LanguageNameTax = (prefixName: string) => {
  return useLanguageName(`corporate.settings.taxes.data.${prefixName}`);
};

const LanguageNameDiscount = (prefixName: string) => {
  return useLanguageName(`corporate.settings.discounts.data.${prefixName}`);
};
// EXPENSES
const LanguageNameExpensesTypes = (prefixName: string) => {
  return useLanguageName(`corporate.settings.expenses_types.${prefixName}`);
};
// ROLES
const LanguageNameRole = (prefixName: string) => {
  return useLanguageName(`corporate.settings.roles.${prefixName}`);
};

export {
  LanguageNameEmployee,
  LanguageNameShipp,
  LanguageNameTax,
  LanguageNameExpensesTypes,
  LanguageNameCorporateSetting,
  LanguageNameDiscount,
  LanguageNameItemData,
  LanguageNameRole,
};
