import {
  faBan,
  faCirclePause,
  faFileCsv,
  faFilePdf,
  faPenToSquare,
  faPrint,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  CellImagedName,
  CellParagraph,
  CellRole,
  CellStatus,
} from "../../../Components";
import { usePermission } from "../../../hooks/usePermission";
import { useLanguageName } from "../../../hooks/useLanguageName";

export const CellComponentEmployees = [
  {
    Component: CellImagedName,
  },
  {
    Component: CellParagraph,
    compProps: {
      parKey: "email",
    },
  },
  {
    Component: CellParagraph,
    compProps: {
      parKey: "mobile",
    },
  },
  {
    Component: CellRole,
  },
  {
    Component: CellStatus,
    compProps: {
      customWidth: "w-[1%]",
    },
  },
];

export const EmployeeTableActionBtnData = () => {
  const exportLanguages = useLanguageName("corporate.crud.export");
  const exportCsvLanguages = useLanguageName("corporate.crud.export_csv");
  const exportPdfLanguages = useLanguageName("corporate.crud.export_pdf");
  return [
    {
      id: 0,
      name: exportLanguages,
      icon: faPrint,
      permission: true,
    },
    {
      id: 1,
      name: exportCsvLanguages,
      icon: faFileCsv,
      permission: true,
    },
    {
      id: 2,
      name: exportPdfLanguages,
      icon: faFilePdf,
      permission: true,
    },
  ];
};

export const employeeNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.employees",
    path: "/settings/employees",
  },
];

export const addEmployeeNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.employees",
    path: "/settings/employees",
  },
  {
    name: "corporate.settings.employees.index.add_employee",
    path: "/settings/add-employee",
  },
];

export const showEmployeeNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.employees",
    path: "/settings/employees",
  },
  {
    name: "corporate.settings.employees.show.title",
    path: "/settings/add-employee",
  },
];
export const updateEmployeeNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.employees",
    path: "/settings/employees",
  },
  {
    name: "corporate.settings.employees.update.title",
    path: "/settings/add-employees",
  },
];

export const ActionBtnRows = ({ paths }: any) => {
  const editEmployeeLanguages = useLanguageName(
    "corporate.settings.employees.update.title"
  );
  const banEmployeeLanguages = useLanguageName(
    "corporate.settings.employees.show.banned"
  ); //"ban employee"
  const suspendEmployeeLanguages = useLanguageName(
    "corporate.settings.employees.show.suspend"
  ); //"suspend employee"
  const deleteEmployeeLanguages = useLanguageName(
    "corporate.settings.employees.show.delete"
  ); //"delete employee"
  const { edit, handleModalClose } = paths;
  return [
    {
      id: 0,
      name: editEmployeeLanguages,
      path: edit,
      icon: faPenToSquare,
      permission: usePermission("corporate.settings.employee.update"),
    },
    {
      id: 1,
      name: banEmployeeLanguages,
      icon: faBan,
      permission: usePermission("corporate.settings.employee.update"),
      optionClick: () => handleModalClose("is_banned", true),
    },
    {
      id: 2,
      name: suspendEmployeeLanguages,
      icon: faCirclePause,
      permission: usePermission("corporate.settings.employee.update"),
      optionClick: () => handleModalClose("is_suspended", true),
    },
    {
      id: 3,
      name: deleteEmployeeLanguages,
      icon: faX,
      permission: usePermission("corporate.settings.employee.destroy"),
      optionClick: () => handleModalClose("delete", true),
    },
  ];
};
