import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUsers, UserData } from "./userAction"; // You should import the correct type for UserData

interface UserState {
  users: UserData[];
  isLoading: boolean;
  error: any | null;
}

const initialState: UserState = { users: [], isLoading: false, error: null };

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        getUsers.fulfilled,
        (state, action: PayloadAction<UserData[]>) => {
          state.isLoading = false;
          state.users = action.payload;
        }
      )
      .addCase(getUsers.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
