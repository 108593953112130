import {
  FC,
  useEffect,
  useState,
  useRef,
  useCallback,
  ChangeEvent,
  FormEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { createExpenseTypeAction } from "../../../../redux/expense_types/expenseTypesAction";
import { Button, Checkbox, Navlink, TextInput } from "../../../../Components";
import { addExpenseTypeNavLinkList } from "../ExpensesTypesTableContent";
import { LanguageNameExpensesTypes } from "../../../../hooks/useLanguageName";
import { useStateData } from "../useStateData";
import { resetType } from "../../../../redux/expense_types/expenseTypesSlice";
import { object } from "../../../../utils/ValidateErrors";

type TypeData = {
  name: string;
  expenses_count: string;
  is_active?: string;
};

export const AddExpensesTypes: FC = () => {
  const { apiType } = useStateData();

  const {
    expenses_types: {
      data: { ExpenseType },
    },
  } = useAppSelector((state) => state?.expenses_types);

  const [typeData, setTypeData] = useState<TypeData>({
    name: "",
    expenses_count: "",
    is_active: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    expenses_count: "",
    is_active: "",
  });

  const flag = useRef(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setTypeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = useCallback(() => {
    const typeSchema = object({
      name: ["required"],
      is_active: ["required"],
    });
    const result = typeSchema.validate(typeData);
    setErrors(result.errors);
    return result.valid;
  }, [typeData]);

  const dispatch = useAppDispatch();

  const saveType = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(createExpenseTypeAction(typeData));
      }
    },
    [validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  const navigate = useNavigate();

  useEffect(() => {
    if (apiType.success) {
      dispatch(resetType());
      navigate("/settings/expenses-types");
    }
  }, [apiType.success]);

  return (
    <div>
      <Navlink
        path="corporate.settings.expenses_types.create.title"
        routes={addExpenseTypeNavLinkList}
      >
        <Button
          form="expenses_types_form"
          type="submit"
          styles="expenses-type-save-add-btn"
          label={LanguageNameExpensesTypes("create")}
          icon={null}
          isLoading={apiType?.isLoading}
          disabled={apiType?.isLoading}
        />
      </Navlink>
      <form
        id="expenses_types_form"
        className="rounded-primary-radius p-7 space-y-7 bg-white"
        onSubmit={saveType}
        noValidate
      >
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            id="type_name"
            name="name"
            label={LanguageNameExpensesTypes("data.name")}
            placeholder={LanguageNameExpensesTypes("data.name_placeholder")}
            onChange={handleChange}
            value={typeData?.name}
            error={errors?.name}
            errorMsg={errors?.name}
          />
          <div className="flex flex-col gap-1">
            <Checkbox
              id="type_is_active"
              name="is_active"
              afterLabel={LanguageNameExpensesTypes("data.is_active")}
              styles="checked:bg-main-color"
              onChange={handleChange}
              error={errors?.is_active}
              errorMsg={errors?.is_active}
              checked={ExpenseType?.is_active}
            />
            <p className="text-sm font-normal opacity-70">
              {LanguageNameExpensesTypes("data.is_active_description")}
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};
