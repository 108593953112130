import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchCitiesAction,
  fetchCountriesAction,
  fetchCurrencyListAction,
  fetchEnumsAction,
  fetchOrderListAction,
  fetchRoleAction,
  fetchUnitsListAction,
} from "./listAction"; // You should import the correct type for UserData

interface InitialStateType {
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Array<string>;
}

interface GeneralState {
  enums: InitialStateType;
  countries: InitialStateType;
  cities: InitialStateType;
  roles: InitialStateType;
  orders: InitialStateType;
  currencies: InitialStateType;
  units: InitialStateType;
}

const initialStateStructure = {
  data: [],
  isLoading: false,
  success: true,
  message: [],
};

const initialState: GeneralState = {
  enums: initialStateStructure,
  countries: initialStateStructure,
  cities: initialStateStructure,
  roles: initialStateStructure,
  orders: initialStateStructure,
  currencies: initialStateStructure,
  units: initialStateStructure,
};

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // FETCH ENUMS LIST ACTION
      .addCase(fetchEnumsAction.pending, (state) => {
        state.enums.isLoading = true;
      })
      .addCase(
        fetchEnumsAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          // state.enums = action.payload;
          state.enums.success = action.payload?.success;
          state.enums.message = action.payload?.message;
          state.enums.data = action.payload?.data;
          state.enums.isLoading = false;
        }
      )
      .addCase(
        fetchEnumsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.enums.isLoading = false;
          state.enums.success = action.payload?.success;
          state.enums.message = action.payload?.message;
        }
      )

      // FETCH COUNTRIES LIST ACTION
      .addCase(fetchCountriesAction.pending, (state) => {
        state.countries.isLoading = true;
      })
      .addCase(
        fetchCountriesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.countries = action.payload;
          state.countries.isLoading = false;
        }
      )
      .addCase(
        fetchCountriesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.countries.isLoading = false;
          state.countries.success = action.payload?.success;
          state.countries.message = action.payload?.message;
        }
      )
      // FETCH CITIES LIST ACTION
      .addCase(fetchCitiesAction.pending, (state) => {
        state.cities.isLoading = true;
      })
      .addCase(
        fetchCitiesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.cities = action.payload;
          state.cities.isLoading = false;
        }
      )
      .addCase(
        fetchCitiesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.cities.isLoading = false;
          state.cities.success = action.payload?.success;
          state.cities.message = action.payload?.message;
        }
      )
      // FETCH ROLE LIST ACTION
      .addCase(fetchRoleAction.pending, (state) => {
        state.roles.isLoading = true;
      })
      .addCase(
        fetchRoleAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.roles = action.payload;
          state.roles.isLoading = false;
        }
      )
      .addCase(
        fetchRoleAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.roles.isLoading = false;
          state.roles.success = action.payload?.success;
          state.roles.message = action.payload?.message;
        }
      )
      // FETCH ORDER LIST ACTION
      .addCase(fetchOrderListAction.pending, (state) => {
        state.orders.isLoading = true;
      })
      .addCase(
        fetchOrderListAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.orders = action.payload;
          state.orders.isLoading = false;
        }
      )
      .addCase(
        fetchOrderListAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.orders.isLoading = false;
          state.orders.success = action.payload?.success;
          state.orders.message = action.payload?.message;
        }
      )
      // FETCH CURRENCY LIST ACTION
      .addCase(fetchCurrencyListAction.pending, (state) => {
        state.currencies.isLoading = true;
      })
      .addCase(
        fetchCurrencyListAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.currencies = action.payload;
          state.currencies.isLoading = false;
        }
      )
      .addCase(
        fetchCurrencyListAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.currencies.isLoading = false;
          state.currencies.success = action.payload?.success;
          state.currencies.message = action.payload?.message;
        }
      )
      // FETCH UNITS LIST ACTION
      .addCase(fetchUnitsListAction.pending, (state) => {
        state.units.isLoading = true;
      })
      .addCase(
        fetchUnitsListAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.units = action.payload;
          state.units.isLoading = false;
        }
      )
      .addCase(
        fetchUnitsListAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.units.isLoading = false;
          state.units.success = action.payload?.success;
          state.units.message = action.payload?.message;
        }
      );
  },
});

export default listSlice.reducer;
