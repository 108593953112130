/* eslint-disable react/jsx-no-undef */
import { Navigate } from "react-router-dom";
import {
  AddClient,
  AddDiscounts,
  AddEmployee,
  AddExpenses,
  AddInvoice,
  AddItemSetting,
  AddPayments,
  AddRecurringInvoices,
  AddRole,
  AddShipp,
  UpdateShipp,
  AddVendor,
  ClientDetails,
  ClientEdit,
  Clients,
  CorporateSettings,
  Dashboard,
  Discounts,
  Employees,
  Expenses,
  ForgetPassword,
  GeneralSetting,
  InvoiceDetails,
  Invoices,
  ItemsSetting,
  Login,
  Membership,
  Payments,
  Profile,
  RecurringInvoices,
  Reports,
  ResetPassword,
  Roles,
  ShowRole,
  Shipping,
  Taxes,
  VendorDetails,
  Vendors,
  VerifyCode,
  Notifications,
  Error401,
  Error403,
  Error404,
  Error500,
  Maintenance,
  ShowTax,
  AddTax,
  ShowEmployee,
  UpdateEmployee,
  Loader,
  ShowShipp,
  UpdateTax,
  ShowItem,
  UpdateItem,
  ExpenseTypes,
  ShowExpensesTypes,
  AddExpensesTypes,
  UpdateExpensesTypes,
  ShowDiscount,
  UpdateDiscounts,
  UpdateRole,
} from "../Pages";
const authProtectedRoutes = [
  // dashboard routes
  {
    path: "/dashboard",
    component: <Dashboard />,
    isAlow: "corporate.dashboard",
  },
  // reports routes
  { path: "/reports", component: <Reports />, isAlow: "corporate.reports" },
  // invoice routes
  { path: "/invoices", component: <Invoices />, isAlow: "corporate.invoices" },
  { path: "/invoice-details", component: <InvoiceDetails /> },
  { path: "/add-invoice", component: <AddInvoice /> },
  // recurring routes
  {
    path: "/recurring-invoices",
    component: <RecurringInvoices />,
    isAlow: "corporate.recurring_invoices",
  },
  { path: "/add-recurring-invoices", component: <AddRecurringInvoices /> },
  // expenses routes
  { path: "/expenses", component: <Expenses />, isAlow: "corporate.expenses" },
  { path: "/add-expenses", component: <AddExpenses /> },
  // clients routes
  { path: "/clients", component: <Clients />, isAlow: "corporate.clients" },
  { path: "/add-client", component: <AddClient /> },
  { path: "/client-details", component: <ClientDetails /> },
  { path: "/client-edit", component: <ClientEdit /> },
  // vendors routes
  { path: "/vendors", component: <Vendors />, isAlow: "corporate.vendors" },
  { path: "/add-vendor", component: <AddVendor /> },
  { path: "/vendor-details", component: <VendorDetails /> },
  // payments routes
  { path: "/payments", component: <Payments />, isAlow: "corporate.payments" },
  { path: "/add-payments", component: <AddPayments /> },
  // Notifications
  {
    path: "/notifications",
    component: <Notifications />,
    isAlow: "corporate.payments",
  },
  // Profile
  { path: "/profile", component: <Profile />, isAlow: "corporate.payments" },
  // Member Ship
  {
    path: "/membership",
    component: <Membership />,
    // fix it
    isAlow: "corporate.payments",
  },
  // settings routes
  {
    path: "/settings",
    component: <GeneralSetting />,
    isAlow: "corporate.settings",
  },
  {
    path: "/settings/corporate",
    component: <CorporateSettings />,
    isAlow: "corporate.settings.corporate_settings",
  },
  {
    path: "/settings/discounts",
    component: <Discounts />,
    isAlow: "corporate.settings.discounts",
  },
  {
    path: "/settings/add-discount",
    component: <AddDiscounts />,
    isAlow: "corporate.settings.discounts.create",
  },
  {
    path: "/settings/show-discount/:id",
    component: <ShowDiscount />,
    isAlow: "corporate.settings.discounts.show",
  },
  {
    path: "/settings/update-discount/:id",
    component: <UpdateDiscounts />,
    isAlow: "corporate.settings.discounts.update",
  },
  {
    path: "/settings/employees",
    component: <Employees />,
    isAlow: "corporate.settings.employee",
  },
  {
    path: "/settings/add-employee",
    component: <AddEmployee />,
    isAlow: "corporate.settings.employee.create",
  },
  {
    path: "/settings/show-employee/:id",
    component: <ShowEmployee />,
    isAlow: "corporate.settings.employee.show",
  },
  {
    path: "/settings/update-employee/:id",
    component: <UpdateEmployee />,
    isAlow: "corporate.settings.employee.update",
  },
  {
    path: "/settings/expenses-types",
    component: <ExpenseTypes />,
    isAlow: "corporate.settings.expenses_types",
  },
  {
    path: "/settings/add-expense-type",
    component: <AddExpensesTypes />,
    isAlow: "corporate.settings.expenses_types.create",
  },
  {
    path: "/settings/show-expense-type/:id",
    component: <ShowExpensesTypes />,
    isAlow: "corporate.settings.expenses_types.show",
  },
  {
    path: "/settings/update-expense-type/:id",
    component: <UpdateExpensesTypes />,
    isAlow: "corporate.settings.expenses_types.update",
  },
  {
    path: "/settings/items",
    component: <ItemsSetting />,
    isAlow: "corporate.settings.items",
  },
  {
    path: "/settings/add-item",
    component: <AddItemSetting />,
    isAlow: "corporate.settings.items.create",
  },
  {
    path: "/settings/show-item/:id",
    component: <ShowItem />,
    isAlow: "corporate.settings.items.show",
  },
  {
    path: "/settings/update-item/:id",
    component: <UpdateItem />,
    isAlow: "corporate.settings.items.update",
  },
  {
    path: "/settings/roles",
    component: <Roles />,
    isAlow: "corporate.settings.roles",
  },
  {
    path: "/settings/add-role",
    component: <AddRole />,
    isAlow: "corporate.settings.roles.create",
  },
  {
    path: "/settings/show-role/:id",
    component: <ShowRole />,
    isAlow: "corporate.settings.roles.show",
  },
  {
    path: "/settings/update-role/:id",
    component: <UpdateRole />,
    isAlow: "corporate.settings.roles.update",
  },
  {
    path: "/settings/shipping",
    component: <Shipping />,
    isAlow: "corporate.settings.shipping",
  },
  {
    path: "/settings/shipping/create",
    component: <AddShipp />,
    isAlow: "corporate.settings.shipping.create",
  },
  {
    path: "/settings/shipping/:id/update",
    component: <UpdateShipp />,
    isAlow: "corporate.settings.shipping.update",
  },
  {
    path: "/settings/shipping/:id",
    component: <ShowShipp />,
    isAlow: "corporate.settings.shipping.show",
  },

  {
    path: "/settings/taxes",
    component: <Taxes />,
    isAlow: "corporate.settings.taxes",
  },
  {
    path: "/settings/add-tax",
    component: <AddTax />,
    isAlow: "corporate.settings.taxes.create",
  },
  {
    path: "/settings/show-tax/:id",
    component: <ShowTax />,
    isAlow: "corporate.settings.taxes.show",
  },
  {
    path: "/settings/update-tax/:id",
    component: <UpdateTax />,
    isAlow: "corporate.settings.taxes.update",
  },
  { path: "*", component: <Navigate to="/error-404" /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const unAuthProtectedRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/verify-code", component: <VerifyCode /> },
];

const publicRoutes = [
  { path: "/error-401", component: <Error401 /> }, // not authorized
  { path: "/error-403", component: <Error403 /> }, // permission
  { path: "/error-404", component: <Error404 /> }, // path
  { path: "/error-500", component: <Error500 /> }, // production
  { path: "/maintenance", component: <Maintenance /> },
  { path: "/loading", component: <Loader /> },
];

export { authProtectedRoutes, unAuthProtectedRoutes, publicRoutes };
