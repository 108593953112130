import { FC } from "react";
import { CellRoleTypes } from "../TableTypes";
import "./style.css";

export const CellRole: FC<CellRoleTypes> = ({ data }) => {
  return (
    <div>
      {data?.Role?.name ? (
        <p className="cell-role">{data?.Role?.name}</p>
      ) : (
        <p className="w-[95px]">...</p>
      )}
    </div>
  );
};
