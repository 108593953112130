import {useAppSelector} from '../../../redux/store';

type Response = {
  isLoading: boolean;
  success: boolean;
  message: {[k: string]: any};
  data: {[k: string]: any};
};

export function useStateData() {
  const {orders, roles, currencies} = useAppSelector((state) => state?.list);

  const {languages, permission} = useAppSelector((state) => state?.general);
  const permissions = useAppSelector(
    (state) => state?.roles?.permissions?.data
  );
  const apiShipp: Response = useAppSelector((state) => state?.shipping?.shippings);

  const shipping = useAppSelector((state) => state?.shipping?.shipping);

  return {
    roles,
    permission,
    permissions,
    apiShipp,
    languages,
    currencies,
    orders,
    shipping,
  };
}
