import {
  ChangeEvent,
  useCallback,
  useState,
  useRef,
  FormEvent,
  useEffect,
} from "react";
import Img from "./../../../assets/Group 981.svg";
import { AuthLayout } from "../AuthLayout";
import { forgetPassAction } from "../../../redux/auth/authAction";
import { useNavigate } from "react-router";
import { object } from "../../../utils/ValidateErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import { Button, TextInput } from "../../../Components";

export interface ApiErrors {
  success: boolean;
  message: Array<string>;
  data: Array<[]>;
}

export const ForgetPassword = () => {
  const { languages } = useAppSelector((state) => state.general);
  const navigate = useNavigate();
  const [data, setData] = useState<{ [k: string]: string }>({ email: "" });
  const [errors, setErrors] = useState<{ [k: string]: string }>({
    email: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const flag = useRef(false);

  const validate = useCallback(() => {
    const userSchema = object({
      email: ["string", "required", "email"],
    });
    const result = userSchema.validate(data);
    setErrors(result.errors);
    return result.valid;
  }, [data]);

  const handleSend = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        const dataApiError = await forgetPassAction({
          email: data,
          cb: () => {
            navigate("/verify-code?type=changePass&email=" + data.email);
          },
        });
        setErrors(dataApiError.message);
      }
    },
    [data, navigate, validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  return (
    <AuthLayout>
      <div className="auth-head-container">
        <div className="auth-head-container-forgetpassword-span ">
          <Link to="/login">
            <div className="container-span">
              <FontAwesomeIcon icon={faArrowLeft} />

              <span className="span-text ">Back</span>
            </div>
          </Link>
        </div>
        <img
          className="auth-image-forget-password"
          src={Img}
          alt="forget password icon"
        />
        <h1 className="auth-card-title">
          {
            languages.data?.ConstantsList?.[
              "corporate.auth.login.forget_password"
            ]
          }
        </h1>
        <p className="auth-card-description">
          {
            languages.data?.ConstantsList?.[
              "corporate.auth.forget_password.description"
            ]
          }
        </p>
      </div>
      <form onSubmit={handleSend} noValidate className="form-forgetpassword ">
        <TextInput
          name="email"
          type="email"
          label=""
          value={data?.email}
          placeholder={
            languages.data?.ConstantsList?.[
              "corporate.auth.forget_password.placeholder_email"
            ]
          }
          onChange={handleChange}
          error={errors?.email}
          errorMsg={errors?.email}
          autoFocus
        />
        <div className="auth-card-footer-container ">
          <div className="auth-card-footer">
            <Button
              type="submit"
              label={
                languages.data?.ConstantsList?.[
                  "corporate.auth.forget_password.send_code"
                ]
              }
              icon={null}
            />
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};
