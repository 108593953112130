import { FC } from "react";
import "./style.css";

interface DurationTypes {
  data: any;
  firstPreposition: string;
  secondPreposition: string;
  positionCss: string;
  firstValue: string;
  secondValue: string;
}
export const CellTimeDuration: FC<DurationTypes> = ({
  data,
  firstPreposition = "",
  secondPreposition = "",
  positionCss,
  firstValue = "",
  secondValue = "",
}) => {
  return (
    <div className={` ${positionCss}`}>
      <div className="flex">
        <p className={`time-duration-text `}>
          <span className="font-semibold">{firstPreposition} </span>
          {firstValue && data[firstValue]},
        </p>

        <p className={`time-duration-text`}>
          <span className="font-semibold">{secondPreposition} </span>
          {secondValue && data[secondValue]}
        </p>
      </div>
    </div>
  );
};
