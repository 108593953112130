import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createDiscountAction,
  deleteDiscountAction,
  fetchAllDiscountsAction,
  fetchDiscountAction,
  updateDiscountAction,
} from "./discountAction"; // You should import the correct type for UserData

interface InitialStateType {
  // data: Record<string, Array<{ key: string; value: any }> | string |undefined>;
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Record<string, string>;
}

interface GeneralState {
  discounts: InitialStateType;
  discount: InitialStateType;
  discountCreated: InitialStateType;
  discountDeleted: InitialStateType;
}

const initialStateStructure = {
  data: { Discount: [] },
  isLoading: true,
  success: true,
  message: {},
};

const initialState: GeneralState = {
  discounts: initialStateStructure,
  discount: initialStateStructure,
  discountCreated: initialStateStructure,
  discountDeleted: { ...initialStateStructure, success: false },
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET/ALL DISCOUNT ACTION
      .addCase(fetchAllDiscountsAction.pending, (state) => {
        state.discounts.isLoading = true;
      })
      .addCase(
        fetchAllDiscountsAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.discounts = action.payload;
          state.discounts.isLoading = false;
        }
      )
      .addCase(
        fetchAllDiscountsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.discounts.success = action.payload?.success;
          state.discounts.message = action.payload?.message;
        }
      )
      // GET/A DISCOUNT ACTION
      .addCase(fetchDiscountAction.pending, (state) => {
        state.discount.isLoading = true;
      })
      .addCase(
        fetchDiscountAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.discount.isLoading = false;
          state.discount = action.payload;
        }
      )
      .addCase(
        fetchDiscountAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.discount.success = action.payload?.success;
          state.discount.message = action.payload?.message;
        }
      )
      // POST/CREATE DISCOUNT ACTION
      .addCase(createDiscountAction.pending, (state) => {
        state.discountCreated.isLoading = true;
      })
      .addCase(
        createDiscountAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.discountCreated.isLoading = false;
          state.discountCreated = action.payload;
        }
      )
      .addCase(
        createDiscountAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.discountCreated.isLoading = false;
          state.discountCreated = action.payload;
        }
      )
      // PUT/UPDATE DISCOUNT ACTION
      .addCase(updateDiscountAction.pending, (state) => {
        state.discount.isLoading = true;
      })
      .addCase(
        updateDiscountAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.discount.isLoading = false;
          state.discount = action.payload;
        }
      )
      .addCase(
        updateDiscountAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.discount.isLoading = false;
          state.discount.success = action.payload?.success;
          state.discount.message = action.payload?.message;
        }
      )
      // DELETE ITEM ACTION
      .addCase(deleteDiscountAction.pending, (state) => {
        state.discountDeleted.success = false;
        state.discountDeleted.isLoading = true;
      })
      .addCase(
        deleteDiscountAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.discountDeleted.isLoading = false;
          state.discountDeleted = action.payload;
        }
      )
      .addCase(
        deleteDiscountAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.discountDeleted.isLoading = false;
          state.discountDeleted.success = action.payload?.success;
          state.discountDeleted.message = action.payload?.message;
        }
      );
  },
});

export default discountsSlice.reducer;
