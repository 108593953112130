import { Checkbox } from "../../../../../Components";
import "./style.css";

type PermissionChecksProps = {
  title: string;
  children: any;
  id: string;
  onChange: Function;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
};

export const PermissionChecks = ({
  title,
  children,
  id,
  onChange,
  defaultChecked,
  checked,
  disabled,
  ...rest
}: PermissionChecksProps) => {
  return (
    <div className="add-role-rule-square">
      <Checkbox
        id={id}
        afterLabel={title}
        styles="add-role-check-box"
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        {...rest}
      />
      <hr className="add-role-divider" />
      <div className="add-role-check-grid-container ">{children}</div>
    </div>
  );
};
