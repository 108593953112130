import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TextInput,
  Checkbox,
  Navlink,
  ActionButton,
} from "../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  fetchPermissionsAction,
  getRoleAction,
} from "../../../../redux/role/roleAction";
import { DeleteRoleModal } from "../Components/DeleteRoleModal";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ActionBtnRows, showRoleNavLinkList } from "../RoleTableContent";
import { LanguageNameRole } from "../../../../hooks/useLanguageName";
import { PermissionsMap } from "../Components/PermissionsMap";
import { PermissionType } from "../RolesTypes";
import "./style.css";

export const ShowRole = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state?.roles?.permissions);

  const {
    roles: {
      data: { Role },
    },
  } = useAppSelector((state) => state?.roles);

  const [dataMap, setDataMap] = useState<any>([]);

  useEffect(() => {
    // convert data to map
    let permissions = data;
    let MapPermission = [];

    function convertToHashMap(permission: PermissionType) {
      let MapedPermission = {
        ...permission,
        isChecked: Role?.permissions?.includes(permission.id),
      };
      if (permission.children && permission.children.length > 0) {
        MapedPermission.children = [];
        for (const child of permission.children) {
          MapedPermission.children[child.id] = convertToHashMap(child);
        }
      }
      return MapedPermission;
    }

    for (const Permission of permissions) {
      MapPermission[Permission.id] = convertToHashMap(Permission);
    }
    setDataMap(MapPermission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { id: roleId } = useParams();

  useEffect(() => {
    // fetch all permissions
    dispatch(fetchPermissionsAction());
    dispatch(getRoleAction(roleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div>
      <Navlink
        path="corporate.settings.roles.show"
        routes={showRoleNavLinkList}
      >
        <ActionButton
          data={ActionBtnRows({
            paths: {
              edit: `/settings/roles/${roleId}/update`,
              setShowDeleteModal,
            },
          })}
          icon={faChevronDown}
          styles="bg-third-color text-white px-[20px] py-[10px]"
        />
      </Navlink>

      <div className="add-role-top-container relative ">
        <form id="role-form" className="add-role-title-row" noValidate>
          <TextInput
            name="name"
            label={LanguageNameRole("data.name")}
            placeholder={LanguageNameRole("data.name_placeholder")}
            onChange={() => {}}
            disabled
            value={Role?.name}
          />
          <div className="flex flex-col justify-center">
            <div className="ml-2">
              <Checkbox
                id="is_active"
                name="is_active"
                afterLabel={LanguageNameRole("data.is_active")}
                styles="add-role-check-box"
                onChange={() => {}}
                checked={Role?.is_active}
                disabled
              />
            </div>
            <div className="add-role-is-active-label">
              {LanguageNameRole("data.is_active_description")}
            </div>
          </div>
        </form>
        <div className="add-role-permissions-title">
          {LanguageNameRole("show.permissions")}
        </div>
        <PermissionsMap
          dataMap={dataMap}
          handleParentsAndChildren={() => {}}
          disabled
        />
      </div>
      <DeleteRoleModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
    </div>
  );
};
