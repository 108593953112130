import IMG from "./../../assets/Ellipse 18.svg";
import IMG1 from "./../../assets/Ellipse 18 (1).svg";
import IMG2 from "./../../assets/Ellipse 14.svg";
import IMG3 from "./../../assets/Ellipse 16.svg";
import IMG4 from "./../../assets/Ellipse 15.svg";
import IMG5 from "./../../assets/Ellipse 14.svg";
export const Items = 
    [
        {
            id:1,
            name:"Brian Griffin",
            desc:" wants to collaborate",
            active:"3 minutes ago",
            img:IMG,
            new:true
        },
        {
            id:2,
            name:"Hey Peter ",
            desc:" we’ve got a new user research opportunity for you. Office is looking for people like you",
            active:"28 minutes ago",
            img:IMG4,
            new:true
        },
        {
            id:3,
            name:"Get an overview ",
            desc:" of user engagement for the past week.  and  attention to enhance user satisfaction.",
            active:"3 days ago",
            img:IMG2
        },
        {
            id:4,
            name:"Glenn ",
            desc:" accepted your invite to co-host Cheerful 2-bedroom home in the heart of Quahog",
            active:"3 days ago",
            img:IMG
        },
        {
            id:5,
            name:"Cleveland Brown ",
            desc:" has left you a review. Both of your reviews from this trip are now public",
            active:"1 weeks ago",
            img:IMG4
        },
        {
            id:6,
            name:"confirm your email ",
            desc:" Please  address by clicking on the link we just emailed you",
            active:"5 August 2023",
            img:IMG5
        },
    
    ]