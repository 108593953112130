import { useEffect, useMemo } from "react";
import { setLanguage } from "../helpers/api_helper";

type UseDirectionProps = {
  Element?: HTMLHtmlElement;
};

export const useDirection = ({
  Element = document.getElementsByTagName("html")[0],
}: UseDirectionProps) => {
  const direction = useMemo(
    () => JSON.parse(localStorage.getItem("language-direction") || `{}`),
    []
  );

  const language = direction?.name || "English";

  const directionHandler = (dir: any) => {
    setLanguage(dir?.locale_key);
    localStorage.setItem("language-direction", JSON.stringify(dir));
    if (dir?.is_rtl) {
      Element.setAttribute("dir", "rtl");
    } else {
      Element.setAttribute("dir", "lrt");
    }
  };

  useEffect(() => {
    if (direction?.is_rtl) {
      Element.setAttribute("dir", "rtl");
    } else {
      Element.setAttribute("dir", "ltr");
    }
  }, [Element, direction]);

  return { direction, language, directionHandler };
};
