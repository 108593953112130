import image from "../../../assets/hani.jpg";
import { RemainingBalance } from "../RemainingBalance";
import { CellImagedName } from "../CellImagedName";
import { CellStatus } from "../CellStatus";
import { Normal } from "../Normal";

export const CellComponent = [
  {
    Component: CellImagedName,
    compProps: {
      image: image,
      name: "User Name",
      country: "Palestine",
      positionCss: "",
    },
  },
  {
    Component: CellStatus,
    compProps: {
      positionCss: "positionCss__",
    },
  },
  {
    Component: Normal,
    compProps: {
      positionCss: "positionCss__",
      firstValue: "duration",
      secondValue: "cycles",
      firstLine: "Duration",
      secondLine: "Times",
    },
  },
  {
    Component: Normal,
    compProps: {
      positionCss: "positionCss__",
      firstValue: "price",
      secondValue: "issueDate",
      firstLine: "USD",
      secondLine: "Issue",
    },
  },
  {
    Component: RemainingBalance,
    compProps: {
      positionCss: "positionCss__",
    },
  },
  {
    Component: Normal,
    compProps: {
      positionCss: "",
      value: "next",
      secondValue: "nextInvoiceDate",
      firstLine: "Next Invoice",
    },
  },
];
