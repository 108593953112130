import { Ref, forwardRef } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvatarDropdown } from "./avatar-dropdown";
import { useAppSelector } from "../../redux/store";
interface AvatarTypes {
  open: boolean | null;
  dropdown: boolean;
  setDropdown: (state: boolean) => void;
}
const Avatar = forwardRef(
  (
    { open, dropdown, setDropdown }: AvatarTypes,
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    const user = useAppSelector((state) => state.auth.data.CorporateEmployee);

    return (
      <div ref={ref} className="absolute bottom-0">
        <AvatarDropdown open={open} dropdown={dropdown} />
        <div
          className={`dropdown-container
        ${open ? "w-[245px]" : "w-[116px]"}  duration-300`}
          onClick={() => setDropdown(!dropdown)}
        >
          <div
            className={`flex gap-x-4 pt-5 ${
              dropdown ? "border-t-2" : "border-0"
            }`}
          >
            <button className="dropdown-button relative">
              <img className="dropdown-img" src={user?.avatar} alt="avatar" />
            </button>
            <span
              className={`dropdown-open ${
                open ? "flex" : "hidden"
              } duration-300`}
            >
              <div className="relative">
                {dropdown && <div className="active-dot" />}
                <h2 className="dropdown-title">{user?.name}</h2>
                <p className="dropdown-desc">{user?.Role?.name}</p>
              </div>
              <FontAwesomeIcon
                className={`dropdown-icon ${
                  dropdown && "rotate-180"
                } duration-300`}
                icon={faChevronDown}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export default Avatar;
