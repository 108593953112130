import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  createTaxAction,
  deleteTaxAction,
  fetchAllTaxesAction,
  getTaxAction,
  updateTaxAction,
} from './taxAction';

interface InitialStateType {
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Array<string>;
}

interface GeneralState {
  tax: InitialStateType;
  taxes: InitialStateType;
}

const initialStateStructure = {
  data: [],
  isLoading: true,
  success: false,
  message: [],
};

const initialState: GeneralState = {
  tax: {
    ...initialStateStructure,
    isLoading: false,
  },
  taxes: initialStateStructure,
};

const taxesSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    resetTax: (state) => {
      state.tax.data = [];
      state.tax.isLoading = false;
      state.tax.message = [];
      state.tax.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // FETCH TAXES
      .addCase(fetchAllTaxesAction.pending, (state) => {
        state.taxes.isLoading = true;
      })
      .addCase(
        fetchAllTaxesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.taxes = action.payload;
          state.taxes.isLoading = false;
        }
      )
      .addCase(
        fetchAllTaxesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.taxes.isLoading = false;
          state.taxes.success = action.payload?.success;
          state.taxes.message = action.payload?.message;
        }
      )

      // Create Tax
      .addCase(createTaxAction.pending, (state) => {
        state.tax.isLoading = true;
      })
      .addCase(
        createTaxAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.tax = action.payload;
          state.tax.isLoading = false;
        }
      )
      .addCase(
        createTaxAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.tax.isLoading = false;
          state.tax.success = action.payload?.success;
          state.tax.message = action.payload?.message;
        }
      )

      // // Fetch Tax
      .addCase(getTaxAction.pending, (state) => {
        state.taxes.isLoading = true;
      })
      .addCase(getTaxAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.taxes.isLoading = false;
        state.taxes = action.payload;
      })
      .addCase(getTaxAction.rejected, (state, action: PayloadAction<any>) => {
        state.taxes.isLoading = false;
        state.taxes = action.payload;
      })

      // Delete Tax
      .addCase(deleteTaxAction.pending, (state) => {
        state.tax.isLoading = true;
      })
      .addCase(
        deleteTaxAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.tax = action.payload;
          state.tax.isLoading = false;
        }
      )
      .addCase(
        deleteTaxAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.tax.isLoading = false;
          state.tax.success = action.payload?.success;
          state.tax.message = action.payload?.message;
        }
      )

      // Update Tax
      .addCase(updateTaxAction.pending, (state) => {
        state.tax.isLoading = true;
      })
      .addCase(
        updateTaxAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.tax = action.payload;
          state.tax.isLoading = false;
        }
      )
      .addCase(
        updateTaxAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.tax.isLoading = false;
          state.tax.success = action.payload?.success;
          state.tax.message = action.payload?.message;
        }
      );
  },
});

export const {resetTax} = taxesSlice.actions;

export default taxesSlice.reducer;
