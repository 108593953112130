import { FC } from "react";
import { CellParagraphTypes } from "../TableTypes";
import "./style.css";

export const CellAction: FC<CellParagraphTypes> = ({
  data,
  customStyle,
  parKey = "",
}) => {
  return (
    <div>
      {data?.[parKey] ? (
        <p className={`cell-role ${customStyle}`}>{data?.[parKey]}</p>
      ) : (
        <p className="w-[95px]">...</p>
      )}
    </div>
  );
};
