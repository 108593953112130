import { FC } from "react";
import { Button, Modal } from "../../../../../../Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCirclePause,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "../../../../../../redux/store";
import { updateEmployeeAction } from "../../../../../../redux/employee/employeeAction";
import { useParams } from "react-router-dom";
import { employeeModalType } from "../../EmployeeHeaderType";
import "../style.css";
import { useLanguageName } from "../../../../../../hooks/useLanguageName";

export const UpdateModal: FC<employeeModalType> = ({
  showModal,
  dataControl = {
    name: "is_suspended",
    title: "",
    subTitle: "",
    btnTitle: "",
  },
  handleModalClose = () => {},
}) => {
  const { id: employeeId } = useParams();
  const dispatch = useAppDispatch();

  const handleUpdate = () => {
    dispatch(
      updateEmployeeAction({
        employee: { [dataControl?.name]: 1 },
        id: employeeId,
      })
    );
    handleModalClose(dataControl?.name, false);
  };

  return (
    <Modal
      title=""
      open={showModal?.is_suspended || showModal?.is_banned}
      handleCloseModal={() => handleModalClose(dataControl?.name, false)}
      footer={false}
    >
      <div className="delete-model-body">
        <div className="delete-model-body-header">
          <div
            onClick={() => handleModalClose(dataControl?.name, false)}
            className="delete-model-body-header-box-warning"
          >
            <FontAwesomeIcon
              className="w-5 h-5 text-[#adadad]"
              icon={faXmark}
            />
          </div>
        </div>
        <div className="delete-model-body-icon-box-warning">
          <FontAwesomeIcon
            className="w-9 h-10	text-warning-color"
            icon={dataControl?.name === "is_suspended" ? faCirclePause : faBan}
          />
        </div>
        <div className="delete-model-body-icon-box-description">
          <h1 className="text-xl font-semibold">
            {useLanguageName(dataControl?.title)}
          </h1>
          <p className="text-base font-light opacity-70">
            {useLanguageName(dataControl?.subTitle)}
          </p>
        </div>
        <>
          <Button
            styles="w-full text-warning-color bg-warning-color-03 mb-5"
            label={useLanguageName(dataControl?.btnTitle)}
            icon={null}
            onClick={handleUpdate}
          />
          <p
            className="cursor-pointer text-base font-normal"
            onClick={() => handleModalClose(dataControl?.name, false)}
          >
            {useLanguageName("corporate.crud.cancel")}
          </p>
        </>
      </div>
    </Modal>
  );
};
