import { Routs } from "./Routes";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import { useDirection } from "./hooks/useDirection";

function App() {
  // useMemo(
  //   () => JSON.parse(localStorage.getItem("language-direction") || `{}`),
  //   []
  // );

  // const direction = JSON.parse(
  //   localStorage.getItem("language-direction") ||
  //     `{"id":1,"name":"English","locale_key":"en","is_rtl":0}`
  // );
  // useEffect(() => {
  //   setLanguage(direction?.locale_key);
  // }, [direction]);

  useDirection({ Element: document.getElementsByTagName("html")[0] });
  useInitialDataFetch();

  return <Routs />;
}

export default App;
