import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import Logo from "./../../../assets/logo (2).svg";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { loginUser } from "../../../redux/auth/authAction";
import { AuthLayout } from "../AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { object } from "../../../utils/ValidateErrors";
import { fetchAvailableLanguagesAction } from "../../../redux/general/generalAction";
import {
  Button,
  LanguageSwitch,
  TextInput,
  useNotify,
} from "../../../Components";
export interface LoginData {
  email: string;
  password: string;
}
type Response = {
  isLoading: boolean;
  success: boolean;
  message: { [k: string]: any } | Array<string>;
  data: { [k: string]: any };
};

export const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const response: Response = useAppSelector((state) => state.auth);
  const {
    languages: { data },
  } = useAppSelector((state) => state.general);

  // console.log('data1', ConstantsList);
  const { availableLanguages } = useAppSelector((state) => state.general);
  const authResponse: Response = useAppSelector((state) => state.auth);

  useEffect(() => {
    // set api response errors
    if (!authResponse.success) {
      setErrors(authResponse.message);
    }
  }, [authResponse]);

  useEffect(() => {
    dispatch(fetchAvailableLanguagesAction());
  }, [dispatch]);

  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState<{ [k: string]: any }>({
    email: "",
    password: "",
  });

  const flag = useRef(false);

  const validate = useCallback(() => {
    const userSchema = object({
      email: ["string", "required", "email"],
      password: ["string", "required", "min=6"],
    });
    const result = userSchema.validate(loginData);
    setErrors(result.errors);
    return result.valid;
  }, [loginData]);

  const Notify = useNotify();

  const loginAction = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(
          loginUser({
            loginData: loginData as LoginData,
            cb: () => {
              navigate("/verify-code?email=" + loginData.email);
              authResponse.message.map((msg: string) =>
                Notify.Success({ message: msg })
              );
            },
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, loginData, navigate, validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate(); // validate on input change
    }
  }, [loginData, validate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <AuthLayout>
      <div className="auth-head-container">
        <div className="auth-head-container-login-span">
          <div className="w-full flex justify-end rtl:justify-start">
            <LanguageSwitch options={availableLanguages.data.Locales} />
          </div>
        </div>
        <img className="w-20 h-28" src={Logo} alt="#" />
        <h1 className="auth-card-title">
          {data?.ConstantsList?.["corporate.auth.login.name"]}
        </h1>
        <p className="auth-card-description">
          {data?.ConstantsList?.["corporate.auth.login.description"]}
        </p>
      </div>
      <form onSubmit={loginAction} noValidate className="form-login">
        <div className="">
          <TextInput
            label={data?.ConstantsList?.["corporate.auth.login.email"]}
            name="email"
            type="email"
            value={loginData?.email}
            placeholder={
              data?.ConstantsList?.["corporate.auth.login.placeholder_email"]
            }
            onChange={handleChange}
            error={errors?.email}
            errorMsg={errors?.email}
            autoFocus
          />
        </div>
        <div className="flex flex-col gap-y-2.5">
          <TextInput
            label={data?.ConstantsList?.["corporate.auth.login.password"]}
            name="password"
            type="password"
            value={loginData?.password}
            placeholder="Enter Password"
            onChange={handleChange}
            error={errors?.password}
            errorMsg={errors?.password}
          />
          <Link
            to="/forgot-password"
            className={`forget-link ${errors?.password ? "mt-8" : "mt-0"}`}
          >
            Forget Password?
          </Link>
        </div>
        <div className="auth-card-footer-container w-full">
          <div className="auth-card-footer">
            <Button
              isLoading={response?.isLoading}
              type="submit"
              label="Login"
              styles="bg-primary-color text-white h-12"
              icon={null}
            />
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};
