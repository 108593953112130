import { FC } from "react";
import "./style.css";

interface ImagedNameTypes {
  customStyle?: string;
  data: Record<string, string>;
}

export const CellImagedName: FC<ImagedNameTypes> = ({ customStyle, data }) => {
  return (
    <div className={`py-5 text-sm ${customStyle}`}>
      <div className="cell-img-container">
        <div className="cell-img-box">
          <img
            className="cell-img-box-image"
            src={data?.avatar}
            alt="personal"
          />
        </div>
        <div className="cell-img-name-box">
          <p className="cell-img-name-box-name">{data?.name}</p>
          <div className="cell-img-country">{data?.address}</div>
        </div>
      </div>
    </div>
  );
};
