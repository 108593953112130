import { useAppSelector } from "../../../redux/store";

type Response = {
  isLoading: boolean;
  success: boolean;
  message: { [k: string]: any };
  data: { [k: string]: any };
};

export function useStateData() {
  const { orders } = useAppSelector((state) => state?.list);

  const { languages } = useAppSelector((state) => state?.general);

  const apiType: Response = useAppSelector(
    (state) => state?.expenses_types?.expense_type
  );

  const expenses_types = useAppSelector(
    (state) => state?.expenses_types?.expenses_types
  );

  return {
    expenses_types,
    apiType,
    languages,
    orders,
  };
}
