import {FC, useState, useEffect, useCallback, useMemo} from 'react';
import {
  OptionFilterType,
  columnOptionType,
  sortOptionType,
} from '../ShippingHeaderType';
import {useAppDispatch} from '../../../../../redux/store';
import {fetchOrderListAction} from '../../../../../redux/list/listAction';
import {useQueryModifier} from '../../../../../hooks/useQueryModifier';
import {Button, Selector} from '../../../../../Components';
import {useStateData} from '../../useStateData';
import {useLanguageName} from '../../../../../hooks/useLanguageName';

export const SortTab: FC = () => {
  const dispatch = useAppDispatch();
  const {query, addQueryParams, queryObject} = useQueryModifier();
  const {orders, shipping} = useStateData();

  const [sortValidateMsg, setSortValidateMsg] = useState<string>('');
  useEffect(() => {
    orders?.data?.length === 0 && dispatch(fetchOrderListAction());
  }, [dispatch, orders?.data?.length]);
  const [columnOption, setColumnOption] = useState<columnOptionType>([]);
  const [sortingListOption] = useState<columnOptionType>([
    {id: 'asc', name: 'asc'},
    {id: 'desc', name: 'desc'},
  ]);

  useEffect(() => {
    const customOrders = orders?.data?.Orders
      ? Object.keys(orders?.data?.Orders?.Settings?.Shipping)?.map(
          (property: string) => ({
            id: property,
            name: orders?.data?.Orders?.Settings?.Shipping[property],
          })
        )
      : [];

    setColumnOption([
      {
        id: '',
        name: 'All',
      },
      ...customOrders,
    ]);
  }, [orders]);
  const initialFilterOption = useMemo(() => {
    return {
      order_by: {id: '', name: ''},
      order_type: {id: '', name: ''},
    };
  }, []);

  const [sortOption, setSortOption] =
    useState<sortOptionType>(initialFilterOption);

  // filter shipp and active changes
  const handleChange = useCallback((name: string, option: OptionFilterType) => {
    setSortOption((prev) => ({...prev, [name]: option}));
  }, []);

  // rest of filter options
  const resetFilter = useCallback(() => {
    setSortOption(initialFilterOption);
    addQueryParams({order_by: '', order_type: ''});
  }, [addQueryParams, initialFilterOption]);

  const handleApply = useCallback(() => {
    const orderById = sortOption?.order_by?.id;
    const orderTypeId = sortOption?.order_type?.id;

    if (orderById !== '' && orderTypeId !== '') {
      const customSortOption = {
        order_by: orderById.toString() || '',
        order_type: orderTypeId.toString() || '',
      };
      addQueryParams(customSortOption);
      setSortValidateMsg('');
    } else {
      setSortValidateMsg('is required');
    }
  }, [addQueryParams, sortOption?.order_by?.id, sortOption?.order_type?.id]);

  useEffect(() => {
    setSortOption({
      order_by:
        // eslint-disable-next-line eqeqeq
        columnOption?.filter((item) => item?.id == queryObject?.order_by)[0] ||
        '',
      order_type:
        sortingListOption?.filter(
          // eslint-disable-next-line eqeqeq
          (item) => item?.id == queryObject?.order_type
        )[0] || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, columnOption]);

  return (
    <>
      <Selector
        label={useLanguageName('corporate.crud.column')}
        name="order_by"
        defaultOptions={sortOption?.order_by}
        options={columnOption}
        placeholder="Choose column name"
        searchPlaceholder="Search column name"
        onChange={handleChange}
      />
      {sortValidateMsg && (
        <p className="px-4 py-2 text-failure-color">{sortValidateMsg}</p>
      )}
      <Selector
        label={useLanguageName('corporate.crud.order')}
        name="order_type"
        defaultOptions={sortOption?.order_type}
        options={sortingListOption}
        placeholder="Choose Shipping"
        searchPlaceholder="Search sorting type"
        onChange={handleChange}
      />
      {sortValidateMsg && (
        <p className="px-4 py-2 text-failure-color">{sortValidateMsg}</p>
      )}

      <div className="filter-drop-footer">
        <Button
          label={useLanguageName('corporate.crud.reset')}
          icon={null}
          styles="text-third-color !w-20 h-7"
          labelStyles="text-xs font-semibold "
          onClick={resetFilter}
        />
        <Button
          label={useLanguageName('corporate.crud.apply')}
          icon={null}
          styles="text-white bg-third-color !w-20 !h-7"
          labelStyles="text-xs font-semibold"
          isLoading={shipping?.isLoading}
          onClick={handleApply}
        />
      </div>
    </>
  );
};
