import { FC } from "react";
import ErrorImg from "./../../../assets/401.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../Components";

export const Error401: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="error-page-background">
      <div className="w-full h-full">
        <div className="error-page-content-container">
          <div className="error-page-img-dimentions">
            <img src={ErrorImg} alt="Maintenance" className="w-full" />
          </div>
          <Button
            styles="error-page-btn"
            icon={null}
            onClick={() => navigate("/login")}
            label="Back To Previous Page"
          />
        </div>
      </div>
    </div>
  );
};
