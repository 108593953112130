import { FC, ReactNode, ChangeEvent, useState } from "react";
import {
  faArrowUpWideShort,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryModifier } from "../../../hooks/useQueryModifier";
import { CustomSelector, Search } from "../../Common";
import "./style.css";

type TableHeaderType = {
  filter?: string | ReactNode;
  sort?: string | ReactNode;
  control?: string | ReactNode;
  filterListCustomCss?: string;
  sortListCustomCss?: string;
};

export const TableHeader: FC<TableHeaderType> = ({
  filter,
  sort,
  control,
  filterListCustomCss = "ltr:md:left-[-233px] ltr:left-[-22px] rtl:md:right-0 rtl:right-[-20px]",
  sortListCustomCss = "ltr:sm:left-[-233px] ltr:left-[-132px] rtl:right-[-130px] rtl:md:right[-150px]",
}) => {
  const { addQueryParams, queryObject } = useQueryModifier();

  const [searchText, setSearchText] = useState<string>(queryObject?.q);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  // search input changes
  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      addQueryParams({ q: value, page: 1 });
    }, 1000);
    setTimeoutId(newTimeoutId);
  };

  return (
    <div className="table-header-selector">
      <div className="table-header-search">
        <Search
          name="search"
          placeholder="Search"
          label=""
          value={searchText}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className="table-header-filter-box">
        {filter && (
          <CustomSelector
            list={filter}
            label="Filter"
            icon={faFilter}
            listCustomCss={filterListCustomCss}
          />
        )}
        {sort && (
          <CustomSelector
            list={sort}
            label="Sort"
            icon={faArrowUpWideShort}
            listCustomCss={sortListCustomCss}
          />
        )}
        {control && control}
      </div>
    </div>
  );
};
