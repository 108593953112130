import { FC } from "react";
import { CellParagraphTypes } from "../TableTypes";
import "./style.css";

export const CellParagraph: FC<CellParagraphTypes> = ({
  data,
  customStyle,
  parKey = "",
}) => {
  return <p className={`cell-paragraph ${customStyle}`}>{data?.[parKey]}</p>;
};
