import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
// import { result } from "./domeData";

const api = new APIClient();

// FETCH LANGUAGES ACTION
export const fetchLanguagesAction = createAsyncThunk(
  "general/language",
  async (language: string, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_LANGUAGE, {
        headers: { "X-localization": language },
      });

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// FETCH AVAILABLE LANGUAGES ACTION
export const fetchAvailableLanguagesAction = createAsyncThunk(
  "general/AvailableLanguages",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_LIST_LANGUAGES);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// FETCH PERMISSION ACTION
export const fetchPermissionAction = createAsyncThunk(
  "general/permission",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(url.GIT_AUTH_PERMISSIONS);
      const newData = {
        ...data,
        data: data?.data?.PermissionCodeList,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);
