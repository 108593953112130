import { FC } from "react";
import "./style.css";

interface StatusTypes {
  data: Record<string, string>;
  customStyle?: string;
  active: string;
  notActive: string;
}

export const CellStatusCustom: FC<StatusTypes> = ({
  data,
  customStyle,
  active,
  notActive,
}) => {
  return (
    <div className={`table-status-container ${customStyle}`}>
      {data?.is_active ? (
        <div aria-hidden className={`table-status-success`}>
          {active}
        </div>
      ) : (
        <div aria-hidden className={`table-status-failure`}>
          {notActive}
        </div>
      )}
    </div>
  );
};
