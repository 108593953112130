import { Checkbox, LoadingLayout } from "../../../../../Components";
import { useAppSelector } from "../../../../../redux/store";
import { PermissionType } from "../../RolesTypes";
import { Permission } from "../Permission/Permission";
import { PermissionChecks } from "../PermissionChecks/PermissionChecks";

export const PermissionsMap = ({
  dataMap,
  handleParentsAndChildren,
  disabled,
}: any) => {
  const { isLoading } = useAppSelector((state) => state?.roles?.permissions);
  return (
    <LoadingLayout isLoading={isLoading}>
      {dataMap &&
        dataMap?.map(
          (item: PermissionType) =>
            item && (
              <Permission
                key={item.id}
                title={item.name}
                id={item.id.toString()}
                onChange={handleParentsAndChildren}
                checked={dataMap[item.id].isChecked}
                disabled={disabled}
              >
                {item.children &&
                  item.children.length > 0 &&
                  item.children?.map((rule: PermissionType) => (
                    <PermissionChecks
                      key={rule.id}
                      id={rule.id.toString()}
                      title={rule.name}
                      onChange={handleParentsAndChildren}
                      checked={dataMap[item.id].children[rule.id].isChecked}
                      {...{
                        "data-parent_id": item.id,
                      }}
                      disabled={disabled}
                    >
                      {rule.children &&
                        rule?.children.map((check: PermissionType) => (
                          <Checkbox
                            key={check.id}
                            id={check.id.toString()}
                            afterLabel={check.name}
                            onChange={handleParentsAndChildren}
                            styles="add-role-check-box"
                            checked={
                              dataMap[item.id].children[rule.id].children[
                                check.id
                              ].isChecked
                            }
                            {...{
                              "data-super_parent_id": item.id,
                              "data-parent_id": rule.id,
                            }}
                            disabled={disabled}
                          />
                        ))}
                    </PermissionChecks>
                  ))}
              </Permission>
            )
        )}
    </LoadingLayout>
  );
};
