import { FC, useState, useEffect } from "react";
import { Modal } from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Button";
import { useLanguageName } from "../../../hooks/useLanguageName";

export type DeleteModalType = {
  showModal: boolean;
  dataControl?: any;
  keys?: Record<string, string>;
  deleteLoading?: boolean;
  handleModalClose: () => void;
  handleDelete: () => void;
};

export const DeleteModal: FC<DeleteModalType> = ({
  showModal,
  keys = {},
  deleteLoading = false,
  handleModalClose = () => {},
  handleDelete = () => {},
}) => {
  const [keysNames, setKeysNames] = useState({
    confirm: "corporate.crud.confirm",
    description: "corporate.settings.employees.show.delete.description",
    deleteBtn: "corporate.crud.delete",
    cancelBtn: "corporate.crud.cancel",
  });
  useEffect(() => {
    setKeysNames((prev) => ({ ...prev, ...keys }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title=""
      open={showModal}
      handleCloseModal={() => handleModalClose()}
      footer={false}
    >
      <div className="delete-model-body">
        <div className="delete-model-body-header">
          <div
            onClick={() => handleModalClose()}
            className="delete-model-body-header-box"
          >
            <FontAwesomeIcon
              className="w-5 h-5 text-[#adadad]"
              icon={faXmark}
            />
          </div>
        </div>
        <div className="delete-model-body-icon-box ">
          <FontAwesomeIcon
            className="w-9 h-10	text-failure-color"
            icon={faTrashCan}
          />
        </div>
        <div className="delete-model-body-icon-box-description">
          <h1 className="text-xl font-semibold">
            {useLanguageName(keysNames?.confirm)}
          </h1>
          <p className="text-base font-light opacity-70">
            {useLanguageName(keysNames?.description)}
          </p>
        </div>
        <>
          <Button
            styles="w-full text-failure-color bg-failure-color-03 mb-5"
            label={useLanguageName(keysNames?.deleteBtn)}
            icon={null}
            onClick={handleDelete}
            isLoading={deleteLoading}
            disabled={deleteLoading}
          />
          <p
            className="cursor-pointer text-base font-normal"
            onClick={() => handleModalClose()}
          >
            {useLanguageName(keysNames?.cancelBtn)}
          </p>
        </>
      </div>
    </Modal>
  );
};
