import {createAsyncThunk} from '@reduxjs/toolkit';
import {APIClient} from '../../helpers/api_helper';
import {AxiosError, isAxiosError} from 'axios';
import * as url from '../../helpers/url_helper';
// import { result } from "./domeData";

const api = new APIClient();

interface LoginArgument {
  loginData: {};
  cb: () => void;
}
// POST/LOGIN USER ACTION
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (loginData: LoginArgument, {rejectWithValue}) => {
    try {
      const {data} = await api.create(url.POST_LOGIN, loginData.loginData);
      if (data?.data?.length === 0) {
        loginData.cb();
      }
      localStorage.setItem('authUser', JSON.stringify(data?.data));
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

interface verifyDataArgument {
  email: string;
  code: string;
}

// POST/VERIFY CODE ACTION
export const verifyCodeAction = createAsyncThunk(
  'auth/verifyCodeAction',
  async (verifyData: verifyDataArgument, {rejectWithValue}) => {
    try {
      const {data} = await api.create(url.POST_VERIFY_CODE, verifyData);
      localStorage.setItem('authUser', JSON.stringify(data?.data));
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

interface forgetPassArgument {
  email: {};
  cb: () => void;
}

// POST/GORGET PASSWORD ACTION
export const forgetPassAction = async (forgetPass: forgetPassArgument) => {
  try {
    const {data} = await api.create(
      url.POST_FORGET_PASSWORD,
      forgetPass?.email
    );
    forgetPass?.cb();
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError?.response) {
      return axiosError?.response?.data;
    }
    throw error;
  }
};

interface checkOtpArgument {
  data: {};
  cb: () => void;
}
// POST/CHECK OTP ACTION
export const checkOtpAction = async (checkOtp: checkOtpArgument) => {
  try {
    const {data} = await api.create(url.POST_CHECK_OTP, checkOtp.data);
    checkOtp?.cb();
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError?.response) {
      return axiosError?.response?.data;
    }
    throw error;
  }
};

interface RestPassArgument {
  restData: {};
  cb: () => void;
}

// POST/RESET PASSWORD ACTION
export const restPasswordUser = createAsyncThunk(
  'auth/restPasswordUser',
  async (restData: RestPassArgument, {rejectWithValue}) => {
    try {
      const {data} = await api.create(
        url.POST_REST_PASSWORD,
        restData.restData
      );
      if (data?.data?.length === 0) {
        restData.cb();
      }
      localStorage.setItem('authUser', JSON.stringify(data));
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// GET/RESET LOGOUT TOKEN ACTION
export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, {rejectWithValue}) => {
    try {
      const authUser = localStorage.getItem('authUser');
      if (authUser) {
        const {data} = await api.create(url.POST_LOGOUT);
        localStorage.removeItem('authUser');
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// GET/USER INFO ACTION
export const fetchUserInfoAction = createAsyncThunk(
  'user/getUserInfo',
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await api.get(url.GET_USER_INFO);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

interface userInfoDataType {
  [key: string]: string | number | HTMLImageElement | File;
}

// PUT/UPDATE USER INFO
export const updateUserInfoAction = createAsyncThunk(
  'user/updateUserInfo',
  async (user: userInfoDataType, {rejectWithValue}) => {
    try {
      const form = new FormData();
      for (let key in user) {
        if (user.hasOwnProperty(key)) {
          form.append(key, user[key] as string);
        }
      }
      form.append('_method', 'PUT');
      const {data} = await api.create(url.PUT_USER_INFO, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);
