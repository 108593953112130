import {
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
  FormEvent,
} from "react";
import { TextInput, Checkbox, Button, Navlink } from "../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  createRoleAction,
  fetchPermissionsAction,
} from "../../../../redux/role/roleAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useStateData } from "../useStateData";
import useInputValidation from "../../../../hooks/useInputValidation";
import { addRoleNavLinkList } from "../RoleTableContent";
import { LanguageNameRole } from "../../../../hooks/useLanguageName";
import { PermissionsMap } from "../Components/PermissionsMap";
import { PermissionType } from "../RolesTypes";
import "./style.css";

export const AddRole = () => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector((state) => state?.roles?.permissions);

  const { apiRole, role, handleInputChange, handleCheckboxChange } =
    useStateData();

  const [dataMap, setDataMap] = useState<any>([]);
  const [permissions, setPermissions] = useState<Array<number | string>>([]);

  useEffect(() => {
    // fetch all permissions
    permissions.length === 0 && dispatch(fetchPermissionsAction());
  }, []);

  useEffect(() => {
    // convert data to map
    let permissions = data;
    let MapPermission = [];

    function convertToHashMap(permission: PermissionType) {
      let MapedPermission = { ...permission };
      if (permission.children && permission.children.length > 0) {
        MapedPermission.children = [];
        for (const child of permission.children) {
          MapedPermission.children[child.id] = convertToHashMap(child);
        }
      }
      return MapedPermission;
    }

    for (const Permission of permissions) {
      MapPermission[Permission.id] = convertToHashMap(Permission);
    }
    setDataMap(MapPermission);
  }, [data]);

  const rolesHandler = (
    event: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    const { id } = event.target;
    const { parent_id, super_parent_id } = event.target.dataset;
    if (parent_id && super_parent_id) {
      if (!isChecked) {
        let result = checkAllParents(
          dataMap[super_parent_id].children[parent_id].children[id],
          !isChecked
        );
        setDataMap((prev: any) => {
          const newDataMap: any = [...prev];
          newDataMap[super_parent_id] = result;
          return newDataMap;
        });
      } else {
        setDataMap((prev: any) => {
          const newDataMap: any = [...prev];
          newDataMap[super_parent_id].children[parent_id].children[
            id
          ].isChecked = false;
          return newDataMap;
        });
        setPermissions((prev: any) =>
          prev.filter((item: any) => item !== parseInt(id))
        );
      }
    }
  };

  const handleSelectParent = (
    event: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    const { id } = event.target;
    const { parent_id, super_parent_id } = event.target.dataset;
    if ((parent_id && super_parent_id) || parent_id) {
      // check children
      if (!isChecked) {
        let parents = checkAllParents(
          dataMap[parent_id].children[id],
          !isChecked
        );
        setDataMap((prev: any) => {
          let newDataMap: any = [...prev];
          newDataMap[parent_id] = parents;
          return newDataMap;
        });
      }
      let children = checkAllChildren(
        dataMap[parent_id].children[id],
        !isChecked
      );
      setDataMap((prev: any) => {
        let newDataMap: any = [...prev];
        newDataMap[parent_id].children[id] = children;
        return newDataMap;
      });
    }
    if (!parent_id && !super_parent_id) {
      // check super parents children
      let result = checkAllChildren(dataMap[id], !isChecked);
      setDataMap((prev: any) => {
        let newDataMap: any = [...prev];
        newDataMap[id] = result;
        return newDataMap;
      });
    }
    setPermissions((prev: Array<number | string>) => {
      if (!isChecked) {
        const newPermissions = new Set([...prev, parseInt(id)]);
        return Array.from(newPermissions);
      } else {
        const newPermissions = new Set(prev);
        newPermissions.delete(id);
        return Array.from(newPermissions);
      }
    });
  };

  const checkAllChildren = (permission: PermissionType, isChecked: boolean) => {
    let updatedPermission = { ...permission };
    updatedPermission = { ...permission, isChecked };
    if (permission.children && permission.children.length !== 0) {
      const updatedChildren = permission.children.map((child: PermissionType) =>
        checkAllChildren(child, isChecked)
      );
      updatedPermission.children = updatedChildren;
    }
    setPermissions((prev: Array<number | string>) => {
      if (isChecked) {
        const newPermissions = new Set([...prev, permission.id]);
        return Array.from(newPermissions);
      } else {
        const newPermissions = new Set(prev);
        newPermissions.delete(permission.id);
        return Array.from(newPermissions);
      }
    });
    return updatedPermission;
  };

  const findNestedPermission = (parent_id: number) => {
    for (const superParentPermission of dataMap) {
      if (superParentPermission && superParentPermission.children.length > 0) {
        for (const parentPermission of superParentPermission.children) {
          if (parentPermission) {
            if (parentPermission.id === parent_id) {
              return parentPermission;
            }
          }
        }
      }
    }
    return null;
  };

  const checkAllParents = (
    permission: PermissionType,
    isChecked: boolean
  ): PermissionType => {
    if (permission.parent_id) {
      let parentPermission = dataMap[permission.parent_id];
      if (!parentPermission) {
        parentPermission = findNestedPermission(permission.parent_id);
      }
      parentPermission.isChecked = isChecked;
      const updatedParentPermission = checkAllParents(
        parentPermission,
        isChecked
      );
      permission.isChecked = isChecked;
      setPermissions((prev: Array<number | string>) => {
        if (isChecked) {
          const newPermissions = new Set([
            ...prev,
            updatedParentPermission.id,
            permission.id,
          ]);
          return Array.from(newPermissions);
        }
        return prev;
      });
      return updatedParentPermission;
    }
    return permission;
  };

  const handleParentsAndChildren = (
    event: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    const { parent_id, super_parent_id } = event.target.dataset;
    if (parent_id && super_parent_id) {
      rolesHandler(event, isChecked);
    } else {
      handleSelectParent(event, isChecked);
    }
  };

  const { errors, action: roleAction } = useInputValidation(
    {
      name: role.name,
      permissions: permissions,
    },
    {
      name: ["required"],
      permissions: ["required"],
    }
  );

  const submitRole = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      roleAction(
        {
          name: role.name,
          permissions: permissions,
        },
        () => {
          dispatch(
            createRoleAction({
              role: { ...role, permissions },
            })
          );
        }
      );
    },
    [dispatch, permissions, role, roleAction]
  );

  return (
    <div>
      <Navlink
        path="corporate.settings.roles.create"
        routes={addRoleNavLinkList}
        children={
          <Button
            type="submit"
            form="role-form"
            label={LanguageNameRole("create.save")}
            styles="add-role-save-btn"
            icon={null}
            isLoading={apiRole?.isLoading}
            disabled={apiRole?.isLoading}
          />
        }
      />
      <div className="add-role-top-container relative ">
        <form
          id="role-form"
          onSubmit={submitRole}
          className="add-role-title-row"
          noValidate
        >
          <TextInput
            name="name"
            label={LanguageNameRole("data.name")}
            placeholder={LanguageNameRole("data.name_placeholder")}
            value={role?.name}
            onChange={handleInputChange}
            error={errors?.name}
            errorMsg={errors?.name}
          />
          <div className="flex flex-col justify-center">
            <div className="ml-2">
              <Checkbox
                id="is_active"
                name="is_active"
                afterLabel={LanguageNameRole("data.is_active")}
                styles="add-role-check-box"
                onChange={handleCheckboxChange}
                checked={!!role.is_active}
              />
            </div>
            <div className="add-role-is-active-label">
              {LanguageNameRole("data.is_active_description")}
            </div>
          </div>
        </form>
        <div className="add-role-permissions-title relative">
          {LanguageNameRole("show.permissions")}
          {errors?.permissions && (
            <div className="absolute flex items-center gap-2 text-sm text-failure-color">
              <FontAwesomeIcon icon={faCircleXmark} />
              <span className="text-[0.8rem] font-normal">
                {errors?.permissions}
              </span>
            </div>
          )}
        </div>
        <PermissionsMap
          dataMap={dataMap}
          handleParentsAndChildren={handleParentsAndChildren}
        />
      </div>
    </div>
  );
};
