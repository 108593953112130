import { FC } from "react";
import { usePagination, DOTS } from "./usePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { PaginationTypes } from "../TableTypes";
import "./style.css";

export const Pagination: FC<PaginationTypes> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange =
    usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
    }) || [];
  let firstPage = paginationRange[0] === currentPage;
  let lastPage = paginationRange[paginationRange.length - 1] === currentPage;

  if (currentPage === 0 || paginationRange!.length < 2) {
    return null;
  }

  const onNext = () => {
    !lastPage && onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    !firstPage && onPageChange(currentPage - 1);
  };

  return (
    <div className="pagination-container ">
      <div
        className={`flex-center control-pagination ${
          firstPage && "control-pagination-disabled"
        }`}
        onClick={onPrevious}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="rtl:rotate-180" />
      </div>
      <div className="body-pagination">
        {paginationRange?.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return <div className="dots-pagination dots">&#8230;</div>;
          }
          return (
            <div
              className={`flex-center dot-pagination selected ${
                pageNumber === currentPage
                  ? "bg-primary-color text-white"
                  : "bg-gray-color-2 text-black-color-07"
              }`}
              key={i}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </div>
          );
        })}
      </div>
      <div
        className={`flex-center control-pagination ${
          lastPage && "control-pagination-disabled"
        }`}
        onClick={onNext}
      >
        <FontAwesomeIcon icon={faChevronRight} className="rtl:rotate-180" />
      </div>
    </div>
  );
};
