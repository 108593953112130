// LOGIN
export const POST_LOGIN = "/auth/login";
export const POST_LOGOUT = "/auth/logout";
export const POST_VERIFY_CODE = "/auth/verify";
export const POST_FORGET_PASSWORD = "/auth/password/forget";
export const POST_CHECK_OTP = "/auth/otp/check";
export const POST_REST_PASSWORD = "/auth/password/reset";

// USER INFO
export const POST_TEST = "/users";
export const GET_USER_INFO = "/auth/info";
export const PUT_USER_INFO = "/auth/update";

// GENERAL
export const GIT_LANGUAGE = "/constants";
export const GIT_AUTH_PERMISSIONS = "/auth/permissions";
export const GIT_LIST_LANGUAGES = "/lists/locales";

// List
export const GIT_LIST_ENUMS = "/lists/enums";
export const GIT_LIST_COUNTRIES = "/lists/countries";
export const GIT_CITE_BY_Q = "/lists/cities";
export const GIT_LIST_ROLES = "/lists/roles";
export const GIT_LISTS_ORDERS = "/lists/orders";
export const GIT_LISTS_UNIT = "/lists/units";
export const GIT_LISTS_CURRENCIES = "/lists/currencies";

// EMPLOYEES
export const GIT_ALL_EMPLOYEE = "/settings/employees";
export const GIT_A_EMPLOYEE = "/settings/employees";
export const POST_EMPLOYEE = "/settings/employees";
export const PUT_A_EMPLOYEE = "/settings/employees";
export const DELETE_A_EMPLOYEE = "/settings/employees";

// ITEMS
export const GIT_ALL_ITEMS = "/settings/items";
export const GIT_A_ITEMS = "/settings/items";
export const POST_ITEMS = "/settings/items";
export const PUT_A_ITEMS = "/settings/items";
export const DELETE_A_ITEMS = "/settings/items";

// role
export const GIT_ROLES = "/settings/roles";
export const GIT_PERMISSIONS = "/settings/permissions";

//shipping
export const GIT_SHIPPING = "/settings/shipping";
// tax
export const GIT_TAXES = "/settings/taxes";

// tax
export const GIT_EXPENSE_TYPES = "/settings/expenses_types";

// CORPORATE SETTINGS
export const GIT_CORPORATE_SETTINGS = "/settings/corporate_settings/show";
export const PUT_CORPORATE_SETTINGS = "/settings/corporate_settings/update";

export const POST_JWT_LOGIN = "/post-jwt-login";
export const POST_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";


//DISCOUNTS
export const GIT_ALL_DISCOUNT = "/settings/discounts";
export const POST_DISCOUNT = "/settings/discounts";
export const GET_A_DISCOUNT = "/settings/discounts";
export const DELETE_A_DISCOUNT = "/settings/discounts";
export const PUT_A_DISCOUNT = "/settings/discounts";