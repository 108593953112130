import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Button";
import { useEscapeKeyPressed } from "../../../hooks/useEscapeKeyPressed";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import "./style.css";

type Option = {
  id: number | string;
  name: string;
  path?: string | undefined;
  icon: IconDefinition;
  permission: boolean;
  optionClick?: Function;
};

interface ButtonTypes {
  data: Array<Option>;
  label?: string;
  styles?: string;
  icon?: IconDefinition | null;
  onClick?: () => void;
}

export const ActionButton = ({
  data = [],
  label = "Actions",
  styles = "",
  icon = faDownload,
  // styles = "w-28 h-10	text-white rounded-modal-radius bg-primary-color flex flex-row-reverse",
  onClick = () => {},
}: ButtonTypes) => {
  const [show, setShow] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);

  const handleCloseDrop = () => {
    setShow(false);
  };
  useEscapeKeyPressed(handleCloseDrop, show);

  useOnClickOutside(
    targetRef,
    useCallback(() => {
      setShow(false);
    }, [])
  );

  const handleOptionClick = (cb: Function | undefined) => {
    setShow(false);
    if (typeof cb === "function") {
      cb();
    }
  };

  return (
    <div className="relative" ref={setTargetRef}>
      <Button
        label={label}
        icon={icon}
        styles={styles}
        onClick={() => setShow(!show)}
      />
      {show && (
        <div
          className={`action-btn-modal ${show ? "opacity-100" : "opacity-0"}`}
        >
          <ul className="flex flex-col">
            {data?.map(
              ({ id, name, icon, path, permission, optionClick }, idx) =>
                permission && (
                  <div key={name} className="flex flex-col items-center">
                    {path ? (
                      <li key={name} className="action-btn-listItem">
                        <Link className="action-btn-listItem-link" to={path}>
                          <FontAwesomeIcon className="w-4 h-4" icon={icon} />
                          <span className="capitalize"> {name}</span>
                        </Link>
                      </li>
                    ) : (
                      <li
                        key={name}
                        className="action-btn-listItem custom-hover-effect"
                        onClick={() => handleOptionClick(optionClick)}
                      >
                        <FontAwesomeIcon className="w-4 h-4" icon={icon} />
                        <span className="capitalize"> {name}</span>
                      </li>
                    )}
                    {idx !== data.length - 1 && <hr className="w-4/5" />}
                  </div>
                )
            )}
          </ul>
        </div>
      )}
      {show && (
        // close modal button
        <button
          tabIndex={-1}
          className="cursor-default	z-30 absolute inset-0"
          onClick={() => setShow(false)}
        />
      )}
    </div>
  );
};
