import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
import { useNotify } from "../../Components";

const api = new APIClient();

interface TypeArguments {
  id?: string;
  name: string;
  is_active?: string;
}

// GET/ALL Expenses Types ACTION
export const fetchAllExpenseTypesAction = createAsyncThunk(
  "expenses_types/getAllExpenseTypes",
  async (query: String, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_EXPENSE_TYPES}${query}`);
      const newData = {
        ...data,
        data: data?.data?.ExpenseTypeCollection,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// GET/ Type ACTION
export const getExpenseTypeAction = createAsyncThunk(
  "expenses_types/getExpenseTypeAction",
  async (query: string | undefined, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_EXPENSE_TYPES}/${query}`);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// POST/CREATE EXPENSE TYPE ACTION
export const createExpenseTypeAction = createAsyncThunk(
  "expenses_types/create",
  async (type: TypeArguments, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.create(url.GIT_EXPENSE_TYPES, type);
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// PUT/UPDATE EXPENSE TYPE ACTION
export const updateExpenseTypeAction = createAsyncThunk(
  "expenses_types/update",
  async (type: TypeArguments, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.create(
        `${url.GIT_EXPENSE_TYPES}/${type?.id}`,
        {
          ...type,
          _method: "PUT",
        }
      );
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// DELETE/REMOVE Type ACTION
export const deleteExpenseTypeAction = createAsyncThunk(
  "expenses_types/delete",
  async (query: string | undefined, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.delete(`${url.GIT_EXPENSE_TYPES}/${query}`);
      if (data?.success) {
        Notify.Success({ message: data?.message?.success });
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);
