import { useEffect, FC } from "react";
import {
  ActionButton,
  Button,
  Navlink,
  Table,
  useNotify,
} from "../../../Components";
import {
  CellComponentEmployees,
  EmployeeTableActionBtnData,
  employeeNavLinkList,
} from "./EmployeeTableContent";
import { fetchAllEmployeesAction } from "../../../redux/employee/employeeAction";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useQueryModifier } from "../../../hooks/useQueryModifier";
import { FilterTab } from "./Components/FilterTab";
import { SortTab } from "./Components/SortTab";
import { usePermission } from "../../../hooks/usePermission";
import { useLanguageName } from "../../../hooks/useLanguageName";
import { useNavigate } from "react-router-dom";

export const Employees: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Notify = useNotify();

  const { message, isLoading, data } = useAppSelector(
    (state) => state?.employees?.employees
  );

  const { query, resetQueryParams } = useQueryModifier();

  useEffect(() => {
    dispatch(fetchAllEmployeesAction(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    Object.keys(message)?.map((el: any) =>
      Notify?.Error({ message: message?.[el] })
    );
    Object.keys(message)?.length && resetQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const saveBtnLanguage = useLanguageName(
    "corporate.settings.employees.index.add_employee"
  );

  return (
    <div>
      <Navlink
        path="corporate.expenses.show.employee"
        routes={employeeNavLinkList}
      >
        {usePermission("corporate.settings.employee.create") && (
          <Button
            form="employee-form"
            type="submit"
            styles="employee-add-btn"
            labelStyles="text-sm"
            label={saveBtnLanguage}
            icon={null}
            onClick={() => navigate("/settings/add-employee")}
          />
        )}
      </Navlink>
      <div className="table-container w-full">
        <Table
          cellComponent={CellComponentEmployees}
          filterHeader={{
            filter: <FilterTab />,
            sort: <SortTab />,
            control: (
              <ActionButton
                label={useLanguageName("corporate.crud.export")}
                styles="employee-export-btn"
                data={EmployeeTableActionBtnData()}
              />
            ),
          }}
          data={data?.Employees}
          rowLink="/settings/show-employee/"
          isLoading={isLoading}
          is_pagination={true}
          pagination={{
            paginateOptions: {
              currentPage: data?.pagination?.current_page, // current page number
              totalCount: data?.pagination?.total, // total count of records
              pageSize: data?.pagination?.per_page, // total records per page
            },
          }}
        />
      </div>
    </div>
  );
};
