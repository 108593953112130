import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.css";
import { useLanguageName } from "../../hooks/useLanguageName";

interface NavLinksTypes {
  path: string;
  routes?: Array<Record<string, string>>;
  header?: string;
  children?: any;
  isBackNavigate?: boolean;
}

export const Navlink: FC<NavLinksTypes> = ({
  path,
  routes,
  header,
  children,
  isBackNavigate = true,
}) => {
  const navigate = useNavigate();

  return (
    <div className=" navlinks-container">
      <div className="navlinks-text-container ">
        <div className="navlinks-text">
          {/* Address */}
          {/* <FontAwesomeIcon className="h-3 opacity-80" icon={faChevronLeft} /> */}
          <h2 className="navlinks-path">
            {isBackNavigate && (
              <FontAwesomeIcon
                className="navlinks-path-arrow"
                icon={faChevronLeft}
                onClick={() => navigate(-1)}
              />
            )}
            {useLanguageName(path)}
          </h2>
        </div>
        <div className="navlinks-header">
          {!routes?.length && <p className="navlinks-header-text">{header}</p>}
          {/* Routes */}
          {routes?.map((link, idx) => (
            <div key={link?.path}>
              {idx !== routes.length - 1 ? (
                <>
                  <NavLink className="navlink" to={link?.path}>
                    {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useLanguageName(link?.name)
                    }
                  </NavLink>
                  <FontAwesomeIcon
                    className="text-xs rtl:rotate-180"
                    icon={faGreaterThan}
                  />
                </>
              ) : (
                <>
                  <p className="navlink-disable">
                    {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useLanguageName(link?.name)
                    }
                  </p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-3 w-full md:w-auto">{children}</div>
    </div>
  );
};
