import { Routes, Route } from "react-router-dom";
import {
  authProtectedRoutes,
  publicRoutes,
  unAuthProtectedRoutes,
} from "./allRoutes";
import { AuthProtected } from "./AuthProtected";
import NonAuthLayout from "../layouts/NonAuthLayout";
import { LoadingLayout } from "../Components";
import { useAppSelector } from "../redux/store";

export const Routs = () => {
  const isLanguage = useAppSelector(
    (state) => state.general?.languages?.data?.ConstantsList
  );
  return (
    <LoadingLayout
      isLoading={!isLanguage}
      boxStyle=" w-full h-screen flex-center"
    >
      <Routes>
        <Route>
          {unAuthProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={route.component}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected isAlow={route?.isAlow}>
                  {route.component}
                </AuthProtected>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </LoadingLayout>
  );
};
