import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { RowTypes } from "../TableTypes";
import "./style.css";

export const Row: FC<RowTypes> = ({ children, id, rowLink, lastRow }) => {
  const navigate = useNavigate();

  return rowLink ? (
    <tr
      className={`custom-table-row ${!lastRow && "border-b"}`}
      onClick={() => navigate(rowLink + id)}
    >
      {children}
    </tr>
  ) : (
    <tr className={`custom-table-row ${!lastRow && "border-b"}`}>{children}</tr>
  );
};
