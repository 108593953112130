import { FC, useState, useEffect, useCallback, useMemo } from "react";

import { OptionFilterType, filterOptionType } from "../DiscountsHeaderType";
import { useAppSelector } from "../../../../../redux/store";
import { useQueryModifier } from "../../../../../hooks/useQueryModifier";
import { Button, Selector } from "../../../../../Components";
import { useDiscountStateData } from "../../useDiscountStateData";
import {
  LanguageNameDiscount,
  useLanguageName,
} from "../../../../../hooks/useLanguageName";

export const DiscountFilterTab: FC = () => {
  const { query, addQueryParams, queryObject } = useQueryModifier();
  const { discounts } = useAppSelector((state) => state.discounts);
  const { customEnum } = useDiscountStateData();

  const initialFilterOption = useMemo(() => {
    return {
      is_active: { id: "", name: "" },
      price_type: { id: "", name: "" },
    };
  }, []);

  const [filterOption, setFilterOption] =
    useState<filterOptionType>(initialFilterOption);
  const activeOption = [
    {
      id: "",
      name: "All",
    },
    {
      id: 1,
      name: "Active",
    },
    {
      id: 0,
      name: "Disabled",
    },
  ];

  const priceTypeOption = [
    {
      id: "",
      name: "All",
    },
    ...customEnum,
  ];

  // filter role and active changes
  const handleChange = useCallback((name: string, option: OptionFilterType) => {
    setFilterOption((prev) => ({ ...prev, [name]: option }));
  }, []);

  // rest of filter options
  const resetFilter = useCallback(() => {
    setFilterOption(initialFilterOption);
    addQueryParams({ is_active: "", price_type: "" });
  }, [addQueryParams, initialFilterOption]);

  const handleApply = useCallback(() => {
    const customFilterOption = {
      is_active: filterOption?.is_active?.id.toString() || "",
      price_type: filterOption?.price_type?.id.toString() || "",
      page: 1,
    };
    addQueryParams(customFilterOption);
  }, [
    addQueryParams,
    filterOption?.is_active?.id,
    filterOption?.price_type?.id,
  ]);

  useEffect(() => {
    setFilterOption({
      price_type: priceTypeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.price_type
      )[0],
      is_active: activeOption?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.id == queryObject?.is_active
      )[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, customEnum]);
  return (
    <>
      <Selector
        label={useLanguageName("corporate.crud.available")}
        name="is_active"
        defaultOptions={filterOption?.is_active}
        options={activeOption}
        placeholder={useLanguageName("corporate.crud.available_placeholder")}
        searchPlaceholder="Search availability"
        onChange={handleChange}
      />
      <Selector
        label={LanguageNameDiscount("price_type")}
        name="price_type"
        defaultOptions={filterOption?.price_type}
        options={priceTypeOption}
        placeholder={useLanguageName(
          "corporate.settings.discounts.data.price_type_placeholder"
        )}
        searchPlaceholder="Search role"
        onChange={handleChange}
      />
      <div className="filter-drop-footer">
        <Button
          label={useLanguageName("corporate.crud.reset")}
          icon={null}
          styles="text-third-color !w-20 h-7"
          labelStyles="text-xs font-semibold "
          onClick={resetFilter}
        />
        <Button
          label={useLanguageName("corporate.crud.apply")}
          icon={null}
          styles="text-white bg-third-color !w-20 !h-7"
          labelStyles="text-xs font-semibold"
          isLoading={discounts?.isLoading}
          onClick={handleApply}
        />
      </div>
    </>
  );
};
