import {
  faFileCsv,
  faFilePdf,
  faPenToSquare,
  faPrint,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { CellName, CellStatus } from "../../../Components";
import { CellType } from "../../../Components/Table/CellType";
import { usePermission } from "../../../hooks/usePermission";
import { useNavigate } from "react-router-dom";
import { useLanguageName } from "../../../hooks/useLanguageName";

export const CellComponentExpensesTypes = [
  {
    Component: CellName,
    compProps: {
      parKey: "name",
    },
  },
  {
    Component: CellStatus,
    compProps: {},
  },
  {
    Component: CellType,
    compProps: {
      positionCss: "positionCss__",
      label: "Expenses Count",
      valueLabel: "Expenses",
      value: "expenses_count",
      customWidth: "w-[1%]",
      valueCustomStyle: "font-bold",
    },
  },
];

export const ExpensesTypesTableActionBtnData = () => {
  const exportLanguages = useLanguageName("corporate.crud.export");
  const exportCsvLanguages = useLanguageName("corporate.crud.export_csv");
  const exportPdfLanguages = useLanguageName("corporate.crud.export_pdf");
  return [
    {
      id: 0,
      name: exportLanguages,
      icon: faPrint,
      permission: true,
    },
    {
      id: 1,
      name: exportCsvLanguages,
      icon: faFileCsv,
      permission: true,
    },
    {
      id: 2,
      name: exportPdfLanguages,
      icon: faFilePdf,
      permission: true,
    },
  ];
};

export const expensesTypesNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.expenses_types.index.title",
    path: "/settings/expenses-types",
  },
];

export const showExpensesTypesNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.expenses_types.index.title",
    path: "/settings/expenses-types",
  },
  {
    name: "corporate.settings.expenses_types.show.title",
    path: "/settings/show-expense-type/:id",
  },
];

export const updateExpensesTypesNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.expenses_types.index.title",
    path: "/settings/expenses-types",
  },
  {
    name: "corporate.settings.expenses_types.update.title",
    path: "/settings/update-expense-type/:id",
  },
];

export const addExpenseTypeNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.expenses_types.index.title",
    path: "/settings/expenses-types",
  },
  {
    name: "corporate.settings.expenses_types.create.title",
    path: "/settings/add-expense-type",
  },
];

export const ActionBtnRows = ({ paths }: any) => {
  const { edit, setShowDeleteModal } = paths;
  const navigate = useNavigate();
  const editExpensesLanguages = useLanguageName(
    "corporate.settings.expenses_types.update"
  );
  const deleteExpensesLanguages = useLanguageName(
    "corporate.settings.expenses_types.delete"
  );
  return [
    {
      id: 0,
      name: editExpensesLanguages,
      icon: faPenToSquare,
      path: edit,
      permission: usePermission("corporate.settings.expenses_types.update"),
      optionClick: () => navigate(edit),
    },
    {
      id: 2,
      name: deleteExpensesLanguages,
      icon: faX,
      permission: usePermission("corporate.settings.expenses_types.destroy"),
      optionClick: () => setShowDeleteModal(true),
    },
  ];
};
