import { ChangeEvent } from "react";
import { SelectButton } from "../SelectButton";
import "./style.css";

interface TextInputTypes {
  id?: string;
  name?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  options: { itemName: string; itemPrice: string }[];
  setDisabled: (value: boolean) => void;
  setAddItem: React.Dispatch<
    React.SetStateAction<{
      key: string;
      input: string;
      quantity: string;
      rate: string;
      amount: string;
      isDisabled: boolean;
    }>
  >;
  onChange?: (
    something:
      | ChangeEvent<HTMLInputElement>
      | {
          itemName?: string;
          itemPrice?: string;
        }
  ) => void;
}

export const SelectInput = ({
  id,
  name = "textInput",
  type = "text",
  label = "Text label",
  value,
  setDisabled,
  placeholder = "Enter value",
  onChange = () => {},
  options,
}: TextInputTypes) => {
  return (
    <div>
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <div className="selectInputWithBtnContainer">
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          className="selectInputWithBtnInput"
          placeholder={placeholder}
          onChange={onChange}
        />
        <SelectButton
          options={options}
          inputValue={value ? value : ""}
          onChange={onChange}
          setDisabled={setDisabled}
        />
      </div>
    </div>
  );
};
