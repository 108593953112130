import { useAppSelector } from "../../../redux/store";

type Response = {
  isLoading: boolean;
  success: boolean;
  message: { [k: string]: any };
  data: { [k: string]: any };
};

export function useStateData() {
  const { enums, roles, currencies, orders } = useAppSelector(
    (state) => state?.list
  );

  const { languages, permission } = useAppSelector((state) => state?.general);

  const permissions = useAppSelector(
    (state) => state?.roles?.permissions?.data
  );

  const apiTax: Response = useAppSelector((state) => state?.taxes?.tax);

  const taxes = useAppSelector((state) => state?.taxes?.taxes);

  return {
    taxes,
    enums,
    roles,
    permission,
    permissions,
    apiTax,
    languages,
    currencies,
    orders,
  };
}
