import { FC, useCallback, MouseEventHandler } from "react";
import { Button, UploadImage } from "../../../../../Components";
import { useLanguageName } from "../../../../../hooks/useLanguageName";
import { updateCorporateSettingAction } from "../../../../../redux/corporate_settings/corporateSettingsAction";
import { useAppDispatch } from "../../../../../redux/store";

type ImageData = {
  [key: string]: string | File | boolean | undefined;
};

export const LogoTab: FC<any> = ({
  isEdit,
  settingsData,
  setSettingsData,
  isLoading,
}: any) => {
  const imagesData = [
    {
      id: "icon",
      title: useLanguageName("corporate.settings.corporates_settings.icon"),
      description: useLanguageName(
        "corporate.settings.corporates_settings.icon"
      ),
      size: "w-[128px] h-[128px]",
      labelSize: "",
    },
    {
      id: "logo",
      title: useLanguageName("corporate.settings.corporates_settings.logo"),
      description: useLanguageName(
        "corporate.settings.corporates_settings.logo_description"
      ),
      size: "w-[225px] h-[225px]",
      labelSize: " 225px x 225px, JPEG/PNG",
    },
    {
      id: "statement_logo",
      title: useLanguageName(
        "corporate.settings.corporates_settings.statement_logo"
      ),
      description: useLanguageName(
        "corporate.settings.corporates_settings.statement_logo_description"
      ),
      size: "w-[450px] h-[225px]",
      labelSize: "450px x 225px, JPEG/PNG",
    },
  ];

  const imagesButtons = {
    upload: useLanguageName("corporate.settings.corporates_settings.upload"),
    remove: useLanguageName("corporate.settings.corporates_settings.remove"),
  };

  const dispatch = useAppDispatch();
  const submitLogo: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      const { id, name } = event.currentTarget;
      if (name === "remove") {
        dispatch(updateCorporateSettingAction({ [id]: true }));
      }

      if (settingsData?.[id]) {
        if (name === "submit") {
          dispatch(updateCorporateSettingAction({ [id]: settingsData?.[id] }));
          const newData = { ...settingsData };
          delete newData?.id;
          setSettingsData(newData);
        }
      }
    },
    [dispatch, setSettingsData, settingsData]
  );

  const handleUploadImageChange = (name: string, value: File | undefined) => {
    setSettingsData((prev: ImageData) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      {imagesData?.map((item, idx) => (
        <div key={item?.id}>
          <h2 className="text-base font-bold">{item?.title}</h2>
          <p className="text-sm	font-normal">{item?.description}</p>
          <div className="flex items-start flex-col mt-5 w-full">
            <UploadImage
              image={settingsData?.[item?.id]}
              name={item?.id}
              isAvatar={false}
              styles={item?.size}
              insideLabel=""
              onChange={handleUploadImageChange}
            />
            <span className="text-xs font-normal opacity-50 mt-2.5">
              {item?.labelSize}
            </span>
          </div>
          {isEdit && (
            <div className="flex justify-end gap-5 w-full">
              <Button
                id={`${item?.id}_remove`}
                name="remove"
                label={imagesButtons?.remove}
                icon={null}
                styles="text-sm	font-semibold	bg-input-color !w-[107px] !h-[39px]"
                onClick={submitLogo}
                isLoading={!!settingsData?.[item.id] && isLoading}
              />
              <Button
                id={item?.id}
                name="submit"
                label={imagesButtons?.upload}
                icon={null}
                styles="text-sm	font-semibold	text-white bg-primary-color !w-[107px] !h-[39px]"
                onClick={submitLogo}
                isLoading={!!settingsData?.[item.id] && isLoading}
              />
            </div>
          )}
          {idx !== imagesData.length - 1 && (
            <div className="w-full h-0.5 bg-gray-color opacity-30 my-7" />
          )}
        </div>
      ))}
    </div>
  );
};
