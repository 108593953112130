import { FC } from "react";
import "./style.css";
interface NormalTypes {
  data: any;
  firstLine: string;
  secondLine: string;
  positionCss: string;
  firstValue: string;
  secondValue: string;
}

export const Normal: FC<NormalTypes> = ({
  data,
  firstLine = "",
  secondLine = "",
  positionCss,
  firstValue = "",
  secondValue = "",
}) => {
  // don't format
  return (
    <div className={`table-container ${positionCss}`}>
      <p
        className={`table-normal-first-text`}
        // className={`text-gray-900 dark:text-gray-200 whitespace-nowrap font-semibold  ${firstClassName}`}
      >
        {`${firstValue && data[firstValue]}  ${firstLine}`}
      </p>
      <p
        className={`table-normal-second-text`}
        // className={`text-gray-600 dark:text-gray-400 whitespace-nowrap ${secondClassName}`}
      >
        {`${secondValue && data[secondValue]}  ${secondLine}`}
      </p>
    </div>
  );
};
