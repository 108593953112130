import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createShippAction,
  deleteShippAction,
  fetchAllShippingAction,
  getShippAction,
  updateShippAction,
} from "./shippAction";
// You should import the correct type for UserData

interface InitialStateType {
  // data: Record<string, Array<{ key: string; value: any }> | string |undefined>;
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Record<string, string>;
}

interface GeneralState {
  shippings: InitialStateType;
  shipping: InitialStateType;
  shippingCreated: InitialStateType;
  shippingDeleted: InitialStateType;
}

const initialStateStructure = {
  data: {},
  isLoading: true,
  success: true,
  message: {},
};

const initialState: GeneralState = {
  shippings: initialStateStructure,
  shipping: initialStateStructure,
  shippingCreated: initialStateStructure,
  shippingDeleted: { ...initialStateStructure, success: false },
};

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET/ALL SHIPPING ACTION
      .addCase(fetchAllShippingAction.pending, (state) => {
        state.shippings.isLoading = true;
      })
      .addCase(
        fetchAllShippingAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.shippings = action.payload;
          state.shippings.isLoading = false;
        }
      )
      .addCase(
        fetchAllShippingAction.rejected,
        (state, action: PayloadAction<any>) => {
          // state.shippings.isLoading = false;
          state.shippings.success = action.payload?.success;
          state.shippings.message = action.payload?.message;
        }
      )
      // GET/A SHIPPING ACTION
      .addCase(getShippAction.pending, (state) => {
        state.shipping.isLoading = true;
      })
      .addCase(
        getShippAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.shipping.isLoading = false;
          state.shipping = action.payload;
        }
      )
      .addCase(getShippAction.rejected, (state, action: PayloadAction<any>) => {
        // state.shipping.isLoading = false;
        state.shipping.success = action.payload?.success;
        state.shipping.message = action.payload?.message;
      })
      // POST/CREATE SHIPPING ACTION
      .addCase(createShippAction.pending, (state) => {
        state.shippingCreated.isLoading = true;
      })
      .addCase(
        createShippAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.shippingCreated.isLoading = false;
          state.shippingCreated = action.payload;
        }
      )
      .addCase(
        createShippAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.shippingCreated.isLoading = false;
          state.shippingCreated = action.payload;
        }
      )
      // PUT/UPDATE SHIPPING ACTION
      .addCase(updateShippAction.pending, (state) => {
        state.shipping.isLoading = true;
      })
      .addCase(
        updateShippAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.shipping.isLoading = false;
          state.shipping = action.payload;
        }
      )
      .addCase(
        updateShippAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.shipping.isLoading = false;
          state.shipping.success = action.payload?.success;
          state.shipping.message = action.payload?.message;
        }
      )
      // DELETE SHIPPING ACTION
      .addCase(deleteShippAction.pending, (state) => {
        state.shippingDeleted.success = false;
        state.shippingDeleted.isLoading = true;
      })
      .addCase(
        deleteShippAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.shippingDeleted.isLoading = false;
          state.shippingDeleted = action.payload;
        }
      )
      .addCase(
        deleteShippAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.shippingDeleted.isLoading = false;
          state.shippingDeleted.success = action.payload?.success;
          state.shippingDeleted.message = action.payload?.message;
        }
      );
  },
});

export default shippingSlice.reducer;
