import { FC } from "react";
import "./style.css";

interface ImagedNameTypes {
  customStyle?: string;
  valueCustomStyle?: string;
  label: string;
  valueLabel?: string;
  value: string;
  data: any;
}

export const CellType: FC<ImagedNameTypes> = ({
  customStyle,
  valueCustomStyle,
  label,
  valueLabel,
  value,
  data,
}) => {
  // don't format
  return (
    <div className={`py-5 text-sm ${customStyle}`}>
      <div className="cell-type-box">
        <p className="cell-type-label">{label}</p>
        <div className={`cell-type-value-box ${valueCustomStyle}`}>
          <span>{data?.[value]}</span>
          <p>{valueLabel}</p>
        </div>
      </div>
    </div>
  );
};
