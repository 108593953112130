import { FC } from "react";
import {
  CellComponent,
  InvoicesData,
  // PaginationChangesType,
  Table,
} from "../../Components";

export const Invoices: FC = () => {
  // const paginationChanges = (data: PaginationChangesType) => {
  //   // console.log("🚀 ~ file: index.tsx:10 ~ paginationChanges ~ data:", data);
  // };
  return (
    <div>
      <Table
        titles={["Invoices"]}
        cellComponent={CellComponent}
        data={InvoicesData}
        pagination={{
          paginateOptions: {
            currentPage: 1, // current page number
            totalCount: 100, // total count of records
            pageSize: 10, // total records per page
          },
          select: {
            options: [10, 20, 30, 50, 100], // options for selecting
          },
          // paginationChanges: paginationChanges, // on pagination changes
        }}
      />
    </div>
  );
};
