import {FC} from 'react';
import './style.css';

interface AmountTypes {
  customStyle?: string;
  label: string;

  data: Record<string, string>;
}

export const CellAmount: FC<AmountTypes> = ({
  customStyle,
  label,

  data,
}) => {
  return (
    <div className={`py-5 text-sm ${customStyle}`}>
      <div className="cell-name-box">
        <p className="cell-name-role-name">{label}</p>
        <div className="cell-name-role">{data?.amount}</div>
      </div>
    </div>
  );
};
