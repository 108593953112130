import { Link, useLocation } from "react-router-dom";
import "./style.css";
interface SubmenuTypes {
  children?: Array<MenuChild>;
}
type MenuChild = {
  path: string;
  label: string;
};
export const Submenu = ({ children }: SubmenuTypes) => {
  const { pathname } = useLocation();
  return (
    <ul>
      {children?.map(({ label, path }) => (
        <li
          className={`submenu-item
            ${pathname === path && "bg-main-color text-white"}`}
          key={label}
        >
          <Link to={path}>{label}</Link>
        </li>
      ))}
    </ul>
  );
};
