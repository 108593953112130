import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteModal } from "../../../../../Components";
import { useAppDispatch } from "../../../../../redux/store";
import { useStateData } from "../../useStateData";
import { deleteTaxAction } from "../../../../../redux/tax/taxAction";
import { resetTax } from "../../../../../redux/tax/taxesSlice";
import "./style.css";

type DeleteTaxModalType = {
  showModal: boolean;
  setShowModal: Function;
};
export const DeleteTaxModal = ({
  showModal,
  setShowModal,
}: DeleteTaxModalType) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: taxId } = useParams();
  const { apiTax } = useStateData();

  const handleSaveModal = () => {
    dispatch(deleteTaxAction(taxId));
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (apiTax.success) {
      dispatch(resetTax());
      navigate("/settings/taxes");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiTax.success]);

  return (
    <DeleteModal
      showModal={showModal}
      handleModalClose={handleModalClose}
      handleDelete={handleSaveModal}
      deleteLoading={apiTax?.isLoading}
      keys={{
        description: "corporate.settings.taxes.show.delete.description",
      }}
    />
  );
};
