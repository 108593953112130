import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { isAxiosError } from "axios";
import * as url from "../../helpers/url_helper";
import { useNotify } from "../../Components";

interface ItemArguments {
  [key: string]: any;
}
const api = new APIClient();

// GET/ALL ITEMS ACTION
export const fetchAllItemsAction = createAsyncThunk(
  "items/getAllItems",
  async (query: String, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`${url.GIT_ALL_ITEMS}${query}`);

      const newData = { ...data };

      newData.data.ItemCollection.Items.forEach((item: any) => {
        item.Currency = item.Currency.name;
        item.Units = item.Units.name;
      });
      const customData = {
        ...data,
        data: data?.data?.ItemCollection,
      };

      return customData;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        return rejectWithValue(errorData);
      }
    }
  }
);

// GET/A ITEMS ACTION
export const fetchItemsAction = createAsyncThunk(
  "items/getItem",
  async (query: string | undefined, { rejectWithValue }) => {
    const Notify = useNotify();

    try {
      const { data } = await api.get(`${url.GIT_A_ITEMS}/${query}`);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// POST/CREATE ITEMS ACTION
export const createItemsAction = createAsyncThunk(
  "items/createItem",
  async (itemData: ItemArguments, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.create(url.POST_ITEMS, itemData);
      Notify.Success({ message: data?.message?.success });

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// PUT/UPDATE ITEM ACTION
export const updateItemsAction = createAsyncThunk(
  "items/updateItem",
  async ({ item, id }: ItemArguments, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const customData = { ...item, _method: "PUT" };

      const { data } = await api.create(`${url.PUT_A_ITEMS}/${id}`, customData);
      Notify.Success({ message: data?.message?.success });

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);

// DELETE ITEM ACTION
export const deleteItemAction = createAsyncThunk(
  "item/deleteItem",
  async ({ id, cb }: Record<string, any>, { rejectWithValue }) => {
    const Notify = useNotify();
    try {
      const { data } = await api.delete(`${url.DELETE_A_ITEMS}/${id}`);
      cb();
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({ message: errorData?.message?.error });
        return rejectWithValue(errorData);
      }
    }
  }
);
