import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "../../../../Components";
import { CellComponent, data, titles } from "../../MembershipData";

export const MembershipPayments = () => {
  return (
    <div className="membership-payments-card">
      <div className="membership-payments-danger-message">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="membership-payments-danger-icon "
        />

        <div className="membership-payments-danger-text-container">
          <p className="membership-payments-danger-title">Payment Required !</p>
          <p className="membership-payments-danger-sub-title">
            You have until 30 February 2023 to confirm payment, then your
            account will suspend
          </p>
        </div>
      </div>
      <div className="mt-5">
        <p className="membership-titles">Membership Payments</p>
        <p className="membership-sub-titles">
          History of your membership payments
        </p>
      </div>
      <div className="membership-table-container">
        <Table
          titles={titles}
          cellComponent={CellComponent}
          data={data}
          tableCss="membership-table-css"
        />
      </div>
    </div>
  );
};
