import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Navlink,
  Selector,
  TextInput,
} from "../../../../Components";
import { object } from "../../../../utils/ValidateErrors";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import {
  getShippAction,
  updateShippAction,
} from "../../../../redux/shipp/shippAction";
import { useStateData } from "../useStateData";
import { fetchCurrencyListAction } from "../../../../redux/list/listAction";
import { updateShippNavLinkList } from "../ShippingTableContent";
import {
  LanguageNameShipp,
  useLanguageName,
} from "../../../../hooks/useLanguageName";

type ShippData = {
  id?: string;
  name: string;
  currency_id: string;
  amount: string;
  is_active?: number;
};
type Option = {
  id: number | string;
  name: string;
};
export const UpdateShipp: FC = () => {
  const { id: shipId } = useParams();
  const navigate = useNavigate();
  const { currencies } = useStateData();

  const {
    data: { Shipping },
  } = useAppSelector((state) => state?.shipping?.shipping);

  const [shippData, setShippData] = useState<ShippData>({
    name: "",
    currency_id: "",
    amount: "",
    is_active: 0,
  });

  const [errors, setErrors] = useState({
    name: "",
    currency_id: "",
    amount: "",
    is_active: "",
  });

  const flag = useRef(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setShippData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSelectChange = (name: string, value: Option) => {
    setShippData((prev) => ({
      ...prev,
      [name]: value.id,
    }));
  };
  const validate = useCallback(() => {
    const shippSchema = object({
      name: ["required"],
      currency_id: ["required"],
      amount: ["required"],
    });
    const result = shippSchema.validate(shippData);
    setErrors(result.errors);
    return result.valid;
  }, [shippData]);

  const dispatch = useAppDispatch();

  const saveShipp = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(updateShippAction(shippData)).then(({ payload }) => {
          payload?.success
            ? navigate(`/settings/shipping/${shipId}`)
            : setErrors(payload?.message);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  useEffect(() => {
    dispatch(fetchCurrencyListAction());
  }, [dispatch]);

  const { id: shippId } = useParams();

  useEffect(() => {
    dispatch(getShippAction(shippId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Shipping) {
      setShippData(Shipping);
    }
  }, [Shipping]);
  return (
    <div>
      <Navlink
        path="corporate.settings.shipping.update.title"
        routes={updateShippNavLinkList}
      >
        <Button
          form="shipp-form"
          type="submit"
          styles="shipping-update-btn"
          label={useLanguageName("corporate.settings.discounts.create.save")}
          icon={null}
          // isLoading={apiShipp?.isLoading}
          // disabled={apiShipp?.isLoading}
        />
      </Navlink>
      <form
        id="shipp-form"
        onSubmit={saveShipp}
        className="shipping-form"
        noValidate
      >
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            id="shipp_name"
            name="name"
            label={LanguageNameShipp("name")}
            placeholder={LanguageNameShipp("name_placeholder")}
            onChange={handleChange}
            error={errors?.name}
            errorMsg={errors?.name}
            value={shippData?.name}
          />
          <div className="relative">
            <Selector
              id="shipp_currency"
              name="currency_id"
              label={LanguageNameShipp("currency_id")}
              options={currencies?.data}
              placeholder={LanguageNameShipp("currency_placeholder")}
              searchPlaceholder="Search currency"
              onChange={handleSelectChange}
              error={errors?.currency_id}
              errorMsg={errors?.currency_id}
              defaultOptions={Shipping?.Currency}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            id="shipp_amount"
            name="amount"
            label={LanguageNameShipp("amount")}
            placeholder={LanguageNameShipp("amount_placeholder")}
            onChange={handleChange}
            error={errors?.amount}
            errorMsg={errors?.amount}
            value={shippData?.amount}
          />
          <div className="flex items-center">
            <div className="flex items-center gap-1 col-span-2">
              <Checkbox
                id="shipp_is_active"
                name="is_active"
                afterLabel={LanguageNameShipp("is_active")}
                styles="checked:bg-main-color"
                onChange={handleChange}
                error={errors?.is_active}
                errorMsg={errors?.is_active}
                checked={!!shippData?.is_active}
              />
                <p className="shipping-is_action-description">
                  {`( ${LanguageNameShipp("is_active_description")} )`}
                </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
