import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userSlice from "./users/userSlice";
import authSlice from "./auth/authSlice";
import generalSlice from "./general/generalSlice";
import listSlice from "./list/listSlice";
import employeesSlice from "./employee/employeeSlice";
import rolesSlice from "./role/rolesSlice";
import discountSlice from "./discount/discountSlice";
import itemsSlice from "./items/itemsSlice";
import taxesSlice from "./tax/taxesSlice";
import expenseTypesSlice from "./expense_types/expenseTypesSlice";
import CorporateSettings from "./corporate_settings/corporateSettingsSlice";
import shippingSlice from './shipp/shippingSlice';

export const store = configureStore({
  reducer: {
    users: userSlice,
    auth: authSlice,
    general: generalSlice,
    list: listSlice,
    employees: employeesSlice,
    roles: rolesSlice,
    discounts: discountSlice,
    items: itemsSlice,
    taxes: taxesSlice,
    shipping: shippingSlice,
    expenses_types: expenseTypesSlice,
    corporateSettings: CorporateSettings,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// (window as any).store = store;
