import { useNavigate } from "react-router-dom";
import {
  faFileCsv,
  faFilePdf,
  faPenToSquare,
  faPrint,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { CellName, CellNameEnum, CellStatus } from "../../../Components";
import { CellType } from "../../../Components/Table/CellType";
import { usePermission } from "../../../hooks/usePermission";
import { useLanguageName } from "../../../hooks/useLanguageName";

export const CellComponentTaxes = [
  {
    Component: CellName,
    compProps: {
      parKey: "name",
    },
  },
  {
    Component: CellNameEnum,
    compProps: {
      positionCss: "positionCss__",
      enumKey: "PriceType",
      label: "Type",
      value: "price_type",
    },
  },
  {
    Component: CellStatus,
    compProps: {
      customWidth: "",
    },
  },
  {
    Component: CellType,
    compProps: {
      positionCss: "positionCss__",
      label: "Tax Value",
      value: "price",
      customWidth: "w-[1%]",
    },
  },
];

export const TaxTableActionBtnData = () => {
  const exportLanguages = useLanguageName("corporate.crud.export");
  const exportCsvLanguages = useLanguageName("corporate.crud.export_csv");
  const exportPdfLanguages = useLanguageName("corporate.crud.export_pdf");
  return [
    {
      id: 0,
      name: exportLanguages,
      icon: faPrint,
      permission: true,
    },
    {
      id: 1,
      name: exportCsvLanguages,
      icon: faFileCsv,
      permission: true,
    },
    {
      id: 2,
      name: exportPdfLanguages,
      icon: faFilePdf,
      permission: true,
    },
  ];
};

export const taxNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.taxes",
    path: "/settings/taxes",
  },
];

export const addTaxNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.taxes",
    path: "/settings/taxes",
  },
  {
    name: "corporate.settings.taxes.create.title",
    path: "/settings/add-tax",
  },
];

export const showTaxNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.taxes",
    path: "/settings/taxes",
  },
  {
    name: "corporate.settings.taxes.show.title",
    path: "/settings/show-tax/:id",
  },
];

export const updateTaxNavLinkList = [
  {
    name: "corporate.dashboard",
    path: "/dashboard",
  },
  {
    name: "corporate.settings",
    path: "/settings",
  },
  {
    name: "corporate.settings.taxes",
    path: "/settings/taxes",
  },
  {
    name: "corporate.settings.taxes.update.title",
    path: "/settings/update-tax/:id",
  },
];

export const ActionBtnRows = ({ paths }: any) => {
  const { edit, setShowDeleteModal } = paths;
  const navigate = useNavigate();
  return [
    {
      id: 0,
      name: "update",
      icon: faPenToSquare,
      path: edit,
      permission: usePermission("corporate.settings.taxes.update"),
      optionClick: () => navigate(edit),
    },
    {
      id: 2,
      name: "delete",
      icon: faX,
      permission: usePermission("corporate.settings.taxes.destroy"),
      optionClick: () => setShowDeleteModal(true),
    },
  ];
};
