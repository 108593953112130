import { ChangeEvent } from "react";

interface TextAreaTypes {
  id?: string;
  name: string;
  label: string;
  cols?: number;
  rows?: number;
  placeholder: string;
  value?: string | number | readonly string[];
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = ({
  id,
  name = "textArea",
  label = "Text area label",
  placeholder = "Enter value",
  value,
  cols = 20,
  rows = 5,
  onChange = () => {},
}: TextAreaTypes) => {
  return (
    <div>
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <textarea
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        cols={cols}
        rows={rows}
        className="text-input resize-none"
        placeholder={placeholder}
      />
    </div>
  );
};
