import { FC } from "react";
import "./style.css";

interface ImagedNameTypes {
  customStyle?: string;
  data: Record<string, string>;
}

export const CellName: FC<ImagedNameTypes> = ({ customStyle, data }) => {
  return (
    <div className={`py-5 text-sm ${customStyle}`}>
      <div className="cell-name-box">
        <p className="cell-name-role-name">{data?.name}</p>
        <div className="cell-name-role">{data?.address}</div>
      </div>
    </div>
  );
};
