import { CellParagraph, CellStatusCustom, CellTimeDuration } from "../../Components";

export const MembershipSummaryData = [
  { title: "Plan Name", info: "Golden Subscription" },
  { title: "Billing Days", info: "Every 30 Days" },
  { title: "Price", info: "150 USD" },
  { title: "Status", info: "Active" },
  { title: "Max Employees", info: "8 Employees" },
  { title: "Next Bill", info: "25 February 2023" },
];

export const titles = ["Payment Date", "Duration", "Amount", "Status"];
export const data = [
  {
    paymentDate: "25 February 2023",
    From: "25 February",
    To: "25 March",
    Amount: "150 USD",
    status: "Unpaid",
  },
  {
    paymentDate: "25 February 2023",
    From: "25 February",
    To: "25 March",
    Amount: "150 USD",
    status: "Paid",
  },
  {
    paymentDate: "25 February 2023",
    From: "25 February",
    To: "25 March",
    Amount: "150 USD",
    status: "Paid",
  },
];
export const CellComponent = [
  {
    Component: CellParagraph,
    compProps: {
      customWidth: "ltr:pr-5 rtl:pl-5 whitespace-nowrap",
      parKey: "paymentDate",
    },
  },
  {
    Component: CellTimeDuration,
    compProps: {
      customWidth: "ltr:pr-5 rtl:pl-5",
      positionCss: "py-6",
      firstPreposition: "From",
      firstValue: "From",
      secondPreposition: "To",
      secondValue: "To",
    },
  },
  {
    Component: CellParagraph,
    compProps: {
      customWidth: "ltr:pr-8 rtl:pl-8 whitespace-nowrap",
      parKey: "Amount",
    },
  },
  {
    Component: CellStatusCustom,
    compProps: {
      active: "Paid",
      notActive: "Unpaid",
      customWidth: "w-[1%] ",
    },
  },
];
