import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteModal } from "../../../../../Components";
import { useAppDispatch } from "../../../../../redux/store";
import { deleteRoleAction } from "../../../../../redux/role/roleAction";
import { useStateData } from "../../useStateData";
import { resetRole } from "../../../../../redux/role/rolesSlice";
import "./style.css";

type DeleteRoleModalType = {
  showModal: boolean;
  setShowModal: Function;
};
export const DeleteRoleModal = ({
  showModal,
  setShowModal,
}: DeleteRoleModalType) => {
  const { id: roleId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { apiRole } = useStateData();

  const handleSaveModal = () => {
    dispatch(deleteRoleAction(roleId));
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (apiRole.success) {
      dispatch(resetRole());
      navigate("/settings/roles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRole.success]);

  return (
    <DeleteModal
      showModal={showModal}
      handleModalClose={handleModalClose}
      handleDelete={handleSaveModal}
      deleteLoading={apiRole?.isLoading}
      keys={{
        description: "corporate.settings.roles.show.delete.description",
      }}
    />
  );
};
