import { useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionButton,
  Button,
  Navlink,
  Table,
  useNotify,
} from "../../../Components";
import {
  CellComponentRoles,
  RoleTableActionBtnData,
  roleNavLinkList,
} from "./RoleTableContent";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useQueryModifier } from "../../../hooks/useQueryModifier";
import { FilterTab } from "./Components/FilterTab";
import { SortTab } from "./Components/SortTab";
import { fetchAllRolesAction } from "../../../redux/role/roleAction";
import { useStateData } from "./useStateData";
import "./style.css";

export const Roles: FC = () => {
  const dispatch = useAppDispatch();
  const Notify = useNotify();
  const { message, isLoading, data } = useAppSelector(
    (state) => state?.roles?.roles
  );
  const { languages, permission } = useStateData();

  const { query, resetQueryParams } = useQueryModifier();

  useEffect(() => {
    dispatch(fetchAllRolesAction(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    Object.keys(message)?.map((el: any) =>
      Notify?.Error({ message: message?.[el] })
    );
    Object.keys(message)?.length && resetQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const navigate = useNavigate();

  return (
    <div>
      <Navlink path="corporate.settings.roles" routes={roleNavLinkList}>
        {permission.data?.includes("corporate.settings.roles.create") && (
          <Button
            type="button"
            styles="roles-index-action-btn"
            labelStyles="text-sm"
            label={
              languages?.data?.ConstantsList?.[
                "corporate.settings.roles.create"
              ]
            }
            icon={null}
            onClick={() => navigate("/settings/add-role")}
          />
        )}
      </Navlink>
      <div className="table-container pt-0 w-full">
        <Table
          cellComponent={CellComponentRoles}
          filterHeader={{
            filter: <FilterTab />,
            sort: <SortTab />,
            control: (
              <ActionButton
                label="Export"
                styles="roles-export-btn"
                data={RoleTableActionBtnData()}
              />
            ),
          }}
          data={data?.Roles}
          rowLink="/settings/show-role/"
          isLoading={isLoading}
          is_pagination={true}
          pagination={{
            paginateOptions: {
              currentPage: data?.pagination?.current_page,
              totalCount: data?.pagination?.total,
              pageSize: data?.pagination?.per_page,
            },
          }}
        />
      </div>
    </div>
  );
};
