import {
  faBoxesStacked,
  faPercent,
  faTruckFast,
  faTag,
  faUser,
  faClipboard,
  faFileInvoiceDollar,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";

export const Items = (permission: Array<string>, languages: any) => {
  const { ConstantsList } = languages?.data || {};

  return [
    {
      id: 1,
      title: ConstantsList["corporate.settings.items"],
      desc: ConstantsList["corporate.settings.items.description"],
      path: "/settings/items",
      icon: faBoxesStacked,
      visible: permission?.includes("corporate.settings.items") || false,
    },
    {
      id: 2,
      title: ConstantsList["corporate.settings.discounts"],
      desc: ConstantsList["corporate.settings.discounts.description"],
      path: "/settings/discounts",
      icon: faPercent,
      visible: permission?.includes("corporate.settings.discounts") || false,
    },
    {
      id: 3,
      title: ConstantsList["corporate.settings.shipping"],
      desc: ConstantsList["corporate.settings.shipping.description"],
      path: "/settings/shipping",
      icon: faTruckFast,
      visible: permission?.includes("corporate.settings.shipping") || false,
    },
    {
      id: 4,
      title: ConstantsList["corporate.settings.taxes"],
      desc: ConstantsList["corporate.settings.taxes.description"],
      path: "/settings/taxes",
      icon: faTag,
      visible: permission?.includes("corporate.settings.taxes") || false,
    },
    {
      id: 5,
      title: ConstantsList["corporate.settings.employees"],
      desc: ConstantsList["corporate.settings.employees.description"],
      path: "/settings/employees",
      icon: faUser,
      visible: permission?.includes("corporate.settings.employee") || false,
    },
    {
      id: 6,
      title: ConstantsList["corporate.settings.roles"],
      desc: ConstantsList["corporate.settings.roles.description"],
      path: "/settings/roles",
      icon: faClipboard,
      visible: permission?.includes("corporate.settings.roles") || false,
    },
    {
      id: 7,
      title: ConstantsList["corporate.settings.expenses_types"],
      desc: ConstantsList["corporate.settings.expenses_types.description"],
      path: "/settings/expenses-types",
      icon: faFileInvoiceDollar,
      visible:
        permission?.includes("corporate.settings.expenses_types") || false,
    },
    {
      id: 8,
      title: ConstantsList["corporate.settings.corporates_settings"],
      desc: ConstantsList["corporate.settings.corporates_settings.description"],
      path: "/settings/corporate",
      icon: faWandMagicSparkles,
      visible:
        permission?.includes("corporate.settings.corporate_settings") || false,
    },
  ];
};
