import "./style.css";
export const RemainingBalance = ({
  data,
  positionCss,
}: {
  data: any;
  positionCss: string;
}) => {
  return (
    <div className={`remainingBalance-container  ${positionCss}`}>
      <p
        className={`${
          data?.remainingBalance !== "0" ? "text-gray-900" : "text-gray-400"
        } whitespace-nowrap font-bold`}
      >
        {data?.remainingBalance + " USD"}
      </p>
      <p
        className={`${
          data?.remainingBalance !== "0" ? "text-gray-600" : "text-gray-300"
        } whitespace-nowrap`}
      >
        Remaining Balance
      </p>
    </div>
  );
};
