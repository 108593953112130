import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Selector, TextInput, UploadImage } from "../../Components";

export const Vendors = () => {
  return (
    <div className="grid grid-cols py-8 pr-8 gap-8 w-full ">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col justify-start gap-1">
          <div className="flex flex-row justify-start items-center gap-4">
            <FontAwesomeIcon icon={faChevronLeft} />
            <h1 className="text-xl font-medium text-primary-background-color opacity-80">
              Add Vendor
            </h1>
          </div>
          <div className="flex flex-row gap-4 ">
            <div className="flex flex-row justify-start items-center gap-4">
              <span className="text-base font-light text-primary-background-color opacity-70">
                dashboard
              </span>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="flex flex-row justify-start items-center gap-4">
              <span className="text-base font-light text-primary-background-color opacity-70">
                Vendors
              </span>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>

            <div className="flex flex-row justify-start items-center gap-4">
              <span className="text-base font-light text-primary-background-color opacity-70">
                Add Vendor
              </span>
            </div>
          </div>
        </div>
        <Button
          label="Save"
          // bgColor="bg-primary-background-color"
          // padding="py-2.5 px-12 "
          // textColor="text-white"
        />
      </div>

      <div className="grid grid-cols p-8 bg-white rounded-second-radius w-full drop-shadow-md">
        <div className="grid grid-rows-11  gap-8">
          <div className="row-span-1">
            <p className="text-lg font-extrabold ">Basic Information</p>
          </div>
          <div className="row-span-3 grid grid-cols-3 gap-8">
            <div className="col-span-2 grid grid-rows-3 gap-8">
              <TextInput
                label="Name"
                placeholder="Enter invoice title"
                onChange={() => {}}
              />
              <TextInput
                label="Name"
                placeholder="Enter invoice title"
                onChange={() => {}}
              />
              <TextInput
                label="Email Address"
                type="Email"
                placeholder="Enter invoice title"
                onChange={() => {}}
              />
            </div>
            <div className="col-span-1">
              <UploadImage
                isAvatar={false}
                image={""}
                onChange={function (event: string | ArrayBuffer | null): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </div>
          </div>
          <div className="row-span-1 grid grid-cols-2 gap-8 ">
            <div className="col-span-1">
              <TextInput
                label="Business Name"
                placeholder="Enter invoice title"
                onChange={() => {}}
              />
            </div>
            <div className="col-span-1">
              {/* <TextInputWithTag label="Website URL" tag="https://" /> */}
            </div>
          </div>
          <div className="row-span-1 grid grid-cols-2 gap-8">
            <Selector
              label="Language"
              // options={[""]}
              placeholder="Choose language"
            />

            <Selector
              label="Currency"
              // options={[""]}
              placeholder="Choose currency"
            />
          </div>
          <div className="row-span-1">
            <p className="text-base font-semibold ">Address</p>
          </div>
          <div className="row-span-1 grid grid-cols-2 gap-8">
            <Selector
              label="Country"
              // options={[""]}
              placeholder="Choose Country"
            />

            {/* <Selector label="City" options={[""]} placeholder="Choose City" /> */}
          </div>
          <div className="row-span-1 grid grid-cols-2 gap-8">
            <TextInput
              label="Business Name"
              placeholder="Enter invoice title"
              onChange={() => {}}
            />
            <TextInput
              label="Business Name"
              placeholder="Enter invoice title"
              onChange={() => {}}
            />
          </div>
          <div className="row-span-1">
            <p className="text-base font-semibold">Additional Information</p>
          </div>
          <div className="row-span-1 grid grid-cols-3 gap-8">
            <TextInput
              label="Telephone Number"
              placeholder="Enter your phone number"
              onChange={() => {}}
            />
            <TextInput
              label="Mobile Number"
              placeholder="Enter your mobile number"
              onChange={() => {}}
            />
            <TextInput
              label="Tax Id"
              placeholder="Enter your tax id"
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
