import { FC } from "react";
import { DeleteModal, useNotify } from "../../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { discountModalType } from "../../DiscountTypes";
import { deleteDiscountAction } from "../../../../../redux/discount/discountAction";

export const DiscountDeleteModal: FC<discountModalType> = ({
  showModal,
  handleModalClose = () => {},
}) => {
  const { id: discountId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Notify = useNotify();

  const { message } = useAppSelector(
    (state) => state?.discounts?.discountDeleted
  );

  const handleDelete = () => {
    dispatch(
      deleteDiscountAction({
        id: `${discountId}`,
        cb: () => {
          Notify.Success({ message: message?.success });
          handleModalClose();
          navigate("/settings/discounts");
        },
      })
    );
  };

  return (
    <DeleteModal
      showModal={showModal}
      handleModalClose={handleModalClose}
      handleDelete={handleDelete}
      keys={{
        description: "corporate.settings.discounts.show.delete.description",
      }}
    />
  );
};
