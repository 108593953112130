import { FC } from "react";
import { DeleteModal, useNotify } from "../../../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { deleteEmployeesAction } from "../../../../../../redux/employee/employeeAction";
import { useNavigate, useParams } from "react-router-dom";
import { employeeModalType } from "../../EmployeeHeaderType";
import "../style.css";

export const EmployeeDeleteModal: FC<employeeModalType> = ({
  showModal,
  handleModalClose = () => {},
}) => {
  const { id: employeeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const Notify = useNotify();

  const { message }: any = useAppSelector(
    (state) => state.employees?.employeeDeleted
  );

  const handleDelete = () => {
    dispatch(
      deleteEmployeesAction({
        id: employeeId,
        cb: () => {
          Notify.Success({ message: message?.success });
          navigate("/settings/employees");
        },
      })
    );
  };

  const handleCloseDeleteModal = () => {
    handleModalClose("delete", false);
  };

  return (
    <DeleteModal
      showModal={showModal?.delete}
      handleModalClose={handleCloseDeleteModal}
      handleDelete={handleDelete}
    />
  );
};
