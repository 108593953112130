import { FC } from "react";
import "./style.css";

interface StatusTypes {
  data: Record<string, string>;
  customStyle?: string;
}

export const CellStatus: FC<StatusTypes> = ({ data, customStyle }) => {
  return (
    <div className={`table-status-container ${customStyle}`}>
      {data?.is_active ? (
        <div aria-hidden className={`table-status-success`}>
          Active
        </div>
      ) : (
        <div aria-hidden className={`table-status-failure`}>
          Inactive
        </div>
      )}
    </div>
  );
};
