import { FC } from "react";
import { CustomLoading } from "../../Components";
import "./style.css";

export const Loader: FC = () => {
  return (
    <div className="loading-page-container">
      <div className="w-12 h-12">
        <CustomLoading />
      </div>
    </div>
  );
};
