import { NotifyMessage } from "./NotifyMessage";
import {
  NotifyMessageBlock,
  NotifyDataType,
  NotifyContainerProps,
} from "./NotifyTypes";

export const NotifyContainer = ({ values }: NotifyContainerProps) => {
  const handleRemove = (id: string, container: string) => {
    values?.remove(id, container);
  };

  return Object?.keys(values?.data as NotifyDataType).map(
    (notifyName: string) => (
      <div key={notifyName} className={`${notifyName} notify-container`}>
        {values?.data[notifyName as keyof NotifyDataType]?.map(
          (toast: NotifyMessageBlock) => {
            return (
              <div key={toast.id} className={`notify-container-box`}>
                <NotifyMessage
                  id={toast.id}
                  message={toast.message}
                  options={toast.options}
                  onRemove={(val) => handleRemove(val, notifyName)}
                />
              </div>
            );
          }
        )}
      </div>
    )
  );
};
