import React from "react";
import { MembershipSummaryData } from "../../MembershipData";
import { Button, CellStatus } from "../../../../Components";

export const MembershipSummary = () => {
  return (
    <div className=" membership-summary-card">
      <div>
        <p className="membership-titles">Membership Summary</p>
        <p className="membership-sub-titles">Your membership details summary</p>
      </div>
      <div className="py-8">
        {MembershipSummaryData?.map((item, index) => (
          <div key={index}>
            <div className="center">
              <p className="font-bold opacity-[89]">{item.title}</p>
              {item.title === "Status" ? (
                <CellStatus data={{ is_active: "" }} />
              ) : (
                <p className={`opacity-70`}>{item?.info}</p>
              )}
            </div>
            {MembershipSummaryData?.length - 1 !== index && (
              <div className="w-full py-[30px]">
                <hr className="divider" />
              </div>
            )}
          </div>
        ))}
      </div>
      <Button
        styles="membership-change-sub-plan-btn"
        label="Change Subscription Plan"
        icon={null}
      />
      <p className="cancel-membership cancel-membership-hover-effect">
        Cancel Subscription
      </p>
    </div>
  );
};
