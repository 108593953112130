import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createItemsAction,
  deleteItemAction,
  fetchAllItemsAction,
  fetchItemsAction,
  updateItemsAction,
} from "./itemsAction"; // You should import the correct type for UserData

interface InitialStateType {
  // data: Record<string, Array<{ key: string; value: any }> | string |undefined>;
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Record<string, string>;
}

interface GeneralState {
  items: InitialStateType;
  item: InitialStateType;
  itemCreated: InitialStateType;
  itemDeleted: InitialStateType;
}

const initialStateStructure = {
  data: { Item: [] },
  isLoading: true,
  success: true,
  message: {},
};

const initialState: GeneralState = {
  items: initialStateStructure,
  item: initialStateStructure,
  itemCreated: initialStateStructure,
  itemDeleted: { ...initialStateStructure, success: false },
};

const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET/ALL ITEM ACTION
      .addCase(fetchAllItemsAction.pending, (state) => {
        state.items.isLoading = true;
      })
      .addCase(
        fetchAllItemsAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.items = action.payload;
          state.items.isLoading = false;
        }
      )
      .addCase(
        fetchAllItemsAction.rejected,
        (state, action: PayloadAction<any>) => {
          // state.items.isLoading = false;
          state.items.success = action.payload?.success;
          state.items.message = action.payload?.message;
        }
      )
      // GET/A ITEM ACTION
      .addCase(fetchItemsAction.pending, (state) => {
        state.item.isLoading = true;
      })
      .addCase(
        fetchItemsAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.item = action.payload;
          state.item.isLoading = false;
        }
      )
      .addCase(
        fetchItemsAction.rejected,
        (state, action: PayloadAction<any>) => {
          // state.item.isLoading = false;
          state.item.success = action.payload?.success;
          state.item.message = action.payload?.message;
        }
      )
      // POST/CREATE ITEM ACTION
      .addCase(createItemsAction.pending, (state) => {
        state.itemCreated.isLoading = true;
      })
      .addCase(
        createItemsAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.itemCreated = action.payload;
          state.itemCreated.isLoading = false;
        }
      )
      .addCase(
        createItemsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.itemCreated.isLoading = false;
          state.itemCreated = action.payload;
        }
      )
      // PUT/UPDATE ITEM ACTION
      .addCase(updateItemsAction.pending, (state) => {
        state.item.isLoading = true;
      })
      .addCase(
        updateItemsAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.item = action.payload;
          state.item.isLoading = false;
        }
      )
      .addCase(
        updateItemsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.item.isLoading = false;
          state.item.success = action.payload?.success;
          state.item.message = action.payload?.message;
        }
      )
      // DELETE ITEM ACTION
      .addCase(deleteItemAction.pending, (state) => {
        state.itemDeleted.success = false;
        state.itemDeleted.isLoading = true;
      })
      .addCase(
        deleteItemAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.itemDeleted.message = action.payload?.message;
          state.itemDeleted.success = action.payload?.success;
          state.itemDeleted.isLoading = false;
        }
      )
      .addCase(
        deleteItemAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.itemDeleted.isLoading = false;
          state.itemDeleted.success = action.payload?.success;
          state.itemDeleted.message = action.payload?.message;
        }
      );
  },
});

export default itemsSlice.reducer;
