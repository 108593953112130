import { FC, useEffect, useState } from "react";
import {
  ActionButton,
  Checkbox,
  Navlink,
  TextInput,
} from "../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useParams } from "react-router-dom";
import { DiscountActionBtnRows, showDiscount } from "../DiscountsTableContent";
import { LanguageNameDiscount } from "../../../../hooks/useLanguageName";
import { fetchDiscountAction } from "../../../../redux/discount/discountAction";
import { DiscountDeleteModal } from "../Components/DiscountDeleteModal";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const ShowDiscount: FC = () => {
  const { id: discountId } = useParams();
  const dispatch = useAppDispatch();

  const { Discount } = useAppSelector(
    (state) => state?.discounts?.discount?.data
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    dispatch(fetchDiscountAction(discountId));
  }, [dispatch, discountId]);

  return (
    <div>
      <Navlink
        path="corporate.settings.discounts.show.title"
        routes={showDiscount}
      >
        <ActionButton
          data={DiscountActionBtnRows({
            paths: {
              edit: `/settings/update-discount/${discountId}`,
              handleModalClose: () => setShowDeleteModal(true),
            },
          })}
          icon={faChevronDown}
          styles="bg-third-color text-white text-sm w-[100px] h-[41px] flex-row-reverse flex gap-2"
        />
      </Navlink>
      <div className="discount-parent-container">
        <div className="discount-first-row-grid">
          <TextInput
            id="discount_name"
            name="name"
            label={LanguageNameDiscount("name")}
            labelStyle="!text-base"
            inputBoxStyle="h-11"
            value={Discount?.name}
            disabled
          />
          <div className="relative">
            <TextInput
              id="discount_currenct"
              name="currency-id"
              label={LanguageNameDiscount("currency_id")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              value={Discount?.Currency?.name}
              disabled
            />
          </div>
        </div>
        <div className="discount-second-row-grid">
          <div className="relative md:col-span-1 col-span-2">
            <TextInput
              id="discount_price_type"
              name="price-type-id"
              label={LanguageNameDiscount("price_type")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              value={Discount?.price_type_name}
              disabled
            />
          </div>
          <div className="md:col-span-1 col-span-2 w-full">
            <TextInput
              id="discount_value"
              name="value"
              label={LanguageNameDiscount("price")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              value={Discount?.price}
              disabled
            />
          </div>
          <div className="discount-checkbox">
            <div className="discount-checkbox-flex">
              <Checkbox
                id="discount_is_active"
                name="is_active"
                afterLabel={LanguageNameDiscount("is_active")}
                styles="checked:bg-main-color"
                checked={Discount?.is_active}
                disabled
              />
              <p className="is_action-description">
                {`( ${LanguageNameDiscount("is_active_description")} )`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <DiscountDeleteModal
        handleModalClose={handleModalClose}
        showModal={showDeleteModal}
      />
    </div>
  );
};
