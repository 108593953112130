import { FC, useEffect, useState } from "react";
import {
  ActionButton,
  Checkbox,
  Navlink,
  TextInput,
} from "../../../../Components";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useParams } from "react-router-dom";
import { getShippAction } from "../../../../redux/shipp/shippAction";
import { ActionBtnRows, showShippNavLinkList } from "../ShippingTableContent";
import { LanguageNameShipp } from "../../../../hooks/useLanguageName";
import { DeleteShippModal } from "../Components/DeleteShippModal";

export const ShowShipp: FC = () => {
  // const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    shipping: {
      data: { Shipping },
    },
  } = useAppSelector((state) => state?.shipping);

  const dispatch = useAppDispatch();
  const { id: shippId } = useParams();

  const [showModal, setShowModal] = useState({
    delete: false,
    is_suspended: false,
    is_banned: false,
  });

  useEffect(() => {
    dispatch(getShippAction(shippId));
  }, [dispatch, shippId]);

  const handleModalClose = (name: string, value: boolean = false) => {
    setShowModal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      <Navlink
        path="corporate.settings.shipping.show.title"
        routes={showShippNavLinkList}
      >
        <ActionButton
          styles="shipping-action-btn"
          data={ActionBtnRows({
            paths: {
              edit: `/settings/shipping/${shippId}/update`,
              setShowModal,
            },
          })}
        />
      </Navlink>
      <form id="shipp-form" className="shipping-form" noValidate>
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            label={LanguageNameShipp("name")}
            onChange={() => {}}
            value={Shipping?.name}
            disabled
          />
          <TextInput
            label={LanguageNameShipp("currency_id")}
            onChange={() => {}}
            value={Shipping?.Currency?.name}
            disabled
          />
        </div>
        <div className="grid lg:grid-cols-2 gap-7">
          <TextInput
            label={LanguageNameShipp("amount")}
            onChange={() => {}}
            value={Shipping?.amount}
            disabled
          />
          <div className="flex items-center">
            <div className="flex items-center gap-1 col-span-2">
              <Checkbox
                id="shipp_is_active"
                afterLabel={LanguageNameShipp("is_active")}
                onChange={() => {}}
                checked={Shipping?.is_active}
                disabled
              />
              <p className="shipping-is_action-description">
                {`( ${LanguageNameShipp("is_active_description")} )`}
              </p>
            </div>
          </div>
        </div>
      </form>
      {/* <DeleteShippModal
        showModal={showModal}
        setShowModal={handleModalClose}
      /> */}
    </div>
  );
};
