import { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";
import { useProfile } from "../hooks/UserHooks";
import { logoutUser } from "../redux/auth/authAction";
import { AuthProtectedLayout } from "../layouts/AuthProtectedLayout";
import { fetchPermissionAction } from "../redux/general/generalAction";
import { useAppSelector } from "../redux/store";

// import { logoutUser } from "../store/actions";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { permission } = useAppSelector((state) => state.general);

  const { userProfile, loading, token } = useProfile();
  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
      dispatch(fetchPermissionAction());
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  /*
    Navigate is un-auth access protected routes via url
    */

  if (!userProfile && loading && !token) {
    return (
      <Navigate to={{ pathname: "/error-401", state: { from: props.location } }} />
    );
  }

  // is alow permissions
  if (!permission?.data?.includes(props?.isAlow) && permission?.success) {
    // return <Navigate to={"/error-403"} />;
  }

  return <AuthProtectedLayout>{props.children}</AuthProtectedLayout>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
