import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { object } from "../utils/ValidateErrors";

type InitialData = {
  [key: string]: any;
};

type SchemaDefinition = Array<string | "required">;
type Schema = Record<string, SchemaDefinition>;

function useInputValidation(
  InitialData: InitialData,
  validationSchema: Schema
) {
  const [errors, setErrors] = useState<any>({});
  const flag = useRef(false);

  const validate = useCallback(
    (validationData: InitialData) => {
      const schema = object(validationSchema);
      const result = schema.validate(validationData);
      setErrors(result.errors);
      return result.valid;
    },
    [validationSchema]
  );

  const action = useCallback(
    (data: InitialData, onValidationSuccess: Function) => {
      if (!flag.current) {
        flag.current = true;
      }
      if (validate(data)) {
        onValidationSuccess();
      }
    },
    [validate]
  );

  const memoizedInitialData = useMemo(() => InitialData, [InitialData]);

  useEffect(() => {
    if (flag.current) {
      validate(memoizedInitialData);
    }
  }, [memoizedInitialData, validate]);

  return { errors, action };
}

export default useInputValidation;
