import { FC, useState, Fragment } from "react";
import { faCheck, faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDirection } from "../../../hooks/useDirection";
import { useAppDispatch } from "../../../redux/store";
import { fetchLanguagesAction } from "../../../redux/general/generalAction";
import "./style.css";

type LanguageSwitchOptionType = {
  id?: number;
  name: string;
  locale_key: string;
  is_rtl?: string;
};

type LanguageSwitchProps = {
  options?: Array<LanguageSwitchOptionType>;
};

export const LanguageSwitch: FC<LanguageSwitchProps> = ({ options }) => {
  const dispatch = useAppDispatch();

  const [isOpened, setIsOpened] = useState(false);
  const { language, directionHandler } = useDirection({
    Element: document.getElementsByTagName("html")[0],
  });
  const [selected, setSelected] = useState<string>(language || "English");

  return (
    <div className="relative select-none z-50">
      <div
        className={`selected-language-container
      ${isOpened ? " outline-primary-color " : "outline-transparent "} `}
        onClick={() => setIsOpened((prev) => !prev)}
      >
        <FontAwesomeIcon
          icon={faGlobeAmericas}
          className="text-primary-color w-4 h-4 rounded-lg"
        />
        <div className="selected-language">{selected}</div>
        <div className="selected-language-background"></div>
      </div>

      <div
        className={`language-list-container ${
          isOpened ? "opacity-100" : "opacity-0 hidden"
        } `}
      >
        {options?.map((item, index) => (
          <Fragment key={item?.id}>
            <div
              key={item.id}
              className="language-option-container"
              onClick={() => {
                setSelected(item?.name);
                dispatch(fetchLanguagesAction(item?.locale_key));

                setIsOpened(false);
                directionHandler(item);
                //  directionHandler(item.tag);
              }}
            >
              <div className="flex items-center w-full gap-x-4">
                <div className="text-sm font-black text-black/80 w-5">
                  {item?.name?.slice(0, 2)}
                </div>
                <div className="text-sm font-semibold text-black/60">
                  {item.name}
                </div>
              </div>

              <div>
                {selected === item.name && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="w-4 h-4 text-success-color "
                  />
                )}
              </div>
            </div>
            {options.length - 1 > index && <hr className="language-divider" />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
