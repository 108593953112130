import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
// example to use
// const [imagePreview, setImagePreview] = useState("");
// const onChange = (e) => {
//   setImagePreview(e);
// };
// <UploadImage isAvatar onChange={onChange} image={imagePreview} />;

interface UploadImageTypes {
  id?: string;
  name?: string;
  isAvatar: boolean;
  image: string | File;
  styles?: string;
  insideLabel?: string;
  disabled?: boolean;
  error?: boolean | string;
  errorMsg?: string;
  onChange?: (name: string, value: File | undefined) => void;
}

export const UploadImage = ({
  id,
  name = "upload_img",
  isAvatar,
  image,
  styles,
  insideLabel = "Inside label",
  disabled = false,
  error = false,
  errorMsg = "",
  onChange = () => {},
}: UploadImageTypes) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    // Trigger the hidden file input when the button is clicked
    fileInputRef?.current?.click();
  };
  const [imagePreview, setImagePreview] = useState<any>(image);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    onChange(name, file);
  };

  useEffect(() => {
    if (typeof image === "string") {
      setImagePreview(image);
    } else if (typeof image === "object") {
      const img = URL.createObjectURL(image);
      setImagePreview(img);
    }
  }, [image]);

  return (
    <div className="relative">
      <div className="flex-center">
        <button
          onClick={handleButtonClick}
          className={`group
          ${imagePreview ? "opacity-100" : ""}
          ${
            isAvatar
              ? `upload-avatar-container ${
                  error && "border-2 border-failure-color"
                }`
              : "upload-item-container"
          }   
          ${styles} duration-300`}
          type="button"
          disabled={disabled}
        >
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Uploaded Preview"
              className={
                isAvatar
                  ? "w-full h-full  rounded-full"
                  : "w-full h-full  rounded-[20px]"
              }
            />
          ) : (
            <div
              className={`flex-center flex-col ${
                isAvatar ? "" : "text-main-color"
              } gap-5 duration-500`}
            >
              <FontAwesomeIcon className="icon-image" icon={faImage} />
              {!isAvatar && (
                <span className="text-base font-light">{insideLabel}</span>
              )}
            </div>
          )}
        </button>
        <input
          id={id}
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </div>
      {error && (
        <div className="upload-image-error">
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span className="text-3.5 font-[400]">{errorMsg}</span>
        </div>
      )}
    </div>
  );
};
