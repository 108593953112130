import Img from "./../../../assets/Group 983.svg";
import {
  createRef,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { AuthLayout } from "../AuthLayout";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  checkOtpAction,
  verifyCodeAction,
} from "../../../redux/auth/authAction";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { object } from "../../../utils/ValidateErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../Components";

type Response = {
  isLoading: boolean;
  success: boolean;
  message: { [k: string]: any };
  data: { [k: string]: any };
};

export const VerifyCode: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // To check if you are logged in
  const response: Response = useAppSelector((state) => state.auth);
  const {
    languages: { data },
  } = useAppSelector((state) => state.general);

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const codeType = searchParams.get("type") || "";
  const inputRefs = Array.from({ length: 4 }, () =>
    createRef<HTMLInputElement>()
  );
  // To check Otp Action on forget password
  const [errors, setErrors] = useState<{ [k: string]: any }>({
    code: "",
  });

  // useEffect(() => {
  //   // set api response errors
  //   if (!response.success) {
  //     setErrors({ code: response.message.error });
  //   }
  // }, [errors, response]);

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value.length <= 1) {
      // Move to the next input
      if (value.length === 0 || index === 3) {
        return;
      }
      inputRefs[index + 1]?.current?.focus();
    }
  };

  const handleInputKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && !event.currentTarget.value) {
      inputRefs[index - 1]?.current?.focus();
    }
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      const values = inputRefs.map((ref) => ref.current?.value || "");
      const code = values.join("");
      const codeSchema = object({
        code: ["string", "required"],
      });
      const result = codeSchema.validate({ code });
      setErrors(result.errors);
      if (result.valid) {
        if (codeType === "changePass") {
          const dataApiError = await checkOtpAction({
            data: { email, code },
            cb: () => {
              navigate(`/reset-password?email=${email}&code=${code}`);
            },
          });
          setErrors({ ...errors, code: dataApiError.message.error });
        } else {
          dispatch(verifyCodeAction({ email, code }));
        }
      }
    },
    [codeType, dispatch, email, errors, inputRefs, navigate]
  );

  const onPaste = (event: any) => {
    const value = event.clipboardData.getData("Text");
    inputRefs.forEach((ref, idx) => {
      if (ref.current) {
        ref.current.value = value[idx];
      }
    });
  };

  return (
    <AuthLayout>
      <div className="auth-head-container ">
        <div className="auth-head-container-forgetpassword-span ">
          <Link to="/login">
            <div className="container-span">
              <FontAwesomeIcon icon={faArrowLeft} />

              <span className="span-text ">Back</span>
            </div>
          </Link>
        </div>
        <img className="w-64 h-52" src={Img} alt="code message icon" />
        <h1 className="auth-card-title">
          {data?.ConstantsList?.["corporate.auth.check_otp.name"]}
        </h1>
        <p className="auth-card-description">
          {data?.ConstantsList?.["corporate.auth.check_otp.description"]}
        </p>
      </div>
      <div>
        {" "}
        <form className="space-y-3" onSubmit={handleSubmit}>
          <div className="flex justify-center items-center flex-col relative pb-8">
            <div className="gap-x-3 flex w-full justify-center">
              {inputRefs.map((ref, index) => {
                return (
                  <input
                    autoFocus={index === 0}
                    onPaste={onPaste}
                    key={index}
                    ref={ref}
                    type="text"
                    min="0"
                    max="9"
                    className={`verify-code-input rounded-primary-radius
                    bg-input-color ${
                      errors?.code ? "border-b-[3px] border-failure-color" : ""
                    } `}
                    maxLength={1}
                    onChange={(e) => handleInputChange(index, e)}
                    onKeyDown={(e) => handleInputKeyDown(index, e)}
                  />
                );
              })}
            </div>
            <span className="text-sm absolute bottom-0 text-failure-color">
              {errors?.code}
            </span>
          </div>
          <div className="auth-card-footer-container ">
            <div className="auth-card-footer">
              <Button
                type="submit"
                label={
                  data?.ConstantsList?.["corporate.auth.check_otp.verify_code"]
                }
                icon={null}
              />
            </div>
          </div>
        </form>
        <p className="verify-code-sub-footer">
          {data?.ConstantsList?.["corporate.auth.check_otp.resend_code"]}
          <span className="verify-code-sub-footer-link">Resend</span>
        </p>
      </div>
    </AuthLayout>
  );
};
