import {createAsyncThunk} from '@reduxjs/toolkit';
import {APIClient} from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import {isAxiosError} from 'axios';
import {useNotify} from '../../Components';

const api = new APIClient();

interface ShippArguments {
  id?: string;
  name: string;
  currency_id: string;
  amount: string;
  is_active?: number;
}

// GET/ALL Shipping ACTION
export const fetchAllShippingAction = createAsyncThunk(
  'shipping/getAllShipping',
  async (query: String, {rejectWithValue}) => {
    try {
      const {data} = await api.get(`${url.GIT_SHIPPING}${query}`);
      const newData = {
        ...data,
        data: data?.data?.ShippingCollection,
      };

      return newData;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// POST/CREATE SHIPP ACTION
export const createShippAction = createAsyncThunk(
  'shipping/create',
  async (shipp: ShippArguments, {rejectWithValue}) => {
    const Notify = useNotify();
    try {
      const {data} = await api.create(url.GIT_SHIPPING, shipp);
      if (data?.success) {
        Notify.Success({message: data?.message?.success});
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({message: errorData?.message?.error});
        return rejectWithValue(errorData);
      }
    }
  }
);

// GET/ SHIPP ACTION
export const getShippAction = createAsyncThunk(
  'shipping/getShippAction',
  async (query: string | undefined, {rejectWithValue}) => {
    try {
      const {data} = await api.get(`${url.GIT_SHIPPING}/${query}`);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

// DELETE/REMOVE SHIPP ACTION
export const deleteShippAction = createAsyncThunk(
  'shipping/delete',
  async (query: string | undefined, {rejectWithValue}) => {
    const Notify = useNotify();
    try {
      const {data} = await api.delete(`${url.GIT_SHIPPING}/${query}`);
      if (data?.success) {
        Notify.Success({message: data?.message?.success});
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({message: errorData?.message?.error});
        return rejectWithValue(errorData);
      }
    }
  }
);

// PUT/UPDATE SHIPP ACTION
export const updateShippAction = createAsyncThunk(
  'shipping/update',
  async (shipp: ShippArguments, {rejectWithValue}) => {
    const Notify = useNotify();
    try {
      const {data} = await api.create(`${url.GIT_SHIPPING}/${shipp?.id}`, {
        ...shipp,
        _method: 'PUT',
      });
      if (data?.success) {
        Notify.Success({message: data?.message?.success});
      }
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error?.response?.data;
        Notify.Error({message: errorData?.message?.error});
        return rejectWithValue(errorData);
      }
    }
  }
);
