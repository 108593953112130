import {
  ChangeEvent,
  FC,
  useState,
  useCallback,
  FormEvent,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { object } from "../../../../utils/ValidateErrors";
import { fetchAvailableLanguagesAction } from "../../../../redux/general/generalAction";
import {
  fetchCitiesAction,
  fetchCountriesAction,
  fetchRoleAction,
} from "../../../../redux/list/listAction";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  fetchEmployeeAction,
  updateEmployeeAction,
} from "../../../../redux/employee/employeeAction";
import {
  Button,
  Checkbox,
  Navlink,
  Selector,
  TextInput,
  UploadImage,
} from "../../../../Components";
import { EmployeeDataType, EmployeeOptionType } from "../employeeTypes";
import { updateEmployeeNavLinkList } from "../EmployeeTableContent";
import {
  LanguageNameEmployee,
  useLanguageName,
} from "../../../../hooks/useLanguageName";
import "../style.css";

export const UpdateEmployee: FC = () => {
  const { id: employeeId } = useParams();
  const dispatch = useAppDispatch();
  const {
    availableLanguages: {
      data: { Locales },
    },
  } = useAppSelector((state) => state.general);

  const { countries, cities, roles } = useAppSelector((state) => state.list);
  const { employee }: any = useAppSelector((state) => state.employees);
  const navigate = useNavigate();

  const flag = useRef(false);

  const initialData = useMemo(() => {
    return {
      name: "",
      email: "",
      avatar: "",
      password: "",
      mobile: "",
      is_active: 0,
    };
  }, []);
  const initialDataErrors = useMemo(() => {
    return {
      ...initialData,
      is_active: "",
    };
  }, [initialData]);

  const [employeeData, setEmployeeData] =
    useState<EmployeeDataType>(initialData);

  const [errors, setErrors] = useState(initialDataErrors);

  useEffect(() => {
    dispatch(fetchAvailableLanguagesAction());
    dispatch(fetchCountriesAction());
    dispatch(fetchRoleAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEmployeeAction(employeeId));
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (employeeData.country_id) {
      dispatch(fetchCitiesAction(`?country_id=${employeeData.country_id}`));
    }
  }, [dispatch, employeeData.country_id]);

  useEffect(() => {
    setEmployeeData(employee?.data?.Employee);
  }, [employee?.data?.Employee]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleChangeCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setEmployeeData((prev) => ({
      ...prev,
      [name]: checked ? 1 : 0,
    }));
  }, []);

  const handleUploadImageChange = useCallback(
    (name: string, value: File | undefined) => {
      setEmployeeData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  const handleSelectChange = useCallback(
    (name: string, value: EmployeeOptionType) => {
      setEmployeeData((prev) => ({
        ...prev,
        [name]: value.id,
      }));
    },
    []
  );

  const validate = useCallback(() => {
    const employeeSchema = object({
      name: ["required"],
      email: ["required", "email"],
      avatar: ["image"],
      mobile: ["required"],
    });

    const result = employeeSchema.validate(employeeData);
    setErrors(result.errors);
    return result.valid;
  }, [employeeData]);

  const saveEmployee = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (!flag.current) {
        flag.current = true;
      }
      if (validate()) {
        dispatch(
          updateEmployeeAction({ employee: employeeData, id: employeeId })
        ).then(({ payload }) => {
          payload?.success
            ? navigate(`/settings/show-employee/${employeeId}`)
            : setErrors(payload?.message);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validate]
  );

  useEffect(() => {
    if (flag.current) {
      validate();
    }
  }, [validate]);

  return (
    <div>
      <Navlink
        path={"corporate.settings.employees.update.title"}
        routes={updateEmployeeNavLinkList}
      >
        <Button
          form="employee-form"
          type="submit"
          styles="employee-update-save"
          label={useLanguageName("corporate.settings.employees.create.save")}
          icon={null}
        />
      </Navlink>
      <div className="table-container">
        <form
          id="employee-form"
          onSubmit={saveEmployee}
          className="form-box-container"
          noValidate
        >
          <div className="employee-header-img-row">
            <div className="employee-header-img-row-inputs">
              <TextInput
                id="employee_name"
                name="name"
                inputBoxStyle="h-11"
                label={LanguageNameEmployee("name")}
                labelStyle="!text-base"
                placeholder={LanguageNameEmployee("name_placeholder")}
                value={employeeData?.name}
                onChange={handleChange}
                error={errors?.name}
                errorMsg={errors?.name}
              />
              <TextInput
                id="employee_email"
                name="email"
                inputBoxStyle="h-11"
                label={LanguageNameEmployee("email")}
                labelStyle="!text-base"
                placeholder={LanguageNameEmployee("email_placeholder")}
                onChange={handleChange}
                value={employeeData?.email}
                error={errors?.email}
                errorMsg={errors?.email}
              />
            </div>
            <div className="col-span-1 order-1 lg:order-2 self-center">
              <UploadImage
                name="avatar"
                image={employeeData?.avatar || ""}
                isAvatar
                onChange={handleUploadImageChange}
                styles="h-[186px] w-[186px]"
                error={errors?.avatar}
                errorMsg={errors?.avatar}
              />
            </div>
          </div>
          <div className="employee-box-row">
            <TextInput
              id="employee_password"
              name="password"
              inputBoxStyle="h-11"
              label={LanguageNameEmployee("password")}
              labelStyle="!text-base"
              placeholder={LanguageNameEmployee("password_placeholder")}
              value={employeeData?.password}
              type="password"
              onChange={handleChange}
              error={errors?.password}
              errorMsg={errors?.password}
            />
            <div className="relative">
              <Selector
                id="employee_language"
                name="language"
                label={LanguageNameEmployee("locale_id")}
                labelStyle="!text-base"
                placeholder={LanguageNameEmployee("locale_id_placeholder")}
                options={Locales}
                searchPlaceholder="Search language"
                defaultOptions={employeeData?.Locale}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <div className="employee-box-row">
            <TextInput
              id="employee_mobile"
              name="mobile"
              inputBoxStyle="h-11"
              label={LanguageNameEmployee("mobile")}
              labelStyle="!text-base"
              placeholder={LanguageNameEmployee("mobile_placeholder")}
              onChange={handleChange}
              type="number"
              value={employeeData?.mobile}
              handleSelect={handleSelectChange}
              error={errors?.mobile}
              errorMsg={errors?.mobile}
              imgDropdown={{
                isShow: true,
                label: "",
                placeholder: LanguageNameEmployee("country_id"),
                options: countries?.data,
                defaultOptions: employeeData?.Country,
              }}
            />

            <div className="relative">
              <Selector
                id="employee_city"
                name="city_id"
                label={LanguageNameEmployee("city_id")}
                labelStyle="!text-base"
                placeholder={LanguageNameEmployee("city_placeholder")}
                options={cities?.data}
                searchPlaceholder="Search city"
                defaultOptions={employeeData?.City}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <div className="employee-box-row">
            <div className="relative">
              <Selector
                id="employee_role"
                name="role_id"
                label={LanguageNameEmployee("role_id")}
                labelStyle="!text-base"
                placeholder={LanguageNameEmployee("role_placeholder")}
                options={roles?.data}
                searchPlaceholder="Search role"
                defaultOptions={employeeData?.Role}
                onChange={handleSelectChange}
              />
            </div>
            <div className="grid content-center">
              <Checkbox
                id="employee_is_active"
                name="is_active"
                afterLabel={LanguageNameEmployee("is_active")}
                styles="checked:bg-main-color"
                checked={!!employeeData?.is_active}
                onChange={handleChangeCheck}
              />
              <p className="is_active_description ">
                {LanguageNameEmployee("is_active_description")}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
