import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchAvailableLanguagesAction,
  fetchLanguagesAction,
  fetchPermissionAction,
} from "./generalAction"; // You should import the correct type for UserData

interface InitialStateType {
  data: any;
  success: boolean;
  isLoading: boolean;
  message: Array<string>;
}

interface GeneralState {
  permission: InitialStateType;
  languages: InitialStateType;
  availableLanguages: InitialStateType;
}

const initialStateStructure = {
  data: [],
  isLoading: false,
  success: false,
  message: [],
};

const initialState: GeneralState = {
  languages: initialStateStructure,
  permission: initialStateStructure,
  availableLanguages: initialStateStructure,
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Languages
      .addCase(fetchLanguagesAction.pending, (state) => {
        state.languages.isLoading = true;
        state.languages.success = false;
        state.languages.message = [];
      })
      .addCase(
        fetchLanguagesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.languages.isLoading = false;
          state.languages = action.payload;
        }
      )
      .addCase(
        fetchLanguagesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.languages.isLoading = false;
          state.languages.success = action.payload?.success;
          state.languages.message = action.payload?.message;
        }
      )
      // Fetch Permission User
      .addCase(fetchPermissionAction.pending, (state) => {
        state.permission.isLoading = true;
        // state.permission.success = false;
        state.permission.message = [];
      })
      .addCase(
        fetchPermissionAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.permission.isLoading = false;
          state.permission = action.payload;
        }
      )
      .addCase(
        fetchPermissionAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.permission.isLoading = false;
          state.permission.success = action.payload?.success;
          state.permission.message = action.payload?.message;
        }
      )
      // Fetch Available Languages Action
      .addCase(fetchAvailableLanguagesAction.pending, (state) => {
        state.availableLanguages.isLoading = true;
        state.availableLanguages.success = false;
        state.availableLanguages.message = [];
      })
      .addCase(
        fetchAvailableLanguagesAction.fulfilled,
        (state, action: PayloadAction<InitialStateType>) => {
          state.availableLanguages = action.payload;
          state.availableLanguages.isLoading = false;
        }
      )
      .addCase(
        fetchAvailableLanguagesAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.availableLanguages.isLoading = false;
          state.availableLanguages.success = action.payload?.success;
          state.availableLanguages.message = action.payload?.message;
        }
      );
  },
});

export default generalSlice.reducer;
