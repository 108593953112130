import { FC } from "react";
//import full_logo from "./../../assets/full-logo.svg";
import full_logo from "./../../../assets/full-logo.svg";
import maintenanceImg from "./../../../assets/maintenance.svg";

export const Maintenance: FC = () => {
  return (
    <div className="error-page-background">
      <div className="w-full">
        <div className="maintenance-page-content-container">
          <div className="maintenance-page-logo-dimentions">
            <img className="w-full" src={full_logo} alt="Full logo" />
          </div>
          <div className="maintenance-page-img-dimentions">
            <img src={maintenanceImg} alt="Maintenance" className="w-full" />
          </div>
          <div className="maintenance-page-text-container">
            <p className="maintenance-page-title">
              Hang On! We are under maintenance
            </p>
            <p className="maintenance-page-sub-title">
              It will not take long time till we get the error fixed. We will
              back Soon{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
//lg:w-[359px] md:w-[270px] w-[220px]
//lg:w-9/12 md:w-4/5 sm:w-5/6 w-11/12 md:mt-[80px] mt-[40px]
