import { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchAvailableLanguagesAction } from "../../../../redux/general/generalAction";
import {
  fetchCitiesAction,
  fetchCountriesAction,
  fetchRoleAction,
} from "../../../../redux/list/listAction";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { fetchEmployeeAction } from "../../../../redux/employee/employeeAction";
import {
  ActionButton,
  Checkbox,
  Navlink,
  TextInput,
  UploadImage,
} from "../../../../Components";
import { ActionBtnRows, showEmployeeNavLinkList } from "../EmployeeTableContent";
import { LanguageNameEmployee } from "../../../../hooks/useLanguageName";
import { ModalsEmployees } from "../Components/Modals";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../style.css";

export const ShowEmployee: FC = () => {
  const { id: employeeId } = useParams();
  const dispatch = useAppDispatch();
  const {
    availableLanguages: {
      data: { Locales },
    },
  } = useAppSelector((state) => state.general);

  const { countries, cities, roles } = useAppSelector((state) => state.list);
  const { Employee }: any = useAppSelector(
    (state) => state?.employees?.employee?.data
  );

  const [showModal, setShowModal] = useState({
    delete: false,
    is_suspended: false,
    is_banned: false,
  });

  useEffect(() => {
    dispatch(fetchAvailableLanguagesAction());
    dispatch(fetchEmployeeAction(employeeId));
    dispatch(fetchCountriesAction());
    dispatch(fetchRoleAction());
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (Employee?.country_id) {
      dispatch(fetchCitiesAction(`?country_id=${Employee?.country_id}`));
    }
  }, [dispatch, Employee]);

  const handleModalClose = (name: string, value: boolean = false) => {
    setShowModal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      <Navlink
        path="corporate.settings.employees.show.title"
        routes={showEmployeeNavLinkList}
      >
        <ActionButton
          data={ActionBtnRows({
            paths: {
              edit: `/settings/update-employee/${employeeId}`,
              handleModalClose: handleModalClose,
            },
          })}
          icon={faChevronDown}
          styles="employee-action-show-btn"
        />
      </Navlink>
      <div className="table-container">
        <form id="employee-form" className="form-box-container">
          <div className="employee-header-img-row">
            <div className="employee-header-img-row-inputs">
              <TextInput
                id="employee_name"
                name="name"
                label={LanguageNameEmployee("name")}
                labelStyle="!text-base"
                inputBoxStyle="h-11"
                value={Employee?.name || " "}
                disabled
              />
              <TextInput
                id="employee_email"
                name="email"
                label={LanguageNameEmployee("email")}
                labelStyle="!text-base"
                inputBoxStyle="h-11"
                value={Employee?.email || " "}
                disabled
              />
            </div>
            <div className="col-span-1 order-1 lg:order-2 self-center">
              <UploadImage
                name="avatar"
                image={Employee?.avatar}
                isAvatar
                styles="h-[186px] w-[186px]"
                disabled
              />
            </div>
          </div>
          <div className="employee-box-row">
            <TextInput
              id="employee_role"
              name="role_id"
              label={LanguageNameEmployee("role_id")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              value={
                roles?.data?.filter(
                  (role: any) => role?.id === Employee?.role_id
                )[0]?.name || " "
              }
              disabled
            />
            <TextInput
              id="employee_language"
              name="language"
              label={LanguageNameEmployee("locale_id")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              value={
                Locales?.filter(
                  (language: any) => language?.id === Employee?.Locale?.id
                )[0]?.name || " "
              }
              disabled
            />
          </div>
          <div className="employee-box-row">
            <TextInput
              id="employee_mobile"
              name="mobile"
              label={LanguageNameEmployee("mobile")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              value={`${
                countries?.data?.filter(
                  (country: Record<string, string>) =>
                    country?.id === Employee?.city_id
                )[0]?.name || ""
              } | ${Employee?.mobile}`}
              disabled
            />

            <TextInput
              id="employee_city"
              name="city_id"
              label={LanguageNameEmployee("city_id")}
              labelStyle="!text-base"
              inputBoxStyle="h-11"
              value={
                cities?.data?.filter(
                  (city: Record<string, string>) =>
                    city?.id === Employee?.city_id
                )[0]?.name || " "
              }
              disabled
            />
          </div>
          <div className="employee-box-row">
            <div className="grid content-center">
              <Checkbox
                id="employee_is_active"
                name="is_active"
                afterLabel={LanguageNameEmployee("is_active")}
                styles="checked:bg-main-color"
                checked={Employee?.is_active || 0}
              />
              <p className="is_active_description ">
                {LanguageNameEmployee("is_active_description")}
              </p>
            </div>
          </div>
        </form>
      </div>

      <ModalsEmployees
        showModal={showModal}
        handleModalClose={handleModalClose}
      />
    </div>
  );
};
