import { FC } from "react";
import { useAppSelector } from "../../../redux/store";
import "./style.css";

interface ImagedNameTypes {
  customStyle?: string;
  label: string;
  value: string;
  enumKey: string;
  data: any;
}

export const CellNameEnum: FC<ImagedNameTypes> = ({
  customStyle,
  label,
  value,
  enumKey,
  data,
}) => {
  const { enums } = useAppSelector((state) => state?.list);
  const findKeyByValue = (
    obj: Record<string, string | number>,
    targetValue: string | number
  ) => {
    for (const key in obj) {
      if (obj[key] === targetValue) {
        return key;
      }
    }
    return undefined;
  };

  return (
    <div className={`py-5 text-sm ${customStyle}`}>
      <div className="cell-enum-box">
        <p className="cell-enum-label">{label}</p>
        <span className="cell-enum-value">
          {findKeyByValue(enums?.data?.[enumKey], data?.[value])}
        </span>
      </div>
    </div>
  );
};
